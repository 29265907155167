import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AixLoadingComponent } from '@trade-platform/ui-components';

@Component({
    selector: 'aix-page-not-found',
    templateUrl: './page-not-found.html',
    styleUrls: ['./page-not-found.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AixLoadingComponent]
})
export class AixPageNotFoundComponent implements OnInit {
    route = inject(ActivatedRoute);

    isLoggingIn = true;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);
    constructor() {}

    ngOnInit() {
        this.isLoggingIn = this.route.snapshot.url[0].path.includes('access_token');
    }
}
