import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { privacyPolicyDecoder } from '../../decoders/privacy-policy/decoder';
import { PrivacyPolicy } from '../../models/privacy-policy/model';

@Injectable()
export class PrivacyPolicyService {
    private http = inject(HttpClient);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    getPrivacyPolicy(): Observable<PrivacyPolicy> {
        return this.http
            .get<PrivacyPolicy>(`${this.environment.api}privacy`)
            .pipe(concatMap(p => fromPromise(privacyPolicyDecoder.decodeToPromise(p))));
    }
}
