import {
    ChangeDetectorRef,
    Component,
    model,
    OnDestroy,
    OnInit,
    output,
    inject
} from '@angular/core';
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormGroup,
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';
import { DocumentFile, OrderForm } from '@trade-platform/ui-shared';
import { Subscription } from 'rxjs';
import {
    getUploadedFormFiles,
    getOrganizations,
    DocumentOrganization
} from '../../process/overview/utils/order-utils';
import {
    AixButtonComponent,
    AixDropdownComponent,
    AixInputComponent,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../../base.orders.store.facade';
import { RemoteDataModule } from 'ngx-remotedata';
import { AsyncPipe } from '@angular/common';
import { entityType, fileType } from '@trade-platform/lib-enums';
import { AixUploadedFormFilesMenuOption } from '../../form-upload/form-filelist/form-filelist';
import { getLanguage } from 'libs/advisor-ui/language/language.base';
import { OrderOverview } from 'libs/advisor-ui/language/language.interface';

export interface UploadedFormRelation {
    fileName?: string;
    organization?: DocumentOrganization | null;
}

@Component({
    selector: 'aix-label-uploaded-form-documents',
    templateUrl: './label-uploaded-form-documents.html',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        AixDropdownComponent,
        AixInputComponent,
        AixButtonComponent,
        AsyncPipe,
        RemoteDataModule
    ]
})
export class AixLabelUploadedFormDocumentsComponent implements OnInit, OnDestroy {
    store = inject<BaseOrdersStoreFacade>(ORDERS_STORE_FACADE);
    private cd = inject(ChangeDetectorRef);
    private _fb = inject(UntypedFormBuilder);

    relabelForm = model<OrderForm | null>(null);

    filesUpdated = output();
    dismissModal = output();

    relationFormReady = false;
    relationForm: UntypedFormGroup;
    relationsControls: AbstractControl[] = [];
    formFiles: AixUploadedFormFilesMenuOption[] = [];
    subscriptions: Subscription[] = [];
    organizations: DocumentOrganization[] = [];
    formIsInvalid = true;
    cancelButtonType = BUTTON_TYPE.link;
    saveButtonType = BUTTON_TYPE.primary;

    language: OrderOverview = getLanguage('orderOverview');
    hideFormDocumentOwner = !!this.language?.hideFormDocumentOwner;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        this.relationForm = this._fb.group({
            relations: this._fb.array([])
        });
    }

    ngOnInit() {
        this.subscriptions.push(
            this.store.actions.updateOrderFiles.success$.subscribe(action => {
                this.filesUpdated.emit();
            }),
            this.store.orderSuccess$.subscribe(order => {
                this.organizations = getOrganizations(order);

                const relations: UploadedFormRelation[] = [];
                (this.relationForm.get('relations') as UntypedFormArray).controls = []; // clear existing relations controls before creating new ones;

                this.formFiles = [];
                getUploadedFormFiles(order)
                    .filter(item =>
                        item.form?.isOnboarded === false &&
                        item.form.isRequired === false &&
                        this.relabelForm()
                            ? item.form.id === this.relabelForm()?.id
                            : item.file?.type == fileType.unassignedForm
                    )
                    .forEach(file => {
                        if (file !== null) {
                            this.formFiles.push(file);
                        }
                    });
                this.relabelForm.set(null);

                this.formFiles.forEach(f => {
                    const control = this.relationForm.controls['relations'] as UntypedFormArray;
                    control.push(
                        this._fb.group({
                            fileName: '',
                            organization: ''
                        })
                    );

                    let organization;
                    if (this.hideFormDocumentOwner) {
                        organization = this.organizations.find(
                            org => (org.entity.type = entityType.fundSponsor)
                        );
                    } else {
                        organization = this.organizations.find(
                            org => org.name === f.form?.documentOwner
                        );
                    }

                    relations.push({
                        fileName: f.file?.type === fileType.unassignedForm ? '' : f.form?.name,
                        organization
                    });
                });

                this.relationForm.patchValue({
                    relations: relations
                });

                this.relationsControls = (
                    this.relationForm.get('relations') as UntypedFormArray
                ).controls;

                this.relationFormReady = true;

                this.cd.detectChanges();
            }),
            this.relationForm.valueChanges.subscribe(value => {
                this.formIsInvalid = this.isInvalid();
            })
        );
    }

    cancelFileUpload() {
        this.dismissModal.emit();
    }

    clickSaveRelations() {
        const order = this.store.order;
        const relations = this.relationForm.value.relations;
        const updatedFormFiles: DocumentFile[] = [];
        this.formFiles.forEach((formFile, index) => {
            if (relations[index].fileName && relations[index].organization) {
                const fileName = relations[index].fileName;
                const organization = relations[index].organization.length
                    ? relations[index].organization[0]
                    : relations[index].organization;

                // only update files that have been relabelled
                if (
                    (formFile.file && formFile.file?.name !== fileName) ||
                    (formFile.file?.firmId &&
                        organization.entity.type === entityType.firm &&
                        formFile.file?.firmId !== organization.entity.id) ||
                    (formFile.file?.fundId &&
                        organization.entity.type === entityType.fundSponsor &&
                        formFile.file?.fundId !== organization.entity.id) ||
                    (formFile.file?.holdingOptionId &&
                        organization.entity.type === entityType.holdingOption &&
                        formFile.file?.holdingOptionId !== organization.entity.id)
                ) {
                    updatedFormFiles.push({
                        ...formFile.file,
                        name: fileName,
                        firmId:
                            organization.entity.type === entityType.firm
                                ? organization.entity.id
                                : null,
                        fundId:
                            organization.entity.type === entityType.fundSponsor
                                ? organization.entity.id
                                : null,
                        holdingOptionId:
                            organization.entity.type === entityType.holdingOption
                                ? organization.entity.id
                                : null
                    });

                    formFile.file.name = fileName;
                    const entityId = organization.entity.id;
                    if (organization.entity.type === entityType.firm) {
                        formFile.file.firmId = entityId;
                    } else if (organization.entity.type === entityType.fundSponsor) {
                        formFile.file.fundId = entityId;
                    } else {
                        formFile.file.holdingOptionId = entityId;
                    }
                }
            }
        });

        this.store.actions.updateOrderFiles.dispatch({
            updatePayload: updatedFormFiles,
            updatePayloadExtras: { orderId: order.id },
            reducerSuffix: 'orderFilesUpdate'
        });
    }

    isInvalid(): boolean {
        if (!this.formFiles.length) {
            return false;
        }

        return this.relationForm.value.relations.some(
            (item: UploadedFormRelation) =>
                (Array.isArray(item.organization) && item.organization.length === 0) ||
                !item.organization ||
                !item.fileName
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
