import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchHttpError } from '@trade-platform/ui-shared';
import {
    FundFirmActionTypes,
    LoadFirmsByFundAction,
    LoadFirmsByFundFailureAction,
    LoadFirmsByFundSuccessAction
} from './actions';
import { FundFirmsService } from './service';

@Injectable()
export class FundFirmsEffects {
    private actions$ = inject(Actions);
    private service = inject(FundFirmsService);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    loadFirmsByFund$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadFirmsByFundAction>(FundFirmActionTypes.LOAD_FIRMS_BY_FUND),
            switchMap(action => {
                return this.service.getFirmsByFund(action.payload).pipe(
                    map(firms => {
                        return new LoadFirmsByFundSuccessAction({ firms });
                    }),
                    catchHttpError(error => of(new LoadFirmsByFundFailureAction({ error })))
                );
            })
        )
    );
}
