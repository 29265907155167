import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import {
    OrderSigningMethodPayload,
    OrderSigningMethodResponse,
    orderSigningMethodResponseDecoder
} from '@trade-platform/ui-shared';

@Injectable()
export class OrderSigningMethodService {
    private http = inject(HttpClient);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    orderSigningMethod(
        payload: OrderSigningMethodPayload
    ): Observable<OrderSigningMethodResponse | undefined> {
        return this.http
            .post<OrderSigningMethodResponse>(
                `${this.environment.api}orders/${payload.orderId}/signingMethod`,
                {
                    signingMethod: payload.signingMethod
                }
            )
            .pipe(
                concatMap(p => {
                    return fromPromise(orderSigningMethodResponseDecoder.decodeToPromise(p));
                })
            );
    }
}
