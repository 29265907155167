import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { OrderQueueGroup, orderQueueGroupDecoder } from '@trade-platform/ui-shared';

@Injectable()
export class OrdersAdvancedFiltersService {
    private http = inject(HttpClient);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    getFilters(): Observable<OrderQueueGroup[]> {
        return this.http.get<OrderQueueGroup[]>(`${this.environment.api}orders/filters`).pipe(
            map(queues => {
                return queues;
            }),
            concatMap(p => fromPromise(orderQueueGroupDecoder.decodeToPromise(p)))
        );
    }
}
