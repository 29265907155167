import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorWrapper, getErrorText } from '@trade-platform/ui-shared';
import {
    OrderResend,
    OrderResendActionTypes,
    OrderResendFailure,
    OrderResendSuccess
} from './actions';
import { OrderResendService } from './service';

@Injectable()
export class OrderResendEffects {
    private actions$ = inject(Actions);
    private orderResendService = inject(OrderResendService);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    orderResend$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderResend>(OrderResendActionTypes.ORDER_RESEND),
            switchMap(action =>
                this.orderResendService
                    .orderResend(action.payload.orderId, action.payload.recipientId)
                    .pipe(
                        map(res => new OrderResendSuccess(res)),
                        catchError<Action, Observable<Action>>(
                            (error: string | HttpErrorResponse) => {
                                let err: ErrorWrapper;
                                if (error instanceof HttpErrorResponse) {
                                    const errorText = getErrorText(error);
                                    if (error.status === 410 || error.status === 500) {
                                        err = new ErrorWrapper(error.message, {
                                            errorText:
                                                'We are actively processing signatures, you should receive the signed status momentarily.'
                                        });
                                    } else {
                                        err = new ErrorWrapper(error.message, { errorText });
                                    }
                                } else {
                                    err = new ErrorWrapper(error);
                                }
                                return of(new OrderResendFailure({ error: err }));
                            }
                        )
                    )
            )
        )
    );
}
