import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchHttpError } from '@trade-platform/ui-shared';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
    LoadOrdersStatusHistory,
    LoadOrdersStatusHistoryActionTypes,
    LoadOrdersStatusHistoryFailure,
    LoadOrdersStatusHistorySuccess
} from './actions';
import { OrdersStatusHistorysService } from './service';

@Injectable()
export class OrdersStatusHistorysEffects {
    private actions$ = inject(Actions);
    private service = inject(OrdersStatusHistorysService);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    loadOrdersStatusHistorysEffects$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadOrdersStatusHistory>(
                LoadOrdersStatusHistoryActionTypes.LOAD_ORDERS_STATUS_HISTORY
            ),
            switchMap(action =>
                this.service.getOrdersStatusHistory(action.payload.orderId).pipe(
                    map(filters => new LoadOrdersStatusHistorySuccess(filters)),
                    catchHttpError(error => of(new LoadOrdersStatusHistoryFailure({ error })))
                )
            )
        )
    );
}
