import { Injectable, inject } from '@angular/core';
import { OutagesService, TermsService, AixUsersService } from '@trade-platform/ui-shared';

@Injectable()
export class AixLoginApiService {
    outagesService = inject(OutagesService);
    termsService = inject(TermsService);
    profileService = inject(AixUsersService);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}
}
