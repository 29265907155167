import { Component, forwardRef, input, model, output, TemplateRef, viewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AixExpansionPanelComponent } from '../aix-expansion-panel/expansion-panel';
import { v4 as uuid } from 'uuid';
import { AixTooltipDirective } from '../../directives/tooltip/aix-tooltip';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';

export interface AixRadioGroupColumnSection {
    type?: string;
    label: string;
    isTitle: boolean;
    children: any[];
    columnNumber?: number;
}
@Component({
    selector: 'aix-radio-group-column-sections',
    styleUrls: ['./aix-radio-group-column-sections.component.scss'],
    templateUrl: './aix-radio-group-column-sections.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AixRadioGroupColumnSectionsComponent)
        }
    ],
    standalone: true,
    imports: [AixDataTestingDirective, AixTooltipDirective, AixExpansionPanelComponent]
})
export class AixRadioGroupColumnSectionsComponent implements ControlValueAccessor {
    expansionPanelRef = viewChild<AixExpansionPanelComponent>('expansionPanelRef');
    headerTemplate = viewChild<TemplateRef<any>>('headerTemplate');

    _propagateChanges: (value: string) => void = () => ({});
    _propagateTouches: () => void = () => ({});

    options = input<AixRadioGroupColumnSection[]>();
    labelField = input<string>();
    valueField = input<string>();
    descriptionField = input<string>();
    tooltipField = input<string>();
    isRequired = input(false);
    isDisabled = input(false);
    title = input('');
    placeholder = input('');
    headerOffset = input(0);

    value = model('');

    valueChanges = output<string>();
    onSelectOption = output();
    setDirty = output();

    private isDirty = false;

    name = `radioGroup${uuid()}`;
    selectedOptionLabel: string | undefined;

    constructor() {}

    valueToSend(rawValue: string) {
        this.value.set(rawValue ?? null);
        this.valueChanges.emit(rawValue);
        return rawValue;
    }

    onUserInput(evt: Event | ClipboardEvent, option: any) {
        if (!this.isDirty) {
            this.setDirty.emit();
            this.isDirty = true;
        }

        this.selectedOptionLabel = option[<string>this.labelField()]
            ? option[<string>this.labelField()]
            : undefined;
        this.onSelectOption.emit(option);
        this.doAutoscroll();

        const val = this.valueToSend((evt.target as HTMLInputElement).value);
        this._propagateChanges(val);
    }

    doAutoscroll() {
        const element = (<TemplateRef<any>>this.headerTemplate()).elementRef.nativeElement;
        const elementPosition = element.parentElement.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - this.headerOffset();

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });

        (<AixExpansionPanelComponent>this.expansionPanelRef()).toggle();
    }

    writeValue(value: any) {
        this.valueToSend(value);
    }

    registerOnChange(fn: (value: string) => void) {
        this._propagateChanges = fn;
    }

    registerOnTouched(fn: () => void) {
        this._propagateTouches = fn;
    }
}
