import {
    ChangeDetectionStrategy,
    Component,
    ChangeDetectorRef,
    output,
    viewChild,
    inject
} from '@angular/core';
import { AixModalComponent } from '@trade-platform/ui-components';

@Component({
    selector: 'aix-cancel-changes-modal',
    templateUrl: './cancel-changes.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AixModalComponent]
})
export class AixCancelChangesModalComponent {
    private ref = inject(ChangeDetectorRef);

    optionSelected = output<string>();

    cancelChangesModal = viewChild.required<AixModalComponent>('cancelChangesModal');

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    onCancelChangesModal(e: string) {
        this.optionSelected.emit(e);
    }

    public openModal() {
        this.cancelChangesModal().openModal();
        this.ref.markForCheck();
    }

    public closeModal() {
        this.cancelChangesModal().closeModal();
        this.ref.markForCheck();
    }
}
