import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { ProfileRepCode, repCodesDecoder } from '@trade-platform/ui-shared';

@Injectable()
export class RepCodessByAdvisorService {
    private http = inject(HttpClient);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    getRepCodesByAdvisor(advisor: string): Observable<ProfileRepCode[]> {
        return this.http
            .get<ProfileRepCode[]>(`${this.environment.api}users/${advisor}/repcodes`)
            .pipe(concatMap(p => fromPromise(repCodesDecoder.decodeToPromise(p))));
    }
}
