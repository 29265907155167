import {
    Directive,
    ElementRef,
    HostListener,
    input,
    OnDestroy,
    OnInit,
    Renderer2,
    inject
} from '@angular/core';
import { AixFootnoteService } from './aix-footnote.service';

@Directive({
    selector: '[aixFootnote]',
    standalone: true
})
export class AixFootnoteDirective implements OnInit, OnDestroy {
    private footnoteService = inject(AixFootnoteService);
    private _el = inject(ElementRef);
    private _renderer = inject(Renderer2);

    aixFootnoteNumber = input<string>();
    aixFootnoteContent = input<string>();
    aixFootnoteStyle = input<string>();

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent) {
        // Set the active footnote in the service (for reference)
        const element = event.target as HTMLElement;
        const footnoteId = element.getAttribute('data-scroll-to');

        if (footnoteId !== null) {
            this.goToActiveFootnote(footnoteId);
        }
    }

    /***
     * Notes on usage:
     * This directive requires the service AixFootnotesSerice and the component
     * AixFootnotesContainerComponent. The service holds a list of all the footnotes
     * and the container component displays those footnotes.
     */

    ngOnInit() {
        /**
         * Adds footnote to the service. The service will inform the
         * footnotes container component.
         */
        const footnoteObj = {
            id: <string>this.aixFootnoteNumber(),
            content: <string>this.aixFootnoteContent()
        };
        this.footnoteService.addItem(footnoteObj);

        /**
         * Appends the footnote link to the existing HTML in the tag
         */
        const html =
            this._el.nativeElement.innerHTML + this.footnoteTmpl(<string>this.aixFootnoteNumber());
        this._renderer.setProperty(this._el.nativeElement, 'innerHTML', html);
    }

    footnoteTmpl(footnoteNumber: string) {
        return `<sup class="aix-footnote br-link"
                    id="aix-footnote-${footnoteNumber}"
                    style="${this.aixFootnoteStyle()}"
                    data-scroll-to="aix-footnote-content-${footnoteNumber}">
                    ${footnoteNumber}
                </sup>`;
    }

    goToActiveFootnote(footnoteId: string) {
        const elem = document.getElementById(footnoteId);

        if (elem) {
            elem.scrollIntoView({ behavior: 'smooth' });
        }
    }

    ngOnDestroy() {
        // Remove item from the AixFootnoteService
        this.footnoteService.removeItem(<string>this.aixFootnoteNumber());
    }
}
