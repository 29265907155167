import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { OrderApprovalForm, orderApprovalFormsResponse } from '@trade-platform/ui-shared';

@Injectable()
export class OrderApprovalFormsService {
    private http = inject(HttpClient);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    getOrderApprovalForms(orderId: string): Observable<OrderApprovalForm[]> {
        return this.http
            .get<OrderApprovalForm[]>(`${this.environment.api}orders/${orderId}/approvals/forms`)
            .pipe(concatMap(p => fromPromise(orderApprovalFormsResponse.decodeToPromise(p))));
    }
}
