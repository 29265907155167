import { Component, input, Input, OnInit, output, ViewEncapsulation } from '@angular/core';
import { eSignEnvelopeRecipient } from '@trade-platform/ui-shared';
import { recipientStatus } from '@trade-platform/lib-enums';
import { format } from 'date-fns';
import { AixDataTestingDirective } from '@trade-platform/ui-components';
import { AixPhonePipe } from '@trade-platform/dynamic-forms';

@Component({
    selector: 'aix-signer',
    templateUrl: './signer.component.html',
    styleUrls: ['./signer.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [AixDataTestingDirective, AixPhonePipe]
})
export class AixSignerComponent implements OnInit {
    recipient = input<eSignEnvelopeRecipient>();
    showSending = input<boolean>(false);

    onMakeChanges = output<string>();

    recipientTimestamp: string | null = '';
    recipientStatus = recipientStatus;

    ngOnInit() {
        const timestamp = this.recipient()?.timestamp as string;
        this.recipientTimestamp = timestamp ? format(new Date(timestamp), 'M/d/yyyy h:mm a') : null;
    }
}
