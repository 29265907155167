import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchHttpError } from '../utils/catch-http-error';
import {
    LoadOutages,
    LoadOutagesActionTypes,
    LoadOutagesFailure,
    LoadOutagesSuccess
} from './actions';
import { OutagesService } from './service';

@Injectable()
export class OutagesEffects {
    private actions$ = inject(Actions);
    private service = inject(OutagesService);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    loadOutagesEffects$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadOutages>(LoadOutagesActionTypes.LOAD_OUTAGES),
            switchMap(action => {
                return this.service.getOutages().pipe(
                    map(outages => new LoadOutagesSuccess(outages)),
                    catchHttpError(error => of(new LoadOutagesFailure({ error })))
                );
            })
        )
    );
}
