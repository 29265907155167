import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { ExternalFirm } from './model';
import { externalFirmsDecoder } from './decoder';

@Injectable()
export class FundFirmsService {
    private http = inject(HttpClient);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    getFirmsByFund(fundId: number): Observable<ExternalFirm[]> {
        return this.http
            .get<ExternalFirm[]>(`${this.environment.api}funds/${fundId}/firms`)
            .pipe(concatMap(p => fromPromise(externalFirmsDecoder.decodeToPromise(p))));
    }
}
