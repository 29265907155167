import { Injectable, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable()
export class TitleService {
    private titleService = inject(Title);

    private _currentTitle: string;
    get currentTitle(): string {
        return this._currentTitle;
    }

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    suffix = 'AIX Platform';

    setTitle(title: string) {
        this._currentTitle = `${title} - ${this.suffix}`;
        this.titleService.setTitle(this._currentTitle);
    }
}
