import { Injectable, inject } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AixAuthService } from '../../services/auth-service';

// Ensures logout came from an internal component and not a manual url entry;
@Injectable()
export class LogoutGuard {
    authService = inject(AixAuthService);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.authService.canLogout;
    }
}
