import {
    ChangeDetectionStrategy,
    Component,
    input,
    model,
    OnChanges,
    output,
    SimpleChanges,
    inject
} from '@angular/core';
import { commentTag as commentTagTypes } from '@trade-platform/lib-enums';
import {
    FormBuilder,
    FormsModule,
    ReactiveFormsModule,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import {
    AixCheckboxComponent,
    AixDataTestingDirective,
    AixRadioGroupComponent
} from '@trade-platform/ui-components';

@Component({
    selector: 'aix-order-form-comment-tags',
    templateUrl: 'order-form-comment-tags.html',
    styleUrls: ['order-form-comment-tags.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        AixRadioGroupComponent,
        AixDataTestingDirective,
        AixCheckboxComponent
    ]
})
export class AixOrderFormCommentTagsComponent implements OnChanges {
    private _fb = inject(FormBuilder);

    commentTag = model<string>();
    commentPrivate = model<boolean>();
    isPrivate = input<boolean | undefined>();

    commentTagChange = output<string>();
    commentPrivateChange = output<boolean | undefined>();

    commentTypeForm: UntypedFormGroup;

    commentTags: any[] = [
        {
            value: commentTagTypes.change,
            title: 'Needs Changes',
            description:
                'Select this option if material changes are necessary. To resolve a “Needs Changes” comment, the recipient will be required to edit the forms and collect new signatures.'
        },
        {
            value: commentTagTypes.clarification,
            title: 'Needs Clarification',
            description:
                'Select this option to request clarification or a supporting document upload. To resolve a “Needs Clarification” comment, the recipient will be required to reply to the comment.'
        },
        {
            value: commentTagTypes.information,
            title: 'Note',
            description:
                'Select this option to add an informational note. No action will be triggered by this selection.'
        }
    ];

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        this.commentTypeForm = this._fb.group({
            commentType: this._fb.control(null, Validators.required)
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.commentTag || changes.commentPrivate) {
            if (this.commentTypeForm.get('commentType')?.value === 'information') {
                this.commentTypeForm.setValue({
                    commentType: this.commentTag(),
                    commentPrivate: this.commentPrivate()
                });
            } else {
                this.commentTypeForm.setValue({ commentType: this.commentTag() });
            }
        }

        if (changes.isPrivate) {
            if (this.isPrivate()) {
                this.commentTags = [
                    {
                        value: commentTagTypes.information,
                        title: 'Note',
                        description:
                            'Select this option to add an informational note. No action will be triggered by this selection.'
                    }
                ];
            } else {
                this.commentTags = [
                    {
                        value: commentTagTypes.change,
                        title: 'Needs Changes',
                        description:
                            'Select this option if material changes are necessary. To resolve a “Needs Changes” comment, the recipient will be required to edit the forms and collect new signatures.'
                    },
                    {
                        value: commentTagTypes.clarification,
                        title: 'Needs Clarification',
                        description:
                            'Select this option to request clarification or a supporting document upload. To resolve a “Needs Clarification” comment, the recipient will be required to reply to the comment.'
                    },
                    {
                        value: commentTagTypes.information,
                        title: 'Note',
                        description:
                            'Select this option to add an informational note. No action will be triggered by this selection.'
                    }
                ];
            }
        }
    }

    onCommentTagChanged(tag: string) {
        if (tag === 'information') {
            this.commentTypeForm.addControl(
                'commentPrivate',
                this._fb.control(this.commentPrivate(), Validators.required)
            );
            this.commentPrivateChange.emit(this.commentPrivate());
        } else {
            this.commentTypeForm.removeControl('commentPrivate');
            this.commentPrivateChange.emit(undefined);
        }

        this.commentTag.set(tag);
        this.commentTagChange.emit(this.commentTag() as string);
    }

    onCommentPrivateChanged(value: boolean) {
        this.commentPrivate.set(value);
        this.commentPrivateChange.emit(value);
    }
}
