import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { Observable } from 'rxjs';

@Injectable()
export class OrderMapSignaturesService {
    private http = inject(HttpClient);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    mapSignatures(payload: { orderId: string }): Observable<any> {
        return this.http.post(
            `${this.environment.api}orders/${payload.orderId}/startMapSignatures`,
            null,
            { responseType: 'text' }
        );
    }
}
