import { Injectable, inject } from '@angular/core';
import { DynamicFormStore } from '../dynamic-form-store';
import { extractStreamValue } from '@trade-platform/ui-utils';
import { DynamicFormControlState } from '../dynamic-form-store/model';
import { ControlFieldConfig, NotificationConfig } from '@trade-platform/form-fields';

export interface DynamicNextPendingFieldConfig {
    config: ControlFieldConfig;
    elem: HTMLElement;
    margin: number;
}

export interface DynamicNextPendingFieldControl {
    control: DynamicFormControlState;
    elem: HTMLElement;
}

@Injectable()
export class DynamicPendingFieldsManagerService {
    private formStore = inject(DynamicFormStore);

    currentField: string;
    fields: { [key: string]: DynamicNextPendingFieldConfig } = {};
    controls: { [key: string]: DynamicNextPendingFieldControl } = {};
    field: HTMLElement;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    getIndex(id: string): number {
        return Object.keys(this.fields).indexOf(id);
    }

    addField(id: string, field: DynamicNextPendingFieldConfig) {
        this.fields[id] = field;

        if (Object.keys(this.fields).length === 1) {
            this.currentField = id;
        }
    }

    removeField(id: string) {
        delete this.fields[id];

        if (id === this.currentField) {
            const keys = Object.keys(this.fields);
            this.currentField = keys[0];
        }
    }

    nextBlockingNotification() {
        // Stop searching next field if the form is valid
        if (extractStreamValue(this.formStore.isFormValid$)) {
            return;
        }

        const notifications = Array.from(document.querySelectorAll('[aix-notification]'));
        const blockingNotification = notifications.find(currentElement => {
            const refId = currentElement.getAttribute('aix-notification') as string;

            const notification = extractStreamValue(
                this.formStore.getControlStoreByRefId(refId)
            ) as unknown as DynamicFormControlState<NotificationConfig>;

            return notification.fieldConfig.makeFormInvalid === true;
        });
        if (blockingNotification !== undefined) {
            blockingNotification.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
    }

    nextField() {
        // Stop searching next field if the form is valid
        if (extractStreamValue(this.formStore.isFormValid$)) {
            return;
        }

        // Get dynamic form controls in order by DOM tree
        const controls: { [key: string]: DynamicNextPendingFieldControl } = {};
        document.querySelectorAll('[aix-control]').forEach(item => {
            const refId = item.getAttribute('aix-control') as string;
            const formControl = extractStreamValue(this.formStore.getControlStoreByRefId(refId));
            controls[refId] = {
                control: formControl,
                elem: this.fields[refId].elem
            };
        });

        const keys = Object.keys(controls);
        let nextIndex = keys.indexOf(this.currentField) + 1;

        if (nextIndex >= keys.length) {
            nextIndex = 0;
        }

        this.currentField = keys[nextIndex];

        const control = controls[this.currentField].control;

        if (control?.validation === null) {
            this.nextField();
        } else {
            this.scrollToField(this.currentField);
        }
    }

    scrollToField(index: string) {
        if (index) {
            const elem = this.fields[index].elem;
            const input = elem.querySelector('input');
            if (elem) {
                elem.scrollIntoView({ block: 'center', behavior: 'smooth' });
                this.setAnimation(elem);

                setTimeout(() => this.afterAnimation(elem), 3100);

                if (input) {
                    // Wrapped in a setTimeout because otherwise the smooth scrollIntoView
                    // animation breaks
                    setTimeout(() => this.focusElement(input), 500);
                }
            }
        }
    }

    afterAnimation(elem: HTMLElement) {
        if (elem.querySelector('.aix-blink')) {
            elem.querySelector('.aix-blink')?.classList.remove('aix-blink-animation');
        } else {
            this.afterAnimation(elem.parentElement as HTMLElement);
        }
    }

    setAnimation(elem: HTMLElement) {
        if (elem.querySelector('.aix-blink')) {
            elem.querySelector('.aix-blink')?.classList.add('aix-blink-animation');
        } else {
            this.setAnimation(elem.parentElement as HTMLElement);
        }
    }

    focusElement(input: HTMLElement) {
        if (input.getAttribute('type') === 'text') {
            input.focus();
        }
    }
}
