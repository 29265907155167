import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { from as fromPromise } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { JsonDecoder } from 'ts.data.json';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { Register } from '../../models/register/model';

@Injectable()
export class AixRegisterService {
    private http = inject(HttpClient);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    sendRegisterInformation(jwt: string, register?: { userData: Register }) {
        return this.http
            .post(`${this.environment.redirectApi}userRequests?jwt=${jwt}`, register)
            .pipe(
                concatMap(response => fromPromise(JsonDecoder.succeed.decodeToPromise(response)))
            );
    }
}
