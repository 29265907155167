import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProductFavoritesService } from './service';
import {
    ProductFavoritesActionTypes,
    UpdateProductFavoritesAction,
    UpdateProductFavoritesFailureAction,
    UpdateProductFavoritesSuccessAction
} from './actions';
import { of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchHttpError } from '../utils/catch-http-error';

@Injectable()
export class ProductFavoritesEffects {
    private actions$ = inject(Actions);
    private service = inject(ProductFavoritesService);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    updateProductFavorite$ = createEffect(() =>
        this.actions$.pipe(
            ofType<UpdateProductFavoritesAction>(
                ProductFavoritesActionTypes.UPDATE_PRODUCT_FAVORITES
            ),
            switchMap((action: UpdateProductFavoritesAction) =>
                this.service.updateProductFavorites(action.id, action.value).pipe(
                    map(
                        productFavorite => new UpdateProductFavoritesSuccessAction(productFavorite)
                    ),
                    catchHttpError(error => of(new UpdateProductFavoritesFailureAction({ error })))
                )
            )
        )
    );
}
