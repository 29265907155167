import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Parser } from 'expr-eval';
import { DynamicFormControlState, DynamicFormState } from './model';
import { parseISO, addDays, format, isValid } from 'date-fns';
import {
    FormUID,
    getDataByPath,
    getDataPathByFieldConfig,
    getFormControlStateByRefId
} from './utils';
import { LogService, objectHasValue } from '@trade-platform/ui-utils';
import { Logger } from 'typescript-logging';
import { CalculatedFieldOptionsConfig, LimitedDecimalConfig } from '@trade-platform/form-fields';
import * as Lazy from 'lazy.js';

export interface ParsedCalcExp {
    /**
     * When status is 'complete' it means that all involved actors / repeaters had values.
     * When status is 'incomplete' it means that, even though we might have a number as a result, not all involved actors / repeaters had values.
     * - This can happen with a Repeater.forEach() that references an empty repeater.
     * - This can happen when a calcExp has several fields assocaited and one of them is hidden by a relation.
     */
    status: 'complete' | 'incomplete';
    parsedCalcExp: string;
}

const getRepeaterRegExp = () => /(Repeater\("(.+?)"\)\.forEach\("(.+?)"\))/g;
const getAllRepeaterMatches = (calculated: CalculatedFieldOptionsConfig) => {
    const calcExp = calculated.calcExp;
    const repeaterRegExp = getRepeaterRegExp();
    const allRepeaterMatches = [];
    let currentRepeaterMatches: RegExpExecArray | null;
    while ((currentRepeaterMatches = repeaterRegExp.exec(calcExp)) !== null) {
        allRepeaterMatches.push({ repeaterMatch: currentRepeaterMatches });
    }
    return allRepeaterMatches;
};

@Injectable()
export class DynamicFormCalculatedExpressions {
    private store = inject<Store<Record<string, DynamicFormState>>>(Store);
    private logService = inject(LogService);

    readonly LOG: Logger;
    formUID: FormUID;
    readonly exprEvalParser: Parser;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        this.LOG = this.logService.getLogger(
            'components.dynamic-form.dynamic-form-store.calculated-expressions'
        );
        this.exprEvalParser = new Parser();
        this.exprEvalParser.functions.addAll = function (...arr: any[]) {
            return arr.reduce((total, current) => total + Number(current), 0);
        };
        this.exprEvalParser.functions.addDaysToDate = function (dateString: string, days: number) {
            const date = parseISO(dateString.slice(0, -1));
            if (date && isValid(date)) {
                return format(addDays(date, days), 'MM/dd/yyyy');
            }
            return '';
        };
        this.exprEvalParser.functions.addDaysToToday = function (days: number) {
            return format(addDays(new Date(), days), 'MM/dd/yyyy');
        };
        this.exprEvalParser.functions.getCurrentDate = function () {
            return format(new Date(), 'MM/dd/yyyy');
        };
    }

    setFormUID(formUID: FormUID) {
        this.formUID = formUID;
    }

    /**
     * Substitutes all repeater variables inside a `Repeater().forEach()` expression for the actual repeater values.
     * Supports 1 `Repeater().forEach()` expression at the moment.
     *
     * @param calcExp The calculated expression object, with (or without) a `Repeater().forEach()` expression in it.
     */
    protected parseRepeaterExpression(calculated: CalculatedFieldOptionsConfig): ParsedCalcExp {
        this.LOG.debug(`parseRepeaterExpression()`);
        const calcExp = calculated.calcExp;
        const allRepeaterMatches = getAllRepeaterMatches(calculated);

        this.LOG.debug(() => `${allRepeaterMatches.length} Repeater expressions detected`);

        if (allRepeaterMatches.length > 0) {
            let calcExpResult = calcExp;
            const EMPTY_TOKEN = '__EMPTY__';
            allRepeaterMatches.forEach(({ repeaterMatch }) => {
                const [, wholeRepeaterExpression, repeaterRefId, repeaterExpression] =
                    repeaterMatch;

                let repeaterItemsArray: Record<string, any>[] = [];

                const repeaterState = getFormControlStateByRefId(
                    this.store,
                    this.formUID,
                    repeaterRefId
                );
                if (repeaterState) {
                    const repeaterItemsArrayIsh = getDataByPath<Record<string, any>>(
                        this.store,
                        this.formUID,
                        getDataPathByFieldConfig(repeaterState.fieldConfig)
                    );
                    repeaterItemsArray = Lazy(Object.keys(repeaterItemsArrayIsh))
                        .filter(key => key !== 'compiledTemplate')
                        .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
                        .map(key => repeaterItemsArrayIsh[key])
                        .toArray();
                }

                const substitutedExpressions: string[] = [];

                repeaterItemsArray.forEach(repeaterItem => {
                    const substitutedExpression = Object.keys(repeaterItem).reduce(
                        (expressionBeingSubstituted, key) => {
                            return expressionBeingSubstituted.replace(
                                new RegExp(`@${key}`, 'g'),
                                objectHasValue(repeaterItem[key]) && repeaterItem[key] !== ''
                                    ? repeaterItem[key]
                                    : EMPTY_TOKEN
                            );
                        },
                        repeaterExpression
                    );
                    substitutedExpressions.push(substitutedExpression);
                });

                calcExpResult = calcExpResult.replace(
                    wholeRepeaterExpression,
                    substitutedExpressions.join(', ')
                );
            });

            if (calcExpResult.indexOf('@') > -1) {
                const firstAtRef = (/@[a-zA-Z]+/g.exec(calcExpResult) as RegExpExecArray)[0];
                throw new Error(
                    `The CalcExp parser couldn't find ${firstAtRef}. This could be caused by two reasons:\n1. You misspelled ${firstAtRef}.\n2. ${firstAtRef} is configured to be displayed conditionally (which is not supported if it's part of a calcExp) and at least one of the items in the Repeater is hiding the ${firstAtRef} control.`
                );
            }

            let debugMsg = `parseRepeaterExpression: Parsing Repeater expression\n- from -> '${calcExp}'\n- to ---> '${calcExpResult}'`;
            let result: ParsedCalcExp = {
                status: 'complete',
                parsedCalcExp: calcExpResult
            };

            if (
                objectHasValue(calculated.repeaterDefaultValue) &&
                calcExpResult.indexOf('addAll()') > -1
            ) {
                const repeaterDefaultValue = objectHasValue(calculated.repeaterDefaultValue)
                    ? (calculated.repeaterDefaultValue as number).toString()
                    : '0';
                if (calcExpResult.indexOf(EMPTY_TOKEN) > -1) {
                    debugMsg += `\n- ${EMPTY_TOKEN}' was detected, so we return an empty string`;
                    result = {
                        status: 'incomplete',
                        parsedCalcExp: ''
                    };
                } else {
                    debugMsg += `\n- addAll()' was detected and we have repeaterDefaultValue, so we substitute addAll() by ${repeaterDefaultValue}`;
                    // TODO: (joanllenas) we replace for `0` when all operations surrounding addAll() are `+` or `-`,
                    // in case of `*` or `/` we'll have to replace with `1` or the corresponding neutral number for the operation.
                    result = {
                        status: 'complete',
                        parsedCalcExp: calcExpResult.split('addAll()').join(repeaterDefaultValue)
                    };
                }
            } else if (calcExpResult.indexOf('addAll()') > -1) {
                // When the repeater is empty we want to skip making any calculations
                debugMsg += `\n- addAll()' was detected, so we return an empty string`;
                result = {
                    status: 'incomplete',
                    parsedCalcExp: ''
                };
            } else if (calcExpResult.indexOf(EMPTY_TOKEN) > -1) {
                debugMsg += `\n- ${EMPTY_TOKEN}' was detected, so we return an empty string`;
                result = {
                    status: 'incomplete',
                    parsedCalcExp: ''
                };
            }
            this.LOG.debug(() => debugMsg + `\n- Result is: '${JSON.stringify(result)}'`);
            return result;
        } else {
            const res = {
                status: 'complete',
                parsedCalcExp: calcExp
            } as ParsedCalcExp;
            this.LOG.debug(`parseRepeaterExpression: No repeater expressions found.`);
            return res;
        }
    }

    protected parseExprEvalParserVariables(exp: string): string[] {
        const sanitizeDict: { [key: string]: string } = {
            ':': '__COLON__'
        };
        let newExp = '';
        Object.keys(sanitizeDict).forEach(key => {
            newExp = exp.split(key).join(sanitizeDict[key]);
        });
        const unsanitizeDict: { [key: string]: string } = {
            __COLON__: ':'
        };
        return this.exprEvalParser
            .parse(newExp)
            .variables()
            .map(variable => {
                let newVariable = '';
                Object.keys(unsanitizeDict).forEach(key => {
                    newVariable = variable.split(key).join(unsanitizeDict[key]);
                });
                return newVariable;
            });
    }

    private getInputValue(
        exp: string,
        calculated: CalculatedFieldOptionsConfig
    ): { [key: string]: string } | null {
        const refs = this.parseExprEvalParserVariables(exp);
        const varOptions: { [key: string]: string } = {};
        if (refs.length > 0) {
            refs.forEach(refId => {
                let ctrl: DynamicFormControlState | undefined;
                ctrl = undefined;
                ctrl = getFormControlStateByRefId(this.store, this.formUID, refId);
                if (ctrl) {
                    varOptions[refId] = getDataByPath(
                        this.store,
                        this.formUID,
                        getDataPathByFieldConfig(ctrl.fieldConfig)
                    );
                    if (
                        ctrl.fieldConfig.type === 'number' &&
                        (ctrl.fieldConfig as LimitedDecimalConfig).allowCommas
                    ) {
                        for (const varOptionsKey in varOptions) {
                            if (typeof varOptions[varOptionsKey] === 'string') {
                                varOptions[varOptionsKey] = varOptions[varOptionsKey]?.replace(
                                    /,/g,
                                    ''
                                );
                            }
                        }
                    }
                } else if (objectHasValue(calculated.controlDefaultValue)) {
                    varOptions[refId] = (calculated.controlDefaultValue as number).toString();
                } else {
                    throw new Error(
                        `${refId} does not have value and calculated.controlDefaultValue is not set`
                    );
                }
            });
            return varOptions;
        } else {
            return null;
        }
    }

    getParsedResult(calculated: CalculatedFieldOptionsConfig): string {
        const exp = this.parseRepeaterExpression(calculated);
        this.LOG.debug(() => `getParsedResult().exp = ${JSON.stringify(exp)}`);
        if (exp.parsedCalcExp === '') {
            this.LOG.debug(
                `getParsedResult: exp.calcExp is '' so, we skip evaluation and return an empty string`
            );
            return '';
        }
        const varOptions = this.getInputValue(exp.parsedCalcExp, calculated);

        return this.exprEvalParser.evaluate(exp.parsedCalcExp, varOptions || undefined).toString();
    }

    getExpressionRefIds(calculated: CalculatedFieldOptionsConfig): string[] {
        const allRepeaterMatches = getAllRepeaterMatches(calculated);
        if (allRepeaterMatches.length > 0) {
            const refIds: string[] = [];
            allRepeaterMatches.forEach(({ repeaterMatch }) => {
                /*
                 *
                 * The whole Array shape is:
                 * ```ts
                 * const [
                 *    ,
                 *    wholeRepeaterExpression,
                 *    repeaterRefId,
                 *    repeaterExpression
                 * ] = repeaterMatch;
                 * ```
                 */
                const [, , repeaterRefId] = repeaterMatch;
                refIds.push(repeaterRefId);
            });
            return refIds;
        } else {
            return this.parseExprEvalParserVariables(calculated.calcExp);
        }
    }
}
