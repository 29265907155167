import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchHttpError } from '@trade-platform/ui-shared';
import {
    OrderDocumentDecline,
    OrderDocumentDeclineActionTypes,
    OrderDocumentDeclineFailure,
    OrderDocumentDeclineSuccess
} from './actions';
import { OrderDocumentDeclineService } from './service';

@Injectable()
export class OrderDocumentDeclineEffects {
    private actions$ = inject(Actions);
    private orderDocumentDeclineService = inject(OrderDocumentDeclineService);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    orderDocumentDecline$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderDocumentDecline>(OrderDocumentDeclineActionTypes.ORDER_DOCUMENT_DECLINE),
            switchMap(action =>
                this.orderDocumentDeclineService.orderDocumentDecline(action.payload).pipe(
                    map(_ => new OrderDocumentDeclineSuccess()),
                    catchHttpError(error => of(new OrderDocumentDeclineFailure({ error })))
                )
            )
        )
    );
}
