import { Injectable, inject } from '@angular/core';
import { DocumentViewerDownload, DocumentViewerDownloadError } from './actions';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { getFromStorage, LogService } from '@trade-platform/ui-utils';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Logger } from 'typescript-logging';
import { inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { Document } from '../../models/document-viewer/model';
import { AppState } from '../reducers';
import { Auth } from '../../models/auth/model';
import { getFileExtension } from '../utils/file-utils';
import * as PDFJS from 'pdfjs-dist';

@Injectable()
export class DocumentViewerDownloadService {
    private store = inject<Store<AppState>>(Store);
    private http = inject(HttpClient);
    private logService = inject(LogService);

    onDownload: Subject<string> = new Subject();

    PDFJSloaded = false;

    inProgress: { [key: string]: boolean } = {};
    inProgress$: BehaviorSubject<RemoteData<unknown>> = new BehaviorSubject<RemoteData<unknown>>(
        notAsked()
    );

    readonly LOG: Logger;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        this.LOG = this.logService.getLogger('app.services.document-download-service');

        Promise.all([
            // @ts-ignore: only accepted in commonJs o esNext
            import(/* webpackChunkName: "PDFJS" */ 'pdfjs-dist/build/pdf.min.mjs')
        ]).then(() => {
            PDFJS.GlobalWorkerOptions.workerSrc = 'assets/pdf.worker.min.mjs';
            this.PDFJSloaded = true;
        });
    }

    getDocument(document: Document) {
        const docName = document.name ? document.name : 'orderDocuments';
        this.store.dispatch(new DocumentViewerDownload({ downloading: { [docName]: true } }));
        const headers = new HttpHeaders().set(
            'authorization',
            `Bearer ${(getFromStorage('auth') as Auth).accessToken}`
        );
        this.inProgress[document.href] = true;
        this.inProgress$.next(inProgress());

        this.http
            .get(document.href, { headers, responseType: 'blob', observe: 'response' })
            .subscribe(
                (response: HttpResponse<Blob>) => {
                    let filename = 'file';
                    if (document.hasOwnProperty('name')) {
                        filename = document.name as string;
                    } else if (response.headers.get('content-disposition')) {
                        const filenameRegex =
                            /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/;
                        const match = filenameRegex.exec(
                            response.headers.get('content-disposition') as string
                        );
                        if (match != null && match[1]) {
                            filename = match[1];
                        }
                    }
                    if (
                        filename &&
                        (filename.endsWith('.pdf') ||
                            filename.endsWith('.xlsx') ||
                            filename.endsWith('.csv'))
                    ) {
                        filename = filename.split('.').slice(0, 1).join('.');
                    }

                    delete this.inProgress[document.href];

                    if (Object.keys(this.inProgress).length === 0) {
                        this.inProgress$.next(success(null));
                    }

                    this.downloadDocument(response.body as Blob, filename);
                    this.store.dispatch(
                        new DocumentViewerDownload({
                            downloading: { [document.name as string]: false }
                        })
                    );
                },
                error => {
                    this.LOG.debug(() => `Download request failed with ${JSON.stringify(error)}`);
                    this.store.dispatch(
                        new DocumentViewerDownload({
                            downloading: { [document.name as string]: false }
                        })
                    );
                    this.store.dispatch(new DocumentViewerDownloadError({ downloadError: true }));
                }
            );
    }

    downloadDocument(blob: Blob, name: string) {
        const filename = `${name}.${getFileExtension(blob.type)}`;

        if (navigator.userAgent.match('CriOS')) {
            // Chrome iOS
            const reader = new FileReader();
            reader.onloadend = function (e) {
                window.open(reader.result as string);
            };
            reader.readAsDataURL(blob);
        } else {
            // Other browsers
            const blobUrl = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = blobUrl;
            a.target = '_parent';

            try {
                if ('download' in a) {
                    (<any>a).download = filename;
                }
            } catch (err) {
                // discard
            }

            document.body.appendChild(a);

            if ((window as any).Cypress) {
                // Do not attempt to actually download the file in test.
                // Just leave the anchor in there. Ensure your code doesn't
                // automatically remove it either.
                return;
            }

            a.click();
            (a.parentNode as NonNullable<typeof a.parentNode>).removeChild(a);

            // For Firefox it is necessary to delay revoking the ObjectURL
            setTimeout(() => {
                window.URL.revokeObjectURL(blobUrl);
            }, 100);
        }
    }

    download(): Observable<string> {
        return this.onDownload.asObservable();
    }
}
