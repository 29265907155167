import { JsonDecoder } from 'ts.data.json';
import {
    AchInstructions,
    BannerInfo,
    FeesAndCommissions,
    FundFamily,
    FundInfo,
    FundInfoParam,
    OfferingDetails,
    Product,
    ProductAccountType,
    ProductExploreContactInfoRecord,
    ProductExploreMaterial,
    ProductExploreRecord,
    ProductFamily,
    ProductFundSponsor,
    ProductHeaders,
    ProductRecord,
    ProductTransferAgent,
    ProductType,
    PurchaseTypeShareClass,
    SenderFirmData,
    SendExploreRecord,
    SendUserData
} from '../../models/product/model';
import { fundSponsorDecoder } from '../fund-sponsor/decoder';
import { holdingOptionListDecoder } from '../holding-option/decoder';
import { PurchaseType } from '../../models/order/model';
import { AixCheckboxItem, AixCheckboxItemQueue } from '@trade-platform/ui-components';
import { idNumberDecoder } from '../../store/models/common';

// Product Fund Sponsor
export const productFundSponsorDecoder = JsonDecoder.object<ProductFundSponsor>(
    {
        name: JsonDecoder.string,
        shortName: JsonDecoder.optional(JsonDecoder.string),
        url: JsonDecoder.string,
        logoUrl: JsonDecoder.optional(JsonDecoder.string),
        nsccNumber: JsonDecoder.optional(JsonDecoder.string),
        transferAgentFundSponsorId: JsonDecoder.optional(JsonDecoder.string)
    },
    'ProductFundSponsor'
);

// Transfer Agent
export const transferAgentDecoder = JsonDecoder.object<ProductTransferAgent>(
    {
        name: JsonDecoder.optional(JsonDecoder.string),
        shortName: JsonDecoder.optional(JsonDecoder.string),
        abaNumber: JsonDecoder.optional(JsonDecoder.string),
        phone1: JsonDecoder.optional(JsonDecoder.string),
        phone2: JsonDecoder.optional(JsonDecoder.string),
        fax1: JsonDecoder.optional(JsonDecoder.string),
        fax2: JsonDecoder.optional(JsonDecoder.string),
        addressLine1: JsonDecoder.optional(JsonDecoder.string),
        addressLine2: JsonDecoder.optional(JsonDecoder.string),
        city: JsonDecoder.optional(JsonDecoder.string),
        state: JsonDecoder.optional(JsonDecoder.string),
        zip: JsonDecoder.optional(JsonDecoder.string),
        url: JsonDecoder.optional(JsonDecoder.string),
        bankName: JsonDecoder.optional(JsonDecoder.string)
    },
    'ProductTransferAgent'
);

// Fund Info Param
export const fundInfoParamDecoder = JsonDecoder.object<FundInfoParam>(
    {
        message: JsonDecoder.string
    },
    'FundInfoParam'
);

// Fund Info
export const fundInfoDecoder = JsonDecoder.object<FundInfo>(
    {
        type: JsonDecoder.string,
        level: JsonDecoder.string,
        params: JsonDecoder.array<FundInfoParam>(fundInfoParamDecoder, 'FundInfoParam[]'),
        message: JsonDecoder.optional(JsonDecoder.string)
    },
    'FundInfo'
);

// Purchase Type
export const purchaseTypeDecoder = JsonDecoder.object<PurchaseTypeShareClass>(
    {
        id: JsonDecoder.string,
        name: JsonDecoder.oneOf<PurchaseType>(
            [
                JsonDecoder.isExactly<'Initial'>('Initial'),
                JsonDecoder.isExactly<'Additional'>('Additional')
            ],
            'PurchaseType'
        )
    },
    'PurchaseType'
);

export const purchaseTypeListDecoder = JsonDecoder.array<PurchaseTypeShareClass>(
    purchaseTypeDecoder,
    'PurchaseType[]'
);

export const fundFamilyDecoder = JsonDecoder.object<FundFamily>(
    {
        id: JsonDecoder.optional(idNumberDecoder()),
        description: JsonDecoder.optional(JsonDecoder.string),
        ownershipStructure: JsonDecoder.optional(JsonDecoder.string),
        fundStructure: JsonDecoder.optional(JsonDecoder.string)
    },
    'FundFamily'
);

export const productAccountTypeDecoder = JsonDecoder.object<ProductAccountType>(
    {
        accountType: JsonDecoder.string,
        displayName: JsonDecoder.string,
        groupName: JsonDecoder.string,
        headerName: JsonDecoder.optional(JsonDecoder.string),
        sectionName: JsonDecoder.optional(JsonDecoder.string),
        ownershipType: JsonDecoder.string,
        sequence: JsonDecoder.number,
        disabled: JsonDecoder.optional(JsonDecoder.boolean),
        tooltip: JsonDecoder.optional(JsonDecoder.string),
        columnNumber: JsonDecoder.optional(JsonDecoder.number)
    },
    'ProductAccountType'
);

// Product
export const productDecoder = JsonDecoder.object<Product>(
    {
        id: JsonDecoder.number,
        name: JsonDecoder.string,
        priceDescriptor: JsonDecoder.optional(JsonDecoder.string),
        additionalFundInfo: JsonDecoder.optional(
            JsonDecoder.array<FundInfo>(fundInfoDecoder, 'FundInfo[]')
        ),
        accountTypes: JsonDecoder.optional(
            JsonDecoder.array<ProductAccountType>(productAccountTypeDecoder, 'AccountTypes[]')
        ),
        accountTypeGroup: JsonDecoder.optional(JsonDecoder.string),
        filterAccountsByType: JsonDecoder.optional(JsonDecoder.boolean).map(Boolean),
        aiiProgramId: JsonDecoder.optional(JsonDecoder.string),
        bankAddressCity: JsonDecoder.optional(JsonDecoder.string),
        bankAddressName: JsonDecoder.optional(JsonDecoder.string),
        bankAddressPhone: JsonDecoder.optional(JsonDecoder.string),
        bankAddressState: JsonDecoder.optional(JsonDecoder.string),
        bankAddressStreet: JsonDecoder.optional(JsonDecoder.string),
        bankAddressZip: JsonDecoder.optional(JsonDecoder.string),
        bankAbaNumber: JsonDecoder.optional(JsonDecoder.string),
        closedDate: JsonDecoder.optional(JsonDecoder.string),
        cusip: JsonDecoder.optional(JsonDecoder.string),
        dstFundNumber: JsonDecoder.optional(JsonDecoder.number),
        effectiveDate: JsonDecoder.optional(JsonDecoder.string),
        fundSponsor: JsonDecoder.optional(fundSponsorDecoder),
        fundStructure: JsonDecoder.optional(JsonDecoder.string),
        holdingOptions: JsonDecoder.optional(holdingOptionListDecoder),
        isClosed: JsonDecoder.optional(JsonDecoder.boolean).map(Boolean),
        isEffective: JsonDecoder.optional(JsonDecoder.boolean).map(Boolean),
        isin: JsonDecoder.optional(JsonDecoder.boolean),
        isOnboarded: JsonDecoder.optional(JsonDecoder.boolean).map(isOnboarded =>
            isOnboarded === undefined ? true : isOnboarded
        ),
        price: JsonDecoder.optional(JsonDecoder.number),
        priceAsOf: JsonDecoder.optional(JsonDecoder.string),
        purchaseTypes: JsonDecoder.optional(purchaseTypeListDecoder),
        purchaseFrequency: JsonDecoder.optional(JsonDecoder.string),
        exemptions: JsonDecoder.optional(JsonDecoder.string),
        secCentralIndexKey: JsonDecoder.optional(JsonDecoder.string),
        shareclassShortName: JsonDecoder.optional(JsonDecoder.string),
        shareClass: JsonDecoder.succeed,
        shortName: JsonDecoder.optional(JsonDecoder.string),
        subAdvisor: JsonDecoder.optional(JsonDecoder.string),
        taxIndicator: JsonDecoder.optional(JsonDecoder.string),
        ticker: JsonDecoder.optional(JsonDecoder.string),
        trainingRequirement: JsonDecoder.optional(JsonDecoder.string),
        transferAgent: JsonDecoder.optional(transferAgentDecoder),
        transferAgentAccountNumber: JsonDecoder.optional(JsonDecoder.string),
        transferAgentCity: JsonDecoder.optional(JsonDecoder.string),
        transferAgentPhone: JsonDecoder.optional(JsonDecoder.string),
        transferAgentState: JsonDecoder.optional(JsonDecoder.string),
        transferAgentStreet: JsonDecoder.optional(JsonDecoder.string),
        transferAgentZip: JsonDecoder.optional(JsonDecoder.string),
        referenceNumber: JsonDecoder.optional(JsonDecoder.string),
        totalOfferingSize: JsonDecoder.optional(JsonDecoder.number),
        navIndicator: JsonDecoder.optional(JsonDecoder.number),
        productOverview: JsonDecoder.optional(JsonDecoder.boolean),
        achInstructions: JsonDecoder.optional(
            JsonDecoder.object<AchInstructions>(
                {
                    achAccountName: JsonDecoder.optional(JsonDecoder.string),
                    financialInstitutionName: JsonDecoder.optional(JsonDecoder.string),
                    abaRoutingNumber: JsonDecoder.optional(JsonDecoder.string),
                    achAccountNumber: JsonDecoder.optional(JsonDecoder.string)
                },
                'AdminProductProfilePart.achInstructions'
            )
        ),
        transferAgentFundId: JsonDecoder.optional(JsonDecoder.string),
        initialMinimumQualified: JsonDecoder.optional(JsonDecoder.number),
        initialMinimumNonQualified: JsonDecoder.optional(JsonDecoder.number),
        subsequentMinimumQualified: JsonDecoder.optional(JsonDecoder.number),
        subsequentMinimumNonQualified: JsonDecoder.optional(JsonDecoder.number),
        absoluteMinimum: JsonDecoder.optional(JsonDecoder.number),
        ignoreHardMinimum: JsonDecoder.optional(JsonDecoder.boolean),
        ignoreSoftMinimum: JsonDecoder.optional(JsonDecoder.boolean),
        wireAccountName: JsonDecoder.optional(JsonDecoder.string),
        payeeName: JsonDecoder.optional(JsonDecoder.string),
        fundFamily: JsonDecoder.optional(fundFamilyDecoder),
        fundOrderType: JsonDecoder.optional(
            JsonDecoder.oneOf<ProductType>(
                [
                    JsonDecoder.isExactly<'product'>('product'),
                    JsonDecoder.isExactly<'cit'>('cit'),
                    JsonDecoder.isExactly<'3-38'>('3-38'),
                    JsonDecoder.isExactly<'generic-maintenance'>('generic-maintenance')
                ],
                'ProductType'
            )
        ),
        managingBrokerDealer: JsonDecoder.optional(JsonDecoder.string),
        reviewerCanESign: JsonDecoder.optional(JsonDecoder.boolean),
        bannerInfo: JsonDecoder.optional(
            JsonDecoder.object<BannerInfo>(
                {
                    text: JsonDecoder.string,
                    tooltip: JsonDecoder.optional(JsonDecoder.string),
                    color: JsonDecoder.enumeration(
                        ['success', 'alert', 'warn', 'error', 'note', 'ok', 'brand', 'aix'],
                        'AixTagColor'
                    )
                },
                'BannerInfo'
            )
        ),
        canPurchase: JsonDecoder.optional(JsonDecoder.boolean),
        canMaintenance: JsonDecoder.optional(JsonDecoder.boolean),
        investorTypeIndicator: JsonDecoder.optional(JsonDecoder.string),
        logoUrl: JsonDecoder.optional(JsonDecoder.string),
        showProductMaterials: JsonDecoder.optional(JsonDecoder.boolean),
        showProductOverview: JsonDecoder.optional(JsonDecoder.boolean),
        fundSponsorDisplayName: JsonDecoder.optional(JsonDecoder.string),
        nsccNumber: JsonDecoder.optional(JsonDecoder.string),
        registeredIndicator: JsonDecoder.optional(JsonDecoder.string),
        securityStrategyIndicator: JsonDecoder.optional(JsonDecoder.string),
        swiftCode: JsonDecoder.optional(JsonDecoder.string),
        isReducedCommission: JsonDecoder.optional(JsonDecoder.boolean),
        feesAndCommissions: JsonDecoder.optional(
            JsonDecoder.object<FeesAndCommissions>(
                {
                    actualSellingCommission: JsonDecoder.optional(JsonDecoder.number),
                    actualManagingBDFee: JsonDecoder.optional(JsonDecoder.number),
                    actualMarketingAndDueDiligenceFee: JsonDecoder.optional(JsonDecoder.number),
                    actualPlacementFee: JsonDecoder.optional(JsonDecoder.number),
                    actualWholesalerFee: JsonDecoder.optional(JsonDecoder.number),
                    actualExpenseReimbursementFee: JsonDecoder.optional(JsonDecoder.number),
                    actualDealerManagerFee: JsonDecoder.optional(JsonDecoder.number),
                    actualOrganizationOfferingFee: JsonDecoder.optional(JsonDecoder.number),
                    maxSellingCommission: JsonDecoder.optional(JsonDecoder.number),
                    maxManagingBDFee: JsonDecoder.optional(JsonDecoder.number),
                    maxMarketingAndDueDiligenceFee: JsonDecoder.optional(JsonDecoder.number),
                    maxPlacementFee: JsonDecoder.optional(JsonDecoder.number),
                    maxWholesalerFee: JsonDecoder.optional(JsonDecoder.number),
                    maxExpenseReimbursementFee: JsonDecoder.optional(JsonDecoder.number),
                    maxDealerManagerFee: JsonDecoder.optional(JsonDecoder.number),
                    maxOrganizationOfferingFee: JsonDecoder.optional(JsonDecoder.number)
                },
                'FeesAndCommissions'
            )
        ),
        offeringDetails: JsonDecoder.optional(
            JsonDecoder.object<OfferingDetails>(
                {
                    maxOfferingAmount: JsonDecoder.optional(JsonDecoder.number),
                    offeringEquityAmount: JsonDecoder.optional(JsonDecoder.number),
                    offeringDebtAmount: JsonDecoder.optional(JsonDecoder.number),
                    equityRaisedAmount: JsonDecoder.optional(JsonDecoder.number),
                    equityRaisedAsOfDate: JsonDecoder.optional(JsonDecoder.string),
                    equityAvailableAmount: JsonDecoder.optional(JsonDecoder.number),
                    equityAvailableAsOfDate: JsonDecoder.optional(JsonDecoder.string)
                },
                'FeesAndCommissions'
            )
        ),
        frontLoadCalcMethod: JsonDecoder.optional(JsonDecoder.string),
        category: JsonDecoder.optional(JsonDecoder.string),
        securitySubtype: JsonDecoder.optional(JsonDecoder.string)
    },
    'FundData'
);

export const productHeadersDecoder = JsonDecoder.object<ProductHeaders>(
    {
        showProductMaterials: JsonDecoder.string.map(s => s === 'true'),
        showProductOverview: JsonDecoder.string.map(s => s === 'true'),
        showProductProfile: JsonDecoder.string.map(s => s === 'true')
    },
    'ProductHeaders',
    {
        showProductMaterials: 'x-product-materials',
        showProductOverview: 'x-product-overview',
        showProductProfile: 'x-product-profile'
    }
);

// ProductFamily
export const productFamilyDecoder = JsonDecoder.object<ProductFamily>(
    {
        id: JsonDecoder.number,
        fundName: JsonDecoder.string,
        fundSponsor: productFundSponsorDecoder,
        fundStructure: JsonDecoder.optional(JsonDecoder.string),
        shareClass: JsonDecoder.array<Product>(productDecoder, 'Product[]'),
        hasProductMaterial: JsonDecoder.optional(JsonDecoder.boolean)
    },
    'ProductFamily'
);

// ProductRecord
export const productRecordDecoder = JsonDecoder.object<ProductRecord>(
    {
        id: JsonDecoder.number,
        fundName: JsonDecoder.string,
        fundSponsor: productFundSponsorDecoder,
        fundStructure: JsonDecoder.optional(JsonDecoder.string),
        shareClass: JsonDecoder.array<Product>(productDecoder, 'Product[]'),
        favorite: JsonDecoder.optional(JsonDecoder.boolean),
        hasProductMaterial: JsonDecoder.optional(JsonDecoder.boolean)
    },
    'ProductRecord'
);

export const productRecordListDecoder = JsonDecoder.array<ProductRecord>(
    productRecordDecoder,
    'ProductRecord[]'
);

// ProductFilters
export const productFiltersQueueDecoder = JsonDecoder.object<AixCheckboxItemQueue>(
    {
        id: JsonDecoder.string,
        label: JsonDecoder.string,
        tooltip: JsonDecoder.optional(JsonDecoder.string)
    },
    'ProductFiltersQueue'
);

export const productFilterDecoder = JsonDecoder.object<AixCheckboxItem>(
    {
        label: JsonDecoder.optional(JsonDecoder.string),
        key: JsonDecoder.optional(JsonDecoder.string),
        queues: JsonDecoder.optional(
            JsonDecoder.array<AixCheckboxItemQueue>(
                productFiltersQueueDecoder,
                'ProductFiltersQueue[]'
            )
        )
    },
    'ProductFilter'
);

export const productFiltersDecoder = JsonDecoder.array<AixCheckboxItem>(
    productFilterDecoder,
    'ProductFilters'
);

export const productExploreContactInfoRecordDecoder =
    JsonDecoder.object<ProductExploreContactInfoRecord>(
        {
            contactType: JsonDecoder.optional(JsonDecoder.string),
            name: JsonDecoder.optional(JsonDecoder.string),
            email: JsonDecoder.optional(JsonDecoder.string),
            phoneNumber: JsonDecoder.optional(JsonDecoder.string),
            additionalInfo: JsonDecoder.optional(JsonDecoder.string)
        },
        'ProductExploreContactInfoRecord'
    );

// product explore
export const productExploreRecordDecoder = JsonDecoder.object<ProductExploreRecord>(
    {
        fundSponsorId: JsonDecoder.string,
        name: JsonDecoder.string,
        exploreLogoUrl: JsonDecoder.optional(JsonDecoder.string),
        securityTypes: JsonDecoder.optional(JsonDecoder.string),
        addressLine1: JsonDecoder.optional(JsonDecoder.string),
        addressLine2: JsonDecoder.optional(JsonDecoder.string),
        city: JsonDecoder.optional(JsonDecoder.string),
        state: JsonDecoder.optional(JsonDecoder.string),
        zip: JsonDecoder.optional(JsonDecoder.string),
        phone1: JsonDecoder.optional(JsonDecoder.string),
        url: JsonDecoder.optional(JsonDecoder.string),
        description: JsonDecoder.optional(JsonDecoder.string),
        contactInformation: JsonDecoder.optional(productExploreContactInfoRecordDecoder)
    },
    'ProductExploreRecord'
);

export const productExploreRecordListDecoder = JsonDecoder.array<ProductExploreRecord>(
    productExploreRecordDecoder,
    'ProductExploreRecord[]'
);

export const SendUserDataDecoder = JsonDecoder.object<SendUserData>(
    {
        fullName: JsonDecoder.string,
        email: JsonDecoder.string,
        id: JsonDecoder.string,
        firmId: JsonDecoder.string,
        fundSponsorId: JsonDecoder.string,
        organizationId: JsonDecoder.string
    },
    'SendUserData'
);

export const SenderFirmDataDecoder = JsonDecoder.object<SenderFirmData>(
    {
        id: JsonDecoder.number,
        name: JsonDecoder.string,
        crdNumber: JsonDecoder.string,
        type: JsonDecoder.string
    },
    'SenderFirmData'
);

export const SendExploreRecordDecoder = JsonDecoder.object<SendExploreRecord>(
    {
        id: JsonDecoder.optional(JsonDecoder.string),
        orderNumber: JsonDecoder.optional(JsonDecoder.string),
        requestType: JsonDecoder.optional(JsonDecoder.string),
        message: JsonDecoder.optional(JsonDecoder.string),
        fundSponsorId: JsonDecoder.optional(JsonDecoder.number),
        createdAt: JsonDecoder.optional(JsonDecoder.string),
        senderUserData: JsonDecoder.optional(SendUserDataDecoder),
        senderFirmData: JsonDecoder.optional(SenderFirmDataDecoder)
    },
    'SendExploreRecord'
);

export const productExploreMaterialDecoder = JsonDecoder.object<ProductExploreMaterial>(
    {
        id: JsonDecoder.string,
        name: JsonDecoder.string,
        documentType: JsonDecoder.string,
        documentDate: JsonDecoder.string,
        fileName: JsonDecoder.string,
        thumbnail: JsonDecoder.optional(JsonDecoder.string),
        isDownloading: JsonDecoder.optional(JsonDecoder.boolean),
        isSelected: JsonDecoder.optional(JsonDecoder.boolean)
    },
    'ProductExploreMaterial'
);

export const productExploreMaterialsDecoder = JsonDecoder.array<ProductExploreMaterial>(
    productExploreMaterialDecoder,
    'ProductExploreMaterial[]'
);
