import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    input,
    OnChanges,
    OnDestroy,
    OnInit,
    output,
    SimpleChanges,
    inject
} from '@angular/core';
import { fileType } from '@trade-platform/lib-enums';
import {
    BaseOrder,
    DocumentFile,
    OrderFormComment,
    RequiredDocument,
    SupplementalFile
} from '@trade-platform/ui-shared';
import { isInProgress } from 'ngx-remotedata';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
    getOwners,
    getRequiredDocumentEntityName,
    getRequiredFilesToUpload,
    getUploadedSupplementalFiles
} from '../../process/overview/utils/order-utils';
import { ReplacePayload } from '@advisor-ui/app-components';
import { AixFilelistItemsComponent } from './filelist-display/filelist-items';
import { KeyValuePipe } from '@angular/common';
import { SortByPipe } from '@trade-platform/ui-utils';
import {
    BaseOrdersStoreFacade,
    OrderFormCommentsService,
    ORDERS_STORE_FACADE
} from '@advisor-ui/orders';

export interface AixFilesMenuOption {
    id: string;
    file: DocumentFile | RequiredDocument;
    isOpen: boolean;
    type: 'uploaded' | 'required';
    name?: string;
    tooltip?: string;
    contextMap?: string;
    documentOwnerIndex?: number;
    documentOwner?: string;
    entityName?: string;
    firmId?: number;
    fundId?: number;
    holdingOptionId?: number;
}

export interface AixUploadedFilesMenuOption extends AixFilesMenuOption {
    file: DocumentFile;
    isRelationOpen: boolean;
    replacePayload: ReplacePayload | null;
    unresolvedFileComments: OrderFormComment[];
    commentsLabel?: string;
    commentsText?: string;
    supplementalFileType?: SupplementalFile;
}

export interface AixRequiredFilesMenuOption extends AixFilesMenuOption {
    file: RequiredDocument;
}

export interface DocumentOwner {
    id?: string | null;
    contextMap: string;
    alternativeContextMaps?: string[];
    fullName: string;
}

@Component({
    selector: 'aix-filelist',
    templateUrl: 'filelist.html',
    styleUrls: ['filelist.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AixFilelistItemsComponent, KeyValuePipe, SortByPipe]
})
export class AixFilelistComponent implements OnInit, OnChanges, OnDestroy {
    store = inject<BaseOrdersStoreFacade>(ORDERS_STORE_FACADE);
    commentsService = inject(OrderFormCommentsService);
    private ref = inject(ChangeDetectorRef);

    orderComments = input<OrderFormComment[]>();
    displayComponentHeader = input<boolean>();
    enableIgnore = input<boolean>(false);
    isReadOnly = input<boolean>(false);
    uploadCompleted = input<boolean>(false);
    fileUploadOpen = input<boolean>(false);

    clickElem = output<DocumentFile>();
    uploadElem = output<RequiredDocument>();
    viewElem = output<number>();
    editElem = output<DocumentFile>();
    removeElem = output<DocumentFile>();
    ignoreElem = output<AixRequiredFilesMenuOption>();
    unignoreElem = output<AixRequiredFilesMenuOption>();

    isLoading$: Observable<boolean>;
    subscriptions: Subscription[] = [];
    arrayFiles: AixUploadedFilesMenuOption[] = [];
    arrayUnlabeledFiles: AixUploadedFilesMenuOption[] = [];
    arrayRequiredFiles: AixRequiredFilesMenuOption[] = [];

    fileOwnerMap: Map<number, AixFilesMenuOption[]> = new Map<number, AixFilesMenuOption[]>();

    owners: DocumentOwner[] = [];
    uploadUrl: string;

    readonly reducerSuffix = this.store.type;
    readonly fileType = fileType;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        this.isLoading$ = this.store.orderRemoteData$.pipe(map(isInProgress));
    }

    ngOnInit() {
        this.subscriptions.push(
            this.store.orderSuccess$.subscribe(order => {
                this.uploadUrl = `${this.store.apiUrl}/${order.id}/files`;
            }),
            this.store.actions.getOrder.success$
                .pipe(filter(action => this.reducerSuffix === action.payload.reducerSuffix))
                .subscribe(action => {
                    this.updateArrays(action.payload.order);
                    this.ref.detectChanges();
                }),
            this.store.actions.loadDocumentTypes.success$.subscribe(action => {
                this.ref.detectChanges();
            }),
            this.store.actions.getFormComments.success$.subscribe(action => {
                this.updateFileComments();
                this.ref.detectChanges();
            })
        );

        if (this.store.order) {
            this.updateArrays(this.store.order);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.fileUploadOpen && changes.fileUploadOpen.currentValue) {
            // If the global uploader is opened, close any open relations or uploaders;
            this.arrayFiles.forEach((item: AixUploadedFilesMenuOption) => {
                item.isRelationOpen = false;
                item.isOpen = false;
            });
        }
    }

    loadOrder() {
        const order = this.store.order;
        if (order) {
            this.store.actions.getOrder.dispatch({
                orderId: order.id,
                reducerSuffix: this.reducerSuffix
            });
        }
    }

    updateArrays(order: BaseOrder) {
        this.owners = getOwners(order);
        // NOTE: Adding an extra item for un-labeled
        for (let i = 0; i <= this.owners.length; i++) {
            this.fileOwnerMap.set(i, [] as AixFilesMenuOption[]);
        }

        // Supplemental Files;
        const files = order.files;
        if (files) {
            const supplementalFiles: AixUploadedFilesMenuOption[] =
                getUploadedSupplementalFiles(order);

            // Split supplemental files into labeled and unlabeled
            supplementalFiles.forEach((file: AixUploadedFilesMenuOption) => {
                // Unlabeled
                if (file.file.type === fileType.unassignedSupplemental || !file.file.contextMap) {
                    const menuOption = {
                        ...file,
                        name: file.file.name,
                        ...this.getFileCommentData(file.file)
                    } as AixUploadedFilesMenuOption;

                    const current: AixFilesMenuOption[] = this.fileOwnerMap.get(
                        this.owners.length
                    ) as AixFilesMenuOption[];
                    if (current) {
                        current.push(menuOption);
                        this.fileOwnerMap.set(this.owners.length, current);
                    }
                } else {
                    // Labeled
                    const documentOwnerIndex = this.owners.findIndex(
                        (o: DocumentOwner) =>
                            file.contextMap &&
                            (file.contextMap === o.contextMap ||
                                o.alternativeContextMaps?.includes(file.contextMap))
                    );

                    const { firmId, fundId, holdingOptionId } = file.file as DocumentFile;

                    order.requiredSupplementalFiles.forEach((f: RequiredDocument) => {
                        if (
                            f.contextMap === file.contextMap &&
                            f.type === file.supplementalFileType?.name
                        ) {
                            // file.required = true;
                            file.file.descriptionBullets = f.descriptionBullets || [];
                            file.file.descriptionFooter = f.descriptionFooter || '';
                            file.file.descriptionHeader = f.descriptionHeader || '';
                        }
                    });
                    const menuOption = {
                        ...file,
                        name: file.supplementalFileType?.name,
                        tooltip: file.supplementalFileType?.tooltip,
                        documentOwnerIndex,
                        documentOwner:
                            documentOwnerIndex > -1
                                ? this.owners[documentOwnerIndex].fullName
                                : null,
                        entityName: getRequiredDocumentEntityName(order, file.file),
                        firmId,
                        fundId,
                        holdingOptionId,
                        ...this.getFileCommentData(file.file)
                    } as AixUploadedFilesMenuOption;

                    const current: AixFilesMenuOption[] = this.fileOwnerMap.get(
                        documentOwnerIndex
                    ) as AixFilesMenuOption[];
                    if (current) {
                        current.push(menuOption);
                        this.fileOwnerMap.set(documentOwnerIndex, current);
                    }
                }
            });
        }

        // Required Supplemental Files
        const requiredFiles = order.requiredSupplementalFiles;
        if (requiredFiles) {
            const requiredSupplementalFiles: AixRequiredFilesMenuOption[] =
                getRequiredFilesToUpload(order);
            requiredSupplementalFiles.forEach((file: AixRequiredFilesMenuOption) => {
                const documentOwnerIndex = this.owners.findIndex(
                    (o: DocumentOwner) =>
                        file.contextMap &&
                        (file.contextMap === o.contextMap ||
                            o.alternativeContextMaps?.includes(file.contextMap))
                );
                const { type, tooltip, firmId, fundId, holdingOptionId } =
                    file.file as RequiredDocument;
                const menuOption = {
                    ...file,
                    name: type,
                    tooltip,
                    documentOwnerIndex,
                    documentOwner:
                        documentOwnerIndex > -1 ? this.owners[documentOwnerIndex].fullName : null,
                    entityName: getRequiredDocumentEntityName(order, file.file),
                    firmId,
                    fundId,
                    holdingOptionId
                } as AixRequiredFilesMenuOption;

                const current: AixFilesMenuOption[] = this.fileOwnerMap.get(
                    documentOwnerIndex
                ) as AixFilesMenuOption[];
                if (current) {
                    current.push(menuOption);
                    this.fileOwnerMap.set(documentOwnerIndex, current);
                }
            });
        }
    }

    updateFileComments() {
        // Loop through all labeled files;
        this.fileOwnerMap.forEach((item, i) => {
            const files: AixFilesMenuOption[] = [...(item as AixFilesMenuOption[])];
            files.forEach(
                (file: AixFilesMenuOption, index: number, array: AixFilesMenuOption[]) => {
                    if (file.type === 'uploaded') {
                        file = {
                            ...file,
                            ...this.getFileCommentData(file.file as DocumentFile)
                        };
                        array[index] = file;
                    }
                }
            );
            this.fileOwnerMap.set(i, files);
        });
    }

    /**
     * Calculates the provided files comment properties based on the latest order comments data from the store;
     * @param file {DocumentFile} - the file to pull comment data for;
     * @returns an object containing the most up-to-date comment data for the given file;
     */
    private getFileCommentData(
        file: DocumentFile
    ): Pick<
        AixUploadedFilesMenuOption,
        'unresolvedFileComments' | 'commentsLabel' | 'commentsText'
    > {
        const fileComments: OrderFormComment[] =
            this.commentsService.getComments(file.formId || file.id) || [];
        return {
            unresolvedFileComments: fileComments,
            commentsLabel: `${fileComments.length} Item${fileComments.length > 1 ? 's' : ''}`,
            commentsText: `Please re-upload or remove this document in order to resolve the comment${
                fileComments.length > 1 ? 's' : ''
            }`
        };
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
