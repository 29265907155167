import {
    ChangeDetectorRef,
    Component,
    input,
    model,
    OnChanges,
    output,
    SimpleChanges,
    ViewEncapsulation,
    inject
} from '@angular/core';
import { isNonEmptyString } from '@trade-platform/ui-utils';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';
import { NgClass } from '@angular/common';

@Component({
    selector: 'aix-color-input',
    templateUrl: './aix-color-input.component.html',
    styleUrls: ['./aix-color-input.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgClass, AixDataTestingDirective]
})
export class AixColorInputComponent implements OnChanges {
    private ref = inject(ChangeDetectorRef);

    private _hexRegex = /^#[a-fA-F0-9]{3}$|^#[a-fA-F0-9]{6}$/;
    colorIsValid = false;
    hasFocus = false;
    isDirty = false;
    isEmpty = true;
    isFirstChange = true;

    color = model<string>('');

    fieldDisabled = input<boolean>();
    fieldId = input<string>();
    fieldLabel = input<string>();
    fieldHint = input<string>();
    fieldErrorHint = input<string>();
    fieldPlaceholder = input<string>();
    fieldRequired = input<boolean>();

    colorChange = output<string>();

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    getSwatchFill(): string {
        return this.colorIsValid && !this.isEmpty ? this.color() : 'transparent';
    }

    onBlur() {
        this.hasFocus = false;
    }

    onFocus() {
        this.hasFocus = true;
    }

    onInput(evt: Event) {
        const e = evt as KeyboardEvent & { target: any };
        // Handle when user selects all text and types again
        let sum = 0;
        if (e.target.value && e.target.value.length === 1) {
            sum = 1;
        }

        const cursorStart =
            !this.color() || this.color() === ''
                ? e.target.selectionStart + 1
                : e.target.selectionStart + sum;
        const cursorEnd =
            !this.color() || this.color() === ''
                ? e.target.selectionEnd + 1
                : e.target.selectionEnd + sum;
        const formattedValue = this.formatValue(e.target.value);
        this.color.set(formattedValue);
        e.target.value = formattedValue;
        e.target.setSelectionRange(cursorStart, cursorEnd);
        this.colorChange.emit(this.color());
        this.validateColor();
        this.isDirty = isNonEmptyString(this.color());
    }

    formatValue(value: string): string {
        if (value === undefined || value === '') {
            return value;
        }
        const cleanValue: string = value ? value.replace(/[^0-9a-fA-F]/g, '') : value;
        return cleanValue.length > 6 ? `#${cleanValue.slice(0, 6)}` : `#${cleanValue.slice(0, 6)}`;
    }

    validateColor() {
        if (!this.color()) {
            this.isEmpty = true;
            this.colorIsValid = false;
        } else {
            this.isEmpty = this.color().length === 0;
            this.colorIsValid = this._hexRegex.test(this.color());
        }
        this.ref.detectChanges();
    }

    isColorValid() {
        return this.colorIsValid;
    }

    isColorDirty() {
        return this.isDirty;
    }

    makePristine() {
        this.isDirty = false;
        this.isFirstChange = true;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.color) {
            this.isFirstChange = changes.color.isFirstChange();

            this.validateColor();
        }
    }
}
