import {
    ChangeDetectorRef,
    Component,
    forwardRef,
    HostBinding,
    inject,
    OnDestroy,
    OnInit
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { DynamicFormStore } from '../../dynamic-form-store';
import { RepeaterControlState } from '../../dynamic-form-store/model';
import { getHostPropertyClass } from '../../dynamic-form.constants';
import { DynamicFormHelper } from '../../dynamic-form.helper';
import {
    ControlFieldConfig,
    FieldGroupConfig,
    RepeaterFieldConfig
} from '@trade-platform/form-fields';
import { Field } from '../field.interface';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { NgClass } from '@angular/common';
import { DynamicFieldDirective } from '../dynamic-field.directive';
import { Actions, ofType } from '@ngrx/effects';
import {
    DynamicFormActionTypes,
    DynamicFormControlSetDirtyAction
} from '../../dynamic-form-store/actions';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'aix-dynamic-repeater',
    styleUrls: ['./repeater.component.scss'],
    templateUrl: './repeater.component.html',
    standalone: true,
    imports: [
        AixDataTestingDirective,
        NgClass,
        AixButtonComponent,
        forwardRef(() => DynamicFieldDirective),
        DynamicFieldDirective
    ]
})
export class AixDynamicRepeaterComponent implements Field, OnInit, OnDestroy {
    private actions$ = inject(Actions);
    private helper = inject(DynamicFormHelper);
    private store = inject<Store<any>>(Store);
    private formStore = inject(DynamicFormStore);
    private cd = inject(ChangeDetectorRef);

    // Static
    static HOST_CLASS = 'aix-flex-grid aix-form__container';

    // Decorators
    @HostBinding('class')
    classNames = AixDynamicRepeaterComponent.HOST_CLASS;

    // Store Data
    templateData = {
        compiledTemplate: [] as FieldGroupConfig[],
        fieldIsDisabled: false,
        canAddItem: false
    };

    // Other
    dirtyFields: { [key: string]: boolean } = {};
    config: RepeaterFieldConfig;
    subscriptions: Subscription[] = [];

    addLabelButtonType = BUTTON_TYPE.secondary;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        this.cd.detach();
    }

    ngOnInit() {
        const refId = this.config.refId as string;
        this.calculateClassNames();

        if (!this.config.addLabel) {
            this.config.addLabel = 'Add';
        }
        if (!this.config.removeLabel) {
            this.config.removeLabel = 'Remove';
        }

        // Control initialization
        this.formStore.addRepeater(this.config);

        const ctrlStore$ = this.formStore.getControlStoreByRefId<RepeaterControlState>(refId);

        this.subscriptions.push(
            this.actions$
                .pipe(
                    ofType<DynamicFormControlSetDirtyAction>(DynamicFormActionTypes.SET_DIRTY),
                    filter(action => this.dirtyFields.hasOwnProperty(action.refId))
                )
                .subscribe(action => {
                    this.dirtyFields[action.refId] = true;
                }),
            ctrlStore$.subscribe(ctrl => {
                const compiledTemplate = ctrl.extra.compiledTemplate as FieldGroupConfig[];

                if (ctrl.configUpdateByRelation) {
                    this.config = {
                        ...(ctrl.fieldConfig as RepeaterFieldConfig)
                    };
                }

                // Get all refIds from the template
                const templateRefIds = this.getRefIds(compiledTemplate);
                if (Object.keys(this.dirtyFields).length !== templateRefIds.length) {
                    templateRefIds.forEach(refId => {
                        this.dirtyFields[refId] = this.dirtyFields[refId] ?? false;
                    });
                }

                // Set dirty items
                this.setDirtyItems(compiledTemplate);

                // compiledTemplate
                this.templateData.compiledTemplate = compiledTemplate;

                // fieldIsDisabled
                this.templateData.fieldIsDisabled =
                    ctrl.disabledByRelation || ctrl.fieldConfig.disabled === true;

                // canAddItem
                this.templateData.canAddItem =
                    compiledTemplate.length < (this.config.limit as number);

                this.cd.detectChanges();
            })
        );
        this.cd.detectChanges();
    }

    getRefIds(config: FieldGroupConfig[]) {
        const getRefIds = (object: FieldGroupConfig[]) => {
            const refIds: string[] = [];

            const searchRefIds = (obj: any) => {
                if (obj.children && Array.isArray(obj.children)) {
                    obj.children.forEach((itm: any) => searchRefIds(itm));
                } else if (obj.refId) {
                    refIds.push(obj.refId);
                }
            };

            object.forEach((itm: any) => searchRefIds(itm));
            return refIds;
        };

        return getRefIds(config);
    }

    setDirtyItems(template: FieldGroupConfig[]) {
        template.forEach((item: FieldGroupConfig) => {
            if (item.children) {
                this.setDirtyItems(item.children as FieldGroupConfig[]);
            } else if (item.refId && this.dirtyFields[item.refId]) {
                (item as unknown as ControlFieldConfig).isDirty = true;
            }
        });
    }

    cannotAddItemsTooltip() {
        const maxItems = this.templateData.compiledTemplate.length;
        return `This form only supports ${maxItems} ${this.config.label} at this time`;
    }

    addItem() {
        if (this.templateData.canAddItem) {
            this.formStore.addEmptyRepeaterItem(this.config);
        }
    }

    removeItem(index: number) {
        const elem = this.formStore.removeRepeaterItem(this.config, index);
        this.helper.dispatchOnRemoveStoreActions(this.store, this.config, elem);
    }

    canRemoveItem(index: number) {
        return index >= (this.config.itemsAreNotRemovableBeforeIndex || 0);
    }

    // index (for reading purposes)
    itemCalculatedIndex(index: number) {
        if (this.config.itemLabelCountsInvisibleItems) {
            return index;
        }
        return index - (this.config.itemsAreInvisibleBeforeIndex || 0);
    }

    trackByFn(index: number, item: FieldGroupConfig) {
        return item.refId;
    }

    calculateClassNames() {
        this.classNames = this.config.classNames
            ? [
                  AixDynamicRepeaterComponent.HOST_CLASS,
                  ...this.helper.parseHostProperties(this.config.classNames.host)
              ].join(' ')
            : AixDynamicRepeaterComponent.HOST_CLASS;
        this.classNames = this.config.hidden
            ? this.classNames.concat(` ${getHostPropertyClass().hidden}`)
            : this.classNames;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.formStore.removeRepeater(this.config);
    }
}
