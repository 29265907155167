import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
    OrderCancel,
    OrderCancelActionTypes,
    OrderCancelFailure,
    OrderCancelSuccess
} from './actions';
import { OrderCancelService } from './service';
import { catchHttpError } from '@trade-platform/ui-shared';

@Injectable()
export class OrderCancelEffects {
    private actions$ = inject(Actions);
    private orderCancelService = inject(OrderCancelService);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    orderCancel$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderCancel>(OrderCancelActionTypes.ORDER_CANCEL),
            switchMap(action =>
                this.orderCancelService.orderCancel(action.payload.orderId).pipe(
                    map(_ => new OrderCancelSuccess()),
                    catchHttpError(error => of(new OrderCancelFailure({ error })))
                )
            )
        )
    );
}
