import { Directive, ElementRef, input, OnInit, Renderer2, inject } from '@angular/core';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';

@Directive({
    selector: '[data-testing]',
    standalone: true
})
export class AixDataTestingDirective implements OnInit {
    private el = inject(ElementRef);
    private renderer = inject(Renderer2);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    dataTestingValue = input<string | undefined>(undefined, { alias: 'data-testing' });

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    ngOnInit() {
        if (this.environment.environment === 'production') {
            this.renderer.removeAttribute(this.el.nativeElement, 'data-testing');
        } else {
            if (this.dataTestingValue()) {
                this.renderer.setAttribute(
                    this.el.nativeElement,
                    'data-testing',
                    <string>this.dataTestingValue()
                );
            }
        }
    }
}
