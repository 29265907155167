import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchHttpError } from '@trade-platform/ui-shared';
import {
    OrderDocumentUndecline,
    OrderDocumentUndeclineActionTypes,
    OrderDocumentUndeclineFailure,
    OrderDocumentUndeclineSuccess
} from './actions';
import { OrderDocumentUndeclineService } from './service';

@Injectable()
export class OrderDocumentUndeclineEffects {
    private actions$ = inject(Actions);
    private orderDocumentUndeclineService = inject(OrderDocumentUndeclineService);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    orderDocumentUndecline$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderDocumentUndecline>(
                OrderDocumentUndeclineActionTypes.ORDER_DOCUMENT_UNDECLINE
            ),
            switchMap(action =>
                this.orderDocumentUndeclineService.orderDocumentUndecline(action.payload).pipe(
                    map(res => new OrderDocumentUndeclineSuccess()),
                    catchHttpError(error => of(new OrderDocumentUndeclineFailure({ error })))
                )
            )
        )
    );
}
