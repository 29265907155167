import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { concatMap } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { productFavoriteDecoder } from '../../decoders/product-favorites/decoder';

@Injectable()
export class ProductFavoritesService {
    private http = inject(HttpClient);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    updateProductFavorites(id: number, value: boolean | undefined) {
        const action = {
            type: 'funds',
            action: value ? 'add' : 'delete',
            value: [id]
        };
        return this.http
            .put(`${this.environment.api}me/preferences`, action)
            .pipe(concatMap(p => fromPromise(productFavoriteDecoder.decodeToPromise(p))));
    }
}
