import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';

@Injectable()
export class OrderReviewCompleteService {
    private http = inject(HttpClient);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    orderReviewComplete(orderId: string, required = true): Observable<any> {
        const _required = required ? '' : '?skipReview=true';
        return this.http.post<any>(
            `${this.environment.api}orders/${orderId}/reviewComplete${_required}`,
            {
                id: orderId
            }
        );
    }
}
