import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { OrderReinstateResponse, orderReinstateResponseDecoder } from '@trade-platform/ui-shared';

@Injectable()
export class OrderReinstateService {
    private http = inject(HttpClient);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    orderReinstate(orderId: string): Observable<OrderReinstateResponse> {
        return this.http
            .post<OrderReinstateResponse>(`${this.environment.api}orders/${orderId}/reinstate`, {
                id: orderId
            })
            .pipe(concatMap(p => fromPromise(orderReinstateResponseDecoder.decodeToPromise(p))));
    }
}
