import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    viewChild,
    inject
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { DynamicFormComponent } from '@trade-platform/dynamic-forms';
import {
    AixEffectActions,
    ApplyBrandingSuccessAction,
    AppState,
    Brand,
    ClearPreviewBrandingSuccessAction,
    constants,
    ErrorWrapper,
    GetActiveBrandingAction,
    GetActiveBrandingSuccessAction,
    GetActivePreviewBrandingAction,
    GetActivePreviewBrandingSuccessAction,
    getDataFromState,
    isFeatureEnabled,
    PreviewBrandingSuccessAction,
    Profile,
    Role,
    UserTenantDataTypes
} from '@trade-platform/ui-shared';
import { AixDataTestingDirective, AixNotificationComponent } from '@trade-platform/ui-components';
import {
    ColorStyle,
    DynamicColorStyle,
    ENVIRONMENT,
    getFromStorage,
    IEnvironment,
    scrollToTop
} from '@trade-platform/ui-utils';
import {
    AixAuthService,
    AixRouteService,
    LogoutService,
    profileFeatures,
    ProfileStoreFacade,
    routeConstants
} from '@advisor-ui/app-services';
import { RemoteData } from 'ngx-remotedata';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { entityType } from '@trade-platform/lib-enums';
import { SystemNotificationsComponent } from '../system-notifications/system-notifications';
import { AsyncPipe, NgClass, NgStyle } from '@angular/common';

interface NavRoutePropsRoute {
    [type: string]: string;
    index: string;
}

interface NavRouteProps {
    label: string;
    routeName: string;
    features: string[];
    roles: string[];
    routes: NavRoutePropsRoute;
    sortOrder: number;
    tenantTypes?: UserTenantDataTypes[];
}

const navBarRoutes = {
    products: {
        label: 'Dashboard',
        routeName: 'products',
        features: [
            profileFeatures.purchases,
            profileFeatures.accountMaintenance,
            profileFeatures.productOverview
        ],
        roles: [
            constants.profileTypes.ACTIVE_INVESTOR,
            constants.profileTypes.ADMIN,
            constants.profileTypes.ADVISOR,
            constants.profileTypes.ADVISOR_READ_ONLY,
            constants.profileTypes.ASSISTANT,
            constants.profileTypes.AUTHORIZER,
            constants.profileTypes.REVIEWER,
            constants.profileTypes.SIGNER,
            constants.profileTypes.SUBMITTER,
            constants.profileTypes.SYSADMIN
        ],
        routes: {
            index: routeConstants.routes.products.index().join('/'),
            buy: routeConstants.routes.products.index().join('/'),
            maintenance: routeConstants.routes.maintenanceProducts.index().join('/')
        },
        sortOrder: 1,
        tenantTypes: [entityType.firm, entityType.fundSponsor]
    } as NavRouteProps,
    orders: {
        label: 'Orders',
        routeName: 'orders',
        features: [profileFeatures.purchases, profileFeatures.accountMaintenance],
        roles: [
            constants.profileTypes.ASSISTANT,
            constants.profileTypes.ADVISOR,
            constants.profileTypes.ACTIVE_INVESTOR,
            constants.profileTypes.REVIEWER,
            constants.profileTypes.SIGNER,
            constants.profileTypes.ADMIN
        ],
        routes: {
            index: routeConstants.routes.purchase.status().join('/'),
            buy: routeConstants.routes.purchase.status().join('/'),
            maintenance: routeConstants.routes.maintenance.status().join('/'),
            'product-materials': routeConstants.routes.productMaterialsOrders.status().join('/')
        },
        sortOrder: 2
    } as NavRouteProps,
    accounts: {
        label: 'Accounts',
        routeName: 'accounts',
        features: [profileFeatures.accounts],
        roles: [
            constants.profileTypes.ASSISTANT,
            constants.profileTypes.ADVISOR,
            constants.profileTypes.ACTIVE_INVESTOR,
            constants.profileTypes.ADMIN
        ],
        routes: {
            index: routeConstants.routes.accounts.index().join('/')
        },
        sortOrder: 3
    } as NavRouteProps,
    admin: {
        label: 'Admin',
        routeName: 'admin',
        roles: [constants.profileTypes.SYSADMIN],
        routes: {
            index: routeConstants.routes.admin.users().join('/')
        },
        sortOrder: 4
    } as NavRouteProps
};

type NavRoute = keyof typeof navBarRoutes;

export const getNavigationItems = (profile: Profile) => {
    const roles: string[] = profile.roles.map((item: Role) => item.name);
    const navBarItems = roles.reduce((accumulator, role) => {
        // Returns routes allowed for roles
        const keys = Object.keys(navBarRoutes) as NavRoute[];
        const links = keys.reduce((acc, route) => {
            if (navBarRoutes[route].roles.indexOf(role) > -1) {
                acc.push(route);
            }
            return acc;
        }, [] as NavRoute[]);

        for (const link of links) {
            const navItem = navBarRoutes[link];

            // Prevent duplication with enabled check all in one;
            // Need to revisit this if. Tenant type from the organization object doesn't work because for firm subtenants in fund sponsors still says fund sponsor - TRJ
            if (
                !accumulator.includes(navItem) &&
                ((navItem.features && navItem.features.some(isFeatureEnabled)) ||
                    !navItem.features) &&
                ((navItem.tenantTypes &&
                    ((navItem.tenantTypes.includes(entityType.firm as UserTenantDataTypes) &&
                        profile.firm) ||
                        (navItem.tenantTypes.includes(
                            entityType.fundSponsor as UserTenantDataTypes
                        ) &&
                            profile.fundSponsor) ||
                        (navItem.tenantTypes.includes(
                            entityType.holdingOption as UserTenantDataTypes
                        ) &&
                            profile.holdingOption))) ||
                    !navItem.tenantTypes)
            ) {
                accumulator.push(navItem);
            }
        }
        return accumulator;
    }, [] as NavRouteProps[]);

    return navBarItems.sort((route1, route2) => {
        return route1.sortOrder - route2.sortOrder;
    });
};

@Component({
    selector: 'aix-nav-bar',
    templateUrl: './nav-bar.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        SystemNotificationsComponent,
        AixNotificationComponent,
        NgClass,
        NgStyle,
        AixDataTestingDirective,
        RouterLink,
        AsyncPipe
    ]
})
export class AixNavBarComponent implements OnInit, OnDestroy {
    router = inject(Router);
    store = inject<Store<AppState>>(Store);
    profileStore = inject(ProfileStoreFacade);
    actions$ = inject(AixEffectActions);
    private authService = inject(AixAuthService);
    private logoutService = inject(LogoutService);
    private ref = inject(ElementRef);
    private cd = inject(ChangeDetectorRef);
    private sanitizer = inject(DomSanitizer);
    private routeService = inject(AixRouteService);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    dynamicFormRef = viewChild<DynamicFormComponent>('dynamicFormRef');
    brandingNotification = viewChild<AixNotificationComponent>('brandingNotification');
    navbar = viewChild<ElementRef>('navbar');
    supportDropdown = viewChild<ElementRef>('supportDropdown');
    profileDropdown = viewChild<ElementRef>('profileDropdown');

    navbarClasses: Record<string, boolean> = {};
    supportDropdownClasses: Record<string, any> = {};
    profileDropdownClasses: Record<string, any> = {};

    menuItems: NavRouteProps[];
    email: string;
    state$: Observable<RemoteData<Profile, ErrorWrapper>>;
    data$: Observable<Profile>;
    subscriptions: Subscription[] = [];
    supportEmail: string;
    supportPhone: string;

    isTermsAndConditions = false;
    isBrandingPreview = false;
    isRegister = false;
    isPrivacy = false;
    isOutages = false;
    isDocumentViewer = false;

    //logo = environment.assets.logo;
    logo: SafeResourceUrl | null;
    activeRouteName = '';
    activeRouteName$ = new Subject<string>();

    styles: DynamicColorStyle = {
        utilityContainer: {
            backgroundColor: null
        },
        mastheadContainer: {
            backgroundColor: null
        }
    };

    menuIconSelected: string | null = null;

    activePreviewBrand: Brand | null = null;
    activeBrand: Brand | null = null;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        const store = this.store;

        this.state$ = store.pipe(select(state => state.profile));
        this.data$ = getDataFromState(this.state$).pipe(debounceTime(50));

        this.subscriptions.push(
            this.router.events.pipe(debounceTime(50)).subscribe(event => {
                this.getNavigation();
                this.cd.markForCheck();
            }),
            this.data$.subscribe(_ => this.cd.markForCheck()),
            this.actions$
                .ofClass<PreviewBrandingSuccessAction>(PreviewBrandingSuccessAction)
                .subscribe(action => {
                    this.store.dispatch(new GetActivePreviewBrandingAction());
                    if (action && action.payload) {
                        this.previewBrand(action.payload);
                    }
                    scrollToTop();
                }),
            this.actions$
                .ofClass<ApplyBrandingSuccessAction>(ApplyBrandingSuccessAction)
                .subscribe(action => {
                    this.store.dispatch(new GetActiveBrandingAction());
                    if (action && action.payload) {
                        this.applyBrand(action.payload);
                    }
                }),
            this.actions$
                .ofClass<ClearPreviewBrandingSuccessAction>(ClearPreviewBrandingSuccessAction)
                .subscribe(action => {
                    this.store.dispatch(new GetActivePreviewBrandingAction());
                    this.clearPreviewBrand();
                    scrollToTop();
                }),
            this.actions$
                .ofClass<GetActivePreviewBrandingSuccessAction>(
                    GetActivePreviewBrandingSuccessAction
                )
                .subscribe(action => {
                    if (action && action.payload) {
                        this.activePreviewBrand = action.payload;
                    }
                }),
            this.actions$
                .ofClass<GetActiveBrandingSuccessAction>(GetActiveBrandingSuccessAction)
                .subscribe(action => {
                    if (action && action.payload) {
                        this.activeBrand = action.payload;
                    }
                }),
            this.profileStore.profileSuccess$.subscribe((profile: Profile) => {
                this.supportEmail =
                    profile.organization?.supportEmail ||
                    this.environment.contactDetails.supportEmail;
                this.supportPhone =
                    profile.organization?.supportPhoneNumber ||
                    this.environment.contactDetails.supportPhone;
            })
        );
    }

    ngOnInit() {
        if (this.authService.isLoggedInApp()) {
            this.getNavigation();
            this.store.dispatch(new GetActivePreviewBrandingAction());
            this.store.dispatch(new GetActiveBrandingAction());
        } else {
            this.subscriptions.push(
                this.profileStore.actions.get.success$.subscribe(() => {
                    this.getNavigation();
                    this.cd.markForCheck();
                })
            );
        }
        // Check for terms;
        this.subscriptions.push(
            this.router.events.subscribe((e: any) => {
                if (e.routerEvent instanceof NavigationEnd) {
                    this.isRegister = e.routerEvent.url.indexOf('register') !== -1;
                    this.isPrivacy = e.routerEvent.url.indexOf('privacy-policy') !== -1;
                    this.isOutages = e.routerEvent.url.indexOf('outage') !== -1;

                    if (this.isRegister) {
                        this.styles.utilityContainer = {
                            boxShadow: 'none'
                        };
                    }

                    this.isTermsAndConditions =
                        this.router.url === routeConstants.routes.terms.index()[0];

                    if (e.routerEvent.url.indexOf('document-viewer') !== -1) {
                        // Force navbar to display as collapsed when on the document viewer
                        this.isDocumentViewer = true;
                        this.collapseNavBar();
                    } else {
                        this.isDocumentViewer = false;
                        this.expandNavBar();
                    }

                    this.cd.markForCheck();
                }
            })
        );
    }

    getFavicon() {
        return <HTMLLinkElement>document.getElementById('favicon');
    }

    getNavbarLinkStyle(routeName: string): ColorStyle {
        return this.activePreviewBrand && this.activePreviewBrand.navbarTextColor
            ? {
                  color:
                      routeName === this.activeRouteName
                          ? this.activePreviewBrand.navbarHoverColor
                          : this.activePreviewBrand.navbarTextColor
              }
            : { color: null };
    }

    setImages(brand: Brand) {
        if (brand.favicon) {
            const favicon = this.getFavicon();
            if (favicon && favicon.href) {
                favicon.href = brand.favicon;
            }
        }
        if (brand.logo) {
            this.logo = this.sanitizer.bypassSecurityTrustResourceUrl(brand.logo);
        }
    }

    previewBrand(brand: Brand) {
        this.isBrandingPreview = true;
        this.brandingNotification()?.openNotification();
        this.setImages(brand); // Sets favicon and logo;
        if (brand.primaryColor) {
            this.styles.mastheadContainer = {
                backgroundColor: brand.primaryColor
            };
        }
        if (brand.navbarBgColor) {
            this.styles.utilityContainer = {
                backgroundColor: brand.navbarBgColor
            };
        }
        this.cd.detectChanges();
    }

    applyBrand(brand: Brand) {
        this.setImages(brand); // Sets favicon and logo;
        this.cd.detectChanges();
    }

    clearPreviewBrand() {
        this.isBrandingPreview = false;
        this.brandingNotification()?.closeNotification();
        this.styles.mastheadContainer.backgroundColor = null;
        this.styles.utilityContainer.backgroundColor = null;

        const favicon = this.getFavicon();
        if (favicon && favicon.href) {
            favicon.href =
                this.activeBrand && this.activeBrand.favicon && this.activeBrand.favicon.length > 0
                    ? this.activeBrand.favicon
                    : 'favicon.ico';
        }
        this.logo =
            this.activeBrand && this.activeBrand.logo && this.activeBrand.logo.length > 0
                ? this.sanitizer.bypassSecurityTrustResourceUrl(this.activeBrand.logo)
                : null;
        this.activePreviewBrand = null;
        this.cd.detectChanges();
    }

    onClickLink(prop: NavRouteProps) {
        const routeType = this.routeService.routeType;
        if (routeType && prop.routes.hasOwnProperty(routeType)) {
            this.router.navigateByUrl(prop.routes[routeType as string]);
        } else {
            this.router.navigateByUrl(prop.routes.index);
        }
        this.hideProfileDropdown();
        this.hideSupportDropdown();
    }

    onHoverLink(e: any) {
        if (this.activePreviewBrand?.navbarHoverColor?.length) {
            e.style.color = this.activePreviewBrand.navbarHoverColor;
        }
    }

    onLeaveMenuIcon(e: any, menu: string) {
        if (this.activePreviewBrand === null) {
            e.style.color = null;
        } else {
            e.style.color =
                this.menuIconSelected === menu
                    ? this.activePreviewBrand.navbarHoverColor
                    : this.activePreviewBrand.navbarTextColor;
        }

        this.menuIconSelected = null;
    }

    onLeaveLink(e: any, routeName: string) {
        if (this.activePreviewBrand === null) {
            e.style.color = null;
        } else {
            e.style.color =
                routeName === this.activeRouteName || routeName === 'menu-icons'
                    ? this.activePreviewBrand.navbarHoverColor
                    : this.activePreviewBrand.navbarTextColor;
        }
    }

    getNavigation() {
        const profile = getFromStorage<Profile>('profile');
        if (profile && profile.roles && profile.roles.length > 0) {
            this.menuItems = getNavigationItems(profile);
            setTimeout(() => this.getRoute(), 100);
        }
    }

    getRoute() {
        const url = this.router.url;
        const urlParts = url.replace(';', '/').split('/');
        if (urlParts && urlParts.length >= 2) {
            const routeName = urlParts[1];
            this.activeRouteName = routeName;
            this.activeRouteName$.next(routeName);
            this.cd.detectChanges();
        }
    }

    @HostListener('document:click', ['$event'])
    onClickOutside($event: MouseEvent) {
        if (!this.ref.nativeElement.contains($event.target)) {
            this.menuIconSelected = null;
            if (
                this.supportDropdown() &&
                this.supportDropdown()?.nativeElement &&
                this.supportDropdown()?.nativeElement.style
            ) {
                (this.supportDropdown() as ElementRef).nativeElement.style.color =
                    this.activePreviewBrand?.navbarTextColor;
            }
            if (
                this.profileDropdown &&
                this.profileDropdown()?.nativeElement &&
                this.profileDropdown()?.nativeElement.style
            ) {
                (this.profileDropdown() as ElementRef).nativeElement.style.color =
                    this.activePreviewBrand?.navbarTextColor;
            }
            this.hideProfileDropdown();
            this.hideSupportDropdown();
        }
    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        if (this.isDocumentViewer) {
            // This guard is for the integration tests because they can programmatically
            // scroll the document viewer, even though a regular user is prevented
            // from scrolling the window of this view
            return;
        }
        const navHeightVar = 40; // TODO: should matcth the CSS var?
        if (window.pageYOffset > navHeightVar) {
            this.collapseNavBar();
        } else {
            this.expandNavBar();
        }
    }

    expandNavBar() {
        this.navbarClasses = { '-expanded': true };
    }
    collapseNavBar() {
        this.navbarClasses = { '-collapsed': true };
    }

    showSupportDropdown() {
        this.menuIconSelected = 'support';
        (this.profileDropdown() as ElementRef).nativeElement.style.color =
            this.activePreviewBrand?.navbarTextColor;
        this.hideProfileDropdown();
        if (this.supportDropdownClasses['-open']) {
            this.hideSupportDropdown();
            return;
        }
        this.supportDropdownClasses = { '-open': true };
    }

    hideSupportDropdown() {
        if (this.supportDropdownClasses['-open']) {
            this.supportDropdownClasses = { '-closed': true };
        }
    }

    showProfileDropdown() {
        this.menuIconSelected = 'profile';
        (this.supportDropdown() as ElementRef).nativeElement.style.color =
            this.activePreviewBrand?.navbarTextColor;
        this.hideSupportDropdown();
        if (this.profileDropdownClasses['-open']) {
            this.hideProfileDropdown();
            return;
        }
        this.profileDropdownClasses = { '-open': true };
    }

    hideProfileDropdown() {
        if (this.profileDropdownClasses['-open']) {
            this.profileDropdownClasses = { '-closed': true };
        }
    }

    logOut() {
        this.hideProfileDropdown();
        this.authService.callLogout();

        if (this.authService.forceLogout) {
            this.logoutService.logout().subscribe();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
