import { Injectable, OnDestroy, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import {
    Router,
    NavigationEnd,
    NavigationStart,
    ActivationEnd,
    ChildActivationEnd
} from '@angular/router';

@Injectable()
export class AixRouteService implements OnDestroy {
    router = inject(Router);

    subscriptions: Subscription[] = [];

    private _previousRoute: string | null = null;
    private _currentRoute: string | null = null;
    private _routeType: string | null = null;

    get previousRoute(): string | null {
        return this._previousRoute;
    }
    set previousRoute(route: string | null) {
        this._previousRoute = route;
    }

    get currentRoute(): string | null {
        return this._currentRoute;
    }
    set currentRoute(route: string | null) {
        this._currentRoute = route;
    }

    get routeType(): string | null {
        return this._routeType;
    }

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        this.currentRoute = location.hash.substr(1);
        this.subscriptions.push(
            this.router.events.subscribe(e => {
                // Needed to catch route initially when accessed directly from login page,
                // before app reroutes back to login back because we don't have
                // auth data in local storage - JMH;
                if (e instanceof NavigationStart) {
                    if (!this.currentRoute) {
                        this.currentRoute = window.location.pathname;
                    }
                }

                if (e instanceof NavigationEnd) {
                    this.previousRoute = this.currentRoute;
                    this.currentRoute = window.location.hash.substr(1);
                }

                // Grab type data from Angular route;
                if (
                    (e instanceof ActivationEnd || e instanceof ChildActivationEnd) &&
                    e.snapshot &&
                    e.snapshot.data &&
                    e.snapshot.data.type
                ) {
                    this._routeType = e.snapshot.data.type;
                }
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
