import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { ApiFormError, ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { orderDecoder, orderIdDecoder } from '../../decoders/order/decoder';
import { BaseOrder, DocumentFile, OrderId, PartialOrder } from '../../models/order/model';

@Injectable()
export class OrderService {
    private http = inject(HttpClient);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    getOrder(id: string): Observable<BaseOrder> {
        return this.http
            .get<BaseOrder>(`${this.environment.api}orders/${id}`)
            .pipe(concatMap(p => fromPromise(orderDecoder.decodeToPromise(p))));
    }

    createOrder(order: PartialOrder): Observable<BaseOrder> {
        return this.http.post<BaseOrder>(`${this.environment.api}orders`, order);
        //.pipe(concatMap(p => fromPromise(orderDecoder.decodeToPromise(p))));
    }

    updateOrder(order: BaseOrder, formCompleted = false): Observable<OrderId> {
        const payload = { ...order };
        delete (payload as any).id;
        const http = this.http.patch<OrderId>(
            `${this.environment.api}orders/${order.id}?formCompleted=${formCompleted}`,
            payload
        );

        http.pipe(
            concatMap(o => fromPromise(orderIdDecoder.decodeToPromise(o))),
            map(o => {
                if (o.validation && !o.validation.orderPassed) {
                    // Force error because server validation errors are 200 OK
                    throw new ApiFormError('', o.validation.failures, '');
                }
                return o;
            })
        );

        return http;
    }

    updateOrderFiles(files: DocumentFile[], orderId: string): Observable<OrderId> {
        const filesToUpdate = files.map(file => {
            if (file.formId) {
                return {
                    id: file.id,
                    formId: file.formId,
                    name: file.name,
                    firmId: file.firmId,
                    fundId: file.fundId,
                    holdingOptionId: file.holdingOptionId
                };
            }
            return {
                id: file.id,
                contextMap: file.contextMap,
                isPrivate: file.isPrivate,
                supplementalFileTypeId: file.supplementalFileTypeId
            };
        });
        const http = this.http.put<OrderId>(
            `${this.environment.api}orders/${orderId}/files`,
            filesToUpdate
        );

        http.pipe(
            concatMap(o => fromPromise(orderIdDecoder.decodeToPromise(o))),
            map(o => {
                if (o.validation && !o.validation.orderPassed) {
                    // Force error because server validation errors are 200 OK
                    throw new ApiFormError('', o.validation.failures, '');
                }
                return o;
            })
        );

        return http;
    }

    pollOrder(id: string): Observable<BaseOrder> {
        return this.getOrder(id);
    }
}
