import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, throwError as _throw } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Profile } from '@trade-platform/ui-shared';
import { getFromStorage } from '@trade-platform/ui-utils';
import { AixAuthService } from './auth-service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
    private authService = inject(AixAuthService);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Kick user only if Profile is loaded, otherwise it's a 401 in the /me response and we have to show a modal
        // in the login component
        const profile = getFromStorage<Profile>('profile');
        return next.handle(request).pipe(
            catchError(err => {
                if (err instanceof HttpErrorResponse && profile) {
                    // Session expired || Forbidden
                    if (err.status === 401 || err.status === 403) {
                        this.authService.callLogout(err.status === 401);
                    }
                }
                return _throw(err);
            })
        );
    }
}
