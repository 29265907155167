import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    input,
    Input,
    OnDestroy,
    OnInit,
    viewChild,
    ViewChild,
    inject
} from '@angular/core';
import { Router } from '@angular/router';
import {
    AixCheckboxComponent,
    AixDataTestingDirective,
    AixModalComponent
} from '@trade-platform/ui-components';
import { Subscription } from 'rxjs';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../../../../base.orders.store.facade';

@Component({
    selector: 'aix-make-changes',
    templateUrl: './make-changes.html',
    styleUrls: ['./make-changes.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AixModalComponent, AixDataTestingDirective, AixCheckboxComponent]
})
export class AixMakeChangesComponent implements OnInit, OnDestroy {
    router = inject(Router);
    store = inject<BaseOrdersStoreFacade>(ORDERS_STORE_FACADE);
    private ref = inject(ChangeDetectorRef);

    modal = viewChild<AixModalComponent>(AixModalComponent);
    redirectUrl = input<string>();

    subscriptions: Subscription[] = [];

    acceptCancelSignatures = false;

    acceptCancelSignaturesConfirmed = (button: string) =>
        !this.acceptCancelSignatures && button !== 'Cancel';

    readonly reducerSuffix = this.store.type;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    ngOnInit() {
        this.subscriptions.push(
            this.store.actions.makeChanges.success$.subscribe(action => {
                // If make changes api call successful, re-route to order process;
                const order = this.store.order;
                if (action && action.payload && action.payload.id === order.id) {
                    this.store.actions.getOrder.dispatch({
                        orderId: order.id,
                        reducerSuffix: this.reducerSuffix
                    });
                    if (this.redirectUrl() && (this.redirectUrl()?.length ?? 0) > 0) {
                        this.router.navigateByUrl(this.redirectUrl() as string);
                    }
                }
            })
        );
    }

    public open() {
        this.modal()?.openModal();
        this.ref.detectChanges();
    }

    makeChanges() {
        const order = this.store.order;
        this.store.actions.makeChanges.dispatch({ orderId: order.id });
    }

    modalSelected(e: string) {
        switch (e) {
            case 'Yes, continue':
                this.makeChanges();
                break;
            default:
                break;
        }
    }

    cancelSignatures(e: boolean) {
        this.acceptCancelSignatures = e;
    }

    onCloseCancelSignaturesModal() {
        this.acceptCancelSignatures = false;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
