import { Injectable, signal } from '@angular/core';
import { SettlementDetails } from '@trade-platform/ui-shared';

export type CompletedTradeDetailsVisibility = 'none' | 'form-view' | 'read-only-view';

@Injectable()
export class SettlementDetailsDataService {
    settlementDetails: SettlementDetails | null = null;
    openEditDetails = signal(false);
}
