import { ApiFormErrorItem } from '@trade-platform/ui-utils';
import { FormUID } from './utils';
import { DynamicFormControlState, RefId } from './model';

export class DynamicFormActionTypes {
    static readonly ADD_CONTROL = 'DYNAMIC_FORM_ADD_CONTROL';
    static readonly REMOVE_CONTROL = 'DYNAMIC_FORM_REMOVE_CONTROL';
    static readonly REMOVE_CONTROL_AND_DATA = 'DYNAMIC_FORM_REMOVE_CONTROL_AND_DATA';
    static readonly SET_DATA = 'DYNAMIC_FORM_SET_DATA';
    static readonly SET_NAME = 'DYNAMIC_FORM_SET_NAME';
    static readonly DESTROY = 'DYNAMIC_FORM_DESTROY';

    // Single Control action types
    static readonly VALIDATE = 'DYNAMIC_FORM_CONTROL_VALIDATE';
    static readonly SET_DIRTY = 'DYNAMIC_FORM_CONTROL_SET_DIRTY';
    static readonly INJECT_SERVER_VALIDATOR = 'DYNAMIC_FORM_CONTROL_INJECT_SERVER_VALIDATOR';
    static readonly CLEAR_SERVER_VALIDATORS = 'DYNAMIC_FORM_CONTROL_CLEAR_SERVER_VALIDATORS';
    static readonly SET_CONTROL_PROPERTY = 'DYNAMIC_FORM_CONTROL_SET_CONTROL_PROPERTY';

    // Repeater action types
    static readonly SET_REPEATER_DATA = 'DYNAMIC_FORM_SET_REPEATER_DATA';
    static readonly REMOVE_REPEATER_ITEM = 'DYNAMIC_FORM_REMOVE_REPEATER_ITEM';

    // CheckboxGroup action types
    static readonly CHECKBOX_GROUP_CHANGED = 'DYNAMIC_FORM_CHECKBOX_GROUP_CHANGED';
    static readonly RECALCULATE_CHECKBOX_GROUP_DISABLED_OPTIONS =
        'DYNAMIC_FORM_CHECKBOX_GROUP_RECALCULATE_DISABLED_OPTIONS';
    static readonly RECALCULATE_CHECKBOX_GROUP_OPTIONS =
        'DYNAMIC_FORM_CHECKBOX_GROUP_RECALCULATE_OPTIONS';

    // RadioGroup action types
    static readonly RECALCULATE_RADIO_GROUP_OPTIONS =
        'DYNAMIC_FORM_RECALCULATE_RADIO_GROUP_OPTIONS';

    // RadioGroup action types
    static readonly RECALCULATE_DROPDOWN_OPTIONS = 'DYNAMIC_FORM_RECALCULATE_DROPDOWN_OPTIONS';

    // All Controls action types
    static readonly ASSIGN_PATCHED_VALUE = 'DYNAMIC_FORM_CONTROL_ASSIGN_PATCHED_VALUE';
    static readonly RECALCULATE_DISABLE_RELATIONS = 'DYNAMIC_FORM_RECALCULATE_DISABLE_RELATIONS';
    static readonly RECALCULATE_REQUIRE_RELATIONS = 'DYNAMIC_FORM_RECALCULATE_REQUIRE_RELATIONS';
    static readonly RECALCULATE_CONDITIONALS = 'DYNAMIC_FORM_RECALCULATE_CONDITIONALS';
    static readonly RECALCULATE_CALC_EXPRESSIONS = 'DYNAMIC_FORM_RECALCULATE_CALC_EXPRESSIONS';
}

// Dynamic Form actions

export class DynamicFormAddControlAction {
    readonly type = DynamicFormActionTypes.ADD_CONTROL;
    constructor(
        public readonly formUID: FormUID,
        public readonly initialState: DynamicFormControlState
    ) {}
}

export class DynamicFormRemoveControlAction {
    readonly type = DynamicFormActionTypes.REMOVE_CONTROL;
    constructor(public readonly formUID: FormUID, public readonly refId: RefId) {}
}

export class DynamicFormRemoveControlAndDataAction {
    readonly type = DynamicFormActionTypes.REMOVE_CONTROL_AND_DATA;
    constructor(public readonly formUID: FormUID, public readonly refId: RefId) {}
}

export class DynamicFormSetDataAction {
    readonly type = DynamicFormActionTypes.SET_DATA;
    constructor(
        public readonly formUID: FormUID,
        public readonly path: string,
        public readonly value: any
    ) {}
}

export class DynamicFormDestroyAction {
    readonly type = DynamicFormActionTypes.DESTROY;
    constructor(public readonly formUID: FormUID) {}
}

export class DynamicFormSetNameAction {
    readonly type = DynamicFormActionTypes.SET_NAME;
    constructor(public readonly formUID: FormUID, public readonly name: string) {}
}

// Single Control actions

export class DynamicFormControlSetDirtyAction {
    readonly type = DynamicFormActionTypes.SET_DIRTY;
    constructor(public readonly formUID: FormUID, public readonly refId: string) {}
}

export class DynamicFormControlValidateAction {
    readonly type = DynamicFormActionTypes.VALIDATE;
    constructor(public readonly formUID: FormUID, public readonly refIds: RefId[]) {}
}

export class DynamicFormControlInjectServerValidatorAction {
    readonly type = DynamicFormActionTypes.INJECT_SERVER_VALIDATOR;
    constructor(public readonly formUID: FormUID, public readonly error: ApiFormErrorItem) {}
}

export class DynamicFormControlClearServerValidatorsAction {
    readonly type = DynamicFormActionTypes.CLEAR_SERVER_VALIDATORS;
    constructor(public readonly formUID: FormUID, public readonly path: string) {}
}

export class DynamicFormControlSetPropertyAction {
    readonly type = DynamicFormActionTypes.SET_CONTROL_PROPERTY;
    constructor(
        public readonly formUID: FormUID,
        public readonly refId: string,
        public readonly path: string,
        public readonly value: any
    ) {}
}

// Repeater actions

export class DynamicFormSetRepeaterDataAction {
    readonly type = DynamicFormActionTypes.SET_REPEATER_DATA;
    constructor(
        public readonly formUID: FormUID,
        public readonly repeaterRefId: string,
        public readonly value: any
    ) {}
}

export class DynamicFormRepeaterRemoveItemAction {
    readonly type = DynamicFormActionTypes.REMOVE_REPEATER_ITEM;
    constructor(
        public readonly formUID: FormUID,
        public readonly repeaterRefId: string,
        public readonly index: number
    ) {}
}

// CheckboxGroup actions

export class DynamicFormCheckboxGroupChangedAction {
    readonly type = DynamicFormActionTypes.CHECKBOX_GROUP_CHANGED;
    constructor(
        public readonly formUID: FormUID,
        public readonly checkboxGroupRefId: string,
        public readonly optionRefId: string,
        public readonly newValue: boolean
    ) {}
}

export class DynamicFormCheckboxGroupRecalculateDisabledOptionsAction {
    readonly type = DynamicFormActionTypes.RECALCULATE_CHECKBOX_GROUP_DISABLED_OPTIONS;
    constructor(public readonly formUID: FormUID, public readonly checkboxGroupRefId: string) {}
}

export class DynamicFormCheckboxGroupRecalculateOptionsAction {
    readonly type = DynamicFormActionTypes.RECALCULATE_CHECKBOX_GROUP_OPTIONS;
    constructor(public readonly formUID: FormUID, public readonly checkboxGroupRefId: string) {}
}

export class DynamicFormRadioGroupRecalculateOptionsAction {
    readonly type = DynamicFormActionTypes.RECALCULATE_RADIO_GROUP_OPTIONS;
    constructor(public readonly formUID: FormUID, public readonly radioGroupRefId: string) {}
}

export class DynamicFormDropdownRecalculateOptionsAction {
    readonly type = DynamicFormActionTypes.RECALCULATE_DROPDOWN_OPTIONS;
    constructor(public readonly formUID: FormUID, public readonly dropdownRefId: string) {}
}

// All Controls actions

export class DynamicFormAssignPatchedValueAction {
    readonly type = DynamicFormActionTypes.ASSIGN_PATCHED_VALUE;
    constructor(public readonly formUID: FormUID) {}
}

export class DynamicFormRecalculateDisableRelationsAction {
    readonly type = DynamicFormActionTypes.RECALCULATE_DISABLE_RELATIONS;
    constructor(public readonly formUID: FormUID) {}
}

export class DynamicFormRecalculateRequiredRelationsAction {
    readonly type = DynamicFormActionTypes.RECALCULATE_REQUIRE_RELATIONS;
    constructor(public readonly formUID: FormUID) {}
}

export class DynamicFormRecalculateSetLabelConditionalsAction {
    readonly type = DynamicFormActionTypes.RECALCULATE_CONDITIONALS;
    constructor(public readonly formUID: FormUID) {}
}

export class DynamicFormRecalculateCalcExpressionsAction {
    readonly type = DynamicFormActionTypes.RECALCULATE_CALC_EXPRESSIONS;
    constructor(public readonly formUID: FormUID) {}
}

export type DynamicFormActions =
    | DynamicFormAddControlAction
    | DynamicFormRemoveControlAction
    | DynamicFormRemoveControlAndDataAction
    | DynamicFormSetDataAction
    | DynamicFormDestroyAction
    | DynamicFormSetNameAction
    | DynamicFormRecalculateDisableRelationsAction
    | DynamicFormControlSetDirtyAction
    | DynamicFormControlInjectServerValidatorAction
    | DynamicFormControlClearServerValidatorsAction
    | DynamicFormControlSetPropertyAction
    | DynamicFormControlValidateAction
    | DynamicFormRecalculateRequiredRelationsAction
    | DynamicFormRecalculateSetLabelConditionalsAction
    | DynamicFormRecalculateCalcExpressionsAction
    | DynamicFormAssignPatchedValueAction
    | DynamicFormSetRepeaterDataAction
    | DynamicFormRepeaterRemoveItemAction
    | DynamicFormCheckboxGroupChangedAction
    | DynamicFormCheckboxGroupRecalculateDisabledOptionsAction
    | DynamicFormRadioGroupRecalculateOptionsAction
    | DynamicFormDropdownRecalculateOptionsAction
    | DynamicFormCheckboxGroupRecalculateOptionsAction;
