import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    input,
    OnChanges,
    OnDestroy,
    output,
    SimpleChanges,
    viewChild,
    ViewRef,
    inject
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { keyboardEventSwitch } from '@trade-platform/ui-utils';
import { CountryCode, countryCodes } from './country-codes';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';
import { NgClass } from '@angular/common';

@Component({
    selector: 'aix-country-code-dropdown',
    templateUrl: './aix-country-code-dropdown.html',
    styleUrls: ['./aix-country-code-dropdown.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AixCountryCodeDropdownComponent)
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass, AixDataTestingDirective]
})
export class AixCountryCodeDropdownComponent implements ControlValueAccessor, OnChanges, OnDestroy {
    private ref = inject(ChangeDetectorRef);

    searchField = viewChild<ElementRef<HTMLInputElement>>('searchFieldRef');
    dropDownList = viewChild<ElementRef<HTMLUListElement>>('dropDownListRef');

    initialValue = input<string>();

    filter = output<string>();
    filterFailed = output<boolean>();
    onFocused = output();
    onBlurred = output();
    valueChanges = output<CountryCode>();
    setDirty = output();

    searchFieldValue = '';
    isSearching = false;
    isDirty = false;
    isDropdownOpen = false;
    isMousedownList = false;
    hasScroll = false;

    countryCodes = countryCodes;
    selected = 'US';

    _propagateChanges: (value: any) => void = () => ({});
    _propagateTouches: () => void = () => ({});

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.initialValue) {
            const countryCode: CountryCode = this.countryCodes.find(
                cc => cc.value === this.initialValue()
            ) as CountryCode;
            this.toggleOptionSelection(countryCode);
        }
    }

    getFlagStyle(option: CountryCode) {
        return `iti__${option?.id?.toLowerCase()}`;
    }

    getSelectedFlagStyle() {
        if (this.selected) {
            return `iti__${this.selected.toLowerCase()}`;
        } else {
            return '';
        }
    }

    getSelectedValue() {
        if (this.selected) {
            const countryCode = this.countryCodes.find(cc => cc.id === this.selected);
            if (countryCode) {
                return countryCode.value;
            }
        }
        return '';
    }

    // @override
    _applySelection() {
        this.isMousedownList = false;

        if (this.ref) {
            this.detectChanges();
        }
    }

    detectChanges() {
        if (!(this.ref as ViewRef).destroyed) {
            this.ref.detectChanges();
        }
    }

    // @override
    closeDropDown() {
        this.isSearching = false;
        this.isDropdownOpen = false;
        this.resetSelectionLabel();
    }

    // @override
    openDropdown() {
        this.isDropdownOpen = true;
        this.scrollToIndex();
    }

    // @override
    toggleOpenDropdown() {
        if (this.isDropdownOpen) {
            this.closeDropDown();
        } else {
            (<ElementRef>this.searchField()).nativeElement.focus();
            this.openDropdown();
        }
    }

    onClickOption($event: Event, option: CountryCode) {
        $event.stopPropagation();
        this.isDirty = true;
        this.toggleOptionSelection(option);
        this._applySelection();
        this.closeDropDown();
        this.detectChanges();
        this.valueChanges.emit(option);
        this.setDirty.emit();
    }

    resetSelectionLabel() {
        this.searchFieldValue = '';
        this.refreshFilter();
    }

    refreshFilter() {
        this.searchFieldValue = '';
        this.detectChanges();
    }

    onFocus($event: Event) {
        setTimeout(() => {
            $event.stopPropagation();
            this.openDropdown();
            this.onFocused.emit();
            // Detect changes to display the list
            this.detectChanges();

            // Check if it has scroll and detect changes again to apply the class
            this.hasScroll =
                (<ElementRef>this.searchField()).nativeElement.scrollHeight >
                (<ElementRef>this.searchField()).nativeElement.clientHeight;
            this.detectChanges();
        }, 100);
    }

    onBlur($event: Event) {
        $event.stopPropagation();

        setTimeout(() => {
            this.onBlurred.emit();
        }, 100);

        // Fix for Safari. When you click the scrollbar it focuses out the input text and closes the list
        if (this.isMousedownList) {
            return;
        }

        setTimeout(() => {
            const doCleanSearch = this.searchFieldValue.length;
            this.completeSelection();

            // Clean up the filter after blurring
            if (doCleanSearch) {
                this.filter.emit(this.searchFieldValue);
            }

            try {
                this.detectChanges();
            } catch (err) {
                // discard
            }
            this.onBlurred.emit(); // Doesn't work on first selection without this in addition to above - JMH;
        }, 300);
    }

    onMouseDownList() {
        this.isMousedownList = true;
    }

    onMouseLeaveList() {
        this.isMousedownList = false;
    }

    getSearchFieldValue() {
        if (this.isSearching) {
            return this.searchFieldValue;
        } else {
            return '';
        }
    }

    completeSelection() {
        if (this.isSearching) {
            const selectedItem = this.selected;
            const selectedOptionIndex = this.findIndex(-1);
            if (selectedOptionIndex > -1) {
                const filteredOptions = this._getFilteredOptions();
                const isOptionInFilter =
                    filteredOptions.findIndex(opt => opt.id === selectedItem) > -1;

                const nextOptionIndex = isOptionInFilter
                    ? // Current option selected is inside filter, so we select that option
                      this.countryCodes.findIndex(opt => opt.id === this.selected)
                    : // Otherwise we toggle the next available in the search
                      this.findNextIndex(selectedOptionIndex);

                this.toggleOptionSelection(this.countryCodes[nextOptionIndex]);
                this._applySelection();
            }
        }

        (<ElementRef>this.searchField()).nativeElement.value = '';
        this.isSearching = false;

        this.closeDropDown();
    }

    private onKeyDownKeyMatcher = keyboardEventSwitch({
        Tab: (event: KeyboardEvent) => {
            if ((<ElementRef>this.searchField()).nativeElement.value !== '' && this.isSearching) {
                this.selectNext();
                this.completeSelection();
            }
            this.closeDropDown();
        }
    });

    onKeyDown(event: KeyboardEvent) {
        event.stopPropagation();
        this.onKeyDownKeyMatcher.withEvent(event);
    }

    onKeyUpDeleteBackspace() {
        this.resetSelectionLabel();
        this.detectChanges();
    }

    private onKeyUpKeyMatcher = keyboardEventSwitch({
        Enter: (event: KeyboardEvent) => {
            if (!this.selected) {
                this.selectNext();
            }
            this.completeSelection();
            this.isDirty = true;
            this.closeDropDown();
            this.detectChanges();
            ((<ElementRef>this.searchField()).nativeElement as HTMLInputElement).blur();
        },
        Escape: (event: KeyboardEvent) => {
            this.onEscapeKey();
        },
        ArrowDown: (event: KeyboardEvent) => {
            this.isDirty = true;
            if (!this.isDropdownOpen) {
                this.openDropdown();
            }
            this.selectNext();
        },
        ArrowUp: (event: KeyboardEvent) => {
            this.isDirty = true;
            if (!this.isDropdownOpen) {
                this.openDropdown();
            }
            this.selectPrevious();
        },
        Tab: (event: KeyboardEvent) => {
            this.isDirty = true;
            this.openDropdown();
        },
        Delete: (event: KeyboardEvent) => {
            this.isDirty = true;
            this.onKeyUpDeleteBackspace();
        },
        Backspace: (event: KeyboardEvent) => {
            this.isDirty = true;
            this.onKeyUpDeleteBackspace();
        },
        default: (event: KeyboardEvent) => {
            this.isDirty = true;
            this.isSearching = true;
            this.searchFieldValue = (<ElementRef>this.searchField()).nativeElement.value;
            this.openDropdown();
            this.detectChanges();
        }
    });

    onKeyUp(event: KeyboardEvent) {
        event.stopPropagation();
        this.onKeyUpKeyMatcher.withEvent(event);

        this.filterFailed.emit(this.isSearching && this._getFilteredOptions().length === 0);
    }

    onEscapeKey() {
        this.resetSelectionLabel();
        this.closeDropDown();
    }

    selectNext() {
        const selectedOptionIndex = this.findIndex();
        const nextOptionIndex = this.findNextIndex(selectedOptionIndex);

        if (selectedOptionIndex > -1 && nextOptionIndex > -1) {
            this.toggleOptionSelection(this.countryCodes[nextOptionIndex]);
            this._applySelection();
            this.keyScrollToIndex();
        }
    }

    selectPrevious() {
        const selectedOptionIndex = this.findIndex();
        const nextOptionIndex = this.findPreviousIndex(selectedOptionIndex);

        if (selectedOptionIndex > -1 && nextOptionIndex > -1) {
            this.toggleOptionSelection(this.countryCodes[nextOptionIndex]);
            this._applySelection();
            this.keyScrollToIndex();
        }
    }

    scrollToIndex() {
        const selectedOptionIndex = this.findIndex();
        (<ElementRef>this.searchField()).nativeElement.scrollTo(0, selectedOptionIndex * 42);
    }

    keyScrollToIndex() {
        const selectedOptionIndex = this.findIndex();
        setTimeout(
            () =>
                (<ElementRef>this.searchField()).nativeElement.scrollTo(
                    0,
                    selectedOptionIndex * 42
                ),
            1
        );
    }

    findIndex(defaultIndex = 0) {
        let selectedItemIndex;

        if (this.isSearching) {
            const filteredOptions = this._getFilteredOptions();
            selectedItemIndex = filteredOptions.findIndex(opt => opt.id === this.selected);
        } else {
            selectedItemIndex = this.countryCodes.findIndex(opt => opt.id === this.selected);
        }

        if (selectedItemIndex < 0) {
            selectedItemIndex = defaultIndex;
        }

        return selectedItemIndex;
    }

    findNextIndex(selectedOptionIndex: number) {
        let nextIndex = Math.min(selectedOptionIndex + 1, this.countryCodes.length - 1);

        if (this.isSearching) {
            const filteredWrappedOptions = this._getFilteredOptions();
            const nextItemIndex =
                filteredWrappedOptions.findIndex(opt => opt.id === this.selected) + 1;
            const nextItem = filteredWrappedOptions[nextItemIndex];
            nextIndex = this.countryCodes.findIndex(opt => opt === nextItem);
        }

        return nextIndex;
    }

    findPreviousIndex(selectedOptionIndex: number) {
        let previousIndex = Math.max(selectedOptionIndex - 1, 0);

        if (this.isSearching) {
            const filteredWrappedOptions = this._getFilteredOptions();
            const previousItemIndex =
                filteredWrappedOptions.findIndex(opt => opt.id === this.selected) - 1;
            const previousItem = filteredWrappedOptions[previousItemIndex];
            previousIndex = this.countryCodes.findIndex(opt => opt === previousItem);
        }

        return previousIndex;
    }

    registerOnChange(fn: (value: any[]) => void) {
        this._propagateChanges = fn;
    }

    registerOnTouched(fn: () => void) {
        this._propagateTouches = fn;
    }

    writeValue(value: any) {
        if (value === this.selected) {
            return;
        }
        const countryCode = this.countryCodes.find(cc => cc.value === value.toString());
        if (countryCode) {
            this.selected = countryCode.id;
        }
        this._applySelection();
    }

    calculateLabel(opt: any) {
        return opt.name;
    }

    _getFilteredOptions() {
        return this.countryCodes.filter(
            item =>
                item && item.name.toLowerCase().indexOf(this.searchFieldValue.toLowerCase()) > -1
        );
    }

    toggleOptionSelection(option: CountryCode) {
        if (option) {
            this.selected = option.id;
        }
        const countryCode = this.countryCodes.find(cc => cc.id === option.id);
        if (countryCode) {
            this._propagateChanges(countryCode.value);
        }
        this._propagateTouches();
    }

    clearSelection() {
        this.selected = this.countryCodes[0].id;
    }

    ngOnDestroy() {
        this.ref.detach();
    }
}
