import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OrderFinishMapSignaturesService } from './service';
import { map, mergeMap } from 'rxjs/operators';
import { catchHttpError } from '@trade-platform/ui-shared';
import { of } from 'rxjs';
import {
    OrderFinishMapSignatures,
    OrderFinishMapSignaturesActionTypes,
    OrderFinishMapSignaturesFailure,
    OrderFinishMapSignaturesSuccess
} from './actions';

@Injectable()
export class OrderFinishMapSignaturesEffects {
    private actions$ = inject(Actions);
    private orderMapSignaturesService = inject(OrderFinishMapSignaturesService);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    orderDocumentRemove$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderFinishMapSignatures>(
                OrderFinishMapSignaturesActionTypes.ORDER_FINISH_MAP_SIGNATURES
            ),
            mergeMap(action =>
                this.orderMapSignaturesService.mapSignatures(action.payload).pipe(
                    map(res => new OrderFinishMapSignaturesSuccess(res)),
                    catchHttpError(error => of(new OrderFinishMapSignaturesFailure({ error })))
                )
            )
        )
    );
}
