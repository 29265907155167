import { Directive, input, TemplateRef, inject } from '@angular/core';

@Directive({
    selector: '[aixTemplate]',
    standalone: true
})
export class AixTemplateDirective {
    template = inject<TemplateRef<any>>(TemplateRef);

    name = input('', { alias: 'aixTemplate' });

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}
}
