import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { orderFiltersDecoder, OrdersStatusHistory } from '@trade-platform/ui-shared';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Injectable()
export class OrdersStatusHistorysService {
    private http = inject(HttpClient);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    getOrdersStatusHistory(orderId: string): Observable<OrdersStatusHistory[]> {
        return this.http
            .get<OrdersStatusHistory[]>(`${this.environment.api}orders/${orderId}/statusHistory`)
            .pipe(concatMap(p => fromPromise(orderFiltersDecoder.decodeToPromise(p))));
    }
}
