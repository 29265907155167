import {
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    TemplateRef,
    viewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProfileStoreFacade } from '@advisor-ui/app-services';
import {
    BaseOrder,
    DocumentViewerDownloadService,
    OrderStep,
    OrderStepsModel
} from '@trade-platform/ui-shared';
import {
    BaseOrdersStoreFacade,
    OrderFormCommentsPipe,
    ORDERS_STORE_FACADE
} from '@advisor-ui/orders';
import { AixTemplateConfig, AixTemplatesComponent } from '@advisor-ui/app-components';
import { hasReadOnlyAccess } from '../../../../utils';

export interface OrderStepTemplateConfig extends AixTemplateConfig {
    step: OrderStep;
    activeStep: OrderStep;
}

export type OrderStepNameMap = {
    [step in OrderStep]: string;
};

@Component({
    template: '',
    standalone: true
})
export abstract class AixOrderStepComponent implements OnDestroy {
    aixTemplates = viewChild<AixTemplatesComponent>(AixTemplatesComponent);

    public aixTemplatesInitialized = false;

    protected readonly reducerSuffix = this.storeFacade.type;
    protected readonly updateOrderReducerSuffix = 'orderFilesUpdate';

    public isReadOnly: boolean | undefined | null = false;
    public order: BaseOrder;
    protected currentStep: OrderStep;
    protected orderId: string;
    protected subscriptions: Subscription[] = [];

    private readonly stepNameMap: OrderStepNameMap = {
        1: 'Complete Forms',
        2: 'Upload Documents',
        3: 'Review Order',
        4: 'Collect Signatures',
        5: 'Finalize Order'
    };

    constructor(
        @Inject(ORDERS_STORE_FACADE) public storeFacade: BaseOrdersStoreFacade,
        public profileStoreFacade: ProfileStoreFacade,
        protected route: ActivatedRoute,
        protected router: Router,
        protected docDownloader: DocumentViewerDownloadService,
        protected ref: ChangeDetectorRef,
        protected orderFormCommentsPipe: OrderFormCommentsPipe
    ) {
        this.subscriptions.push(
            (this.route.parent as ActivatedRoute).params.subscribe(params => {
                this.orderId = params['orderId'];
                this.loadOrder();
            }),
            this.storeFacade.actions.getOrder.success$.subscribe(action => {
                this.order = action.payload.order;
                this.onOrderUpdated(this.order);
            }),
            this.storeFacade.orderSteps$.subscribe((steps: OrderStepsModel) => {
                this.currentStep = steps.currentStep;
            }),
            this.storeFacade.orderSuccess$.subscribe(order => {
                const profile = this.profileStoreFacade.profile;
                this.isReadOnly = hasReadOnlyAccess(profile, order.firm.id);
            })
        );
    }

    protected abstract onOrderUpdated(order: BaseOrder): void;

    getTemplateConfig(step: OrderStep, activeStep: OrderStep): OrderStepTemplateConfig {
        return {
            title: this.stepNameMap[step],
            step,
            activeStep
        };
    }

    getTemplate(templateName: string): TemplateRef<any> | null {
        if (this.aixTemplates()) {
            return (this.aixTemplates() as AixTemplatesComponent).getTemplate(templateName);
        }
        return null;
    }

    loadOrder() {
        this.storeFacade.actions.getOrder.dispatch({
            orderId: this.orderId,
            reducerSuffix: this.reducerSuffix
        });
    }

    loadOrderFormComments() {
        if (this.orderId) {
            this.storeFacade.actions.getFormComments.dispatch({
                data: {
                    orderId: this.orderId
                }
            });
        }
    }

    downloadTradePackage() {
        const order = this.storeFacade.order;
        this.docDownloader.getDocument({
            href: `${this.storeFacade.apiUrl}/${order.id}/download/`,
            name: order.title
        });
    }

    makeChanges() {
        this.storeFacade.actions.showMakeChangesModal.dispatch();
    }

    showPdf() {
        this.router.navigate(this.storeFacade.routes.documentViewer(this.orderId, ''));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
