import { Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';

export type AixBreadcrumbType = 'link' | 'text';

export interface AixBreadcrumbValue {
    text: string;
    routerLink?: string;
}
export interface AixBreadcrumb {
    type: AixBreadcrumbType;
    value: AixBreadcrumbValue;
}

@Component({
    selector: 'aix-breadcrumbs',
    templateUrl: './aix-breadcrumbs.component.html',
    styleUrls: ['./aix-breadcrumbs.component.scss'],
    imports: [RouterLink]
})
export class AixBreadcrumbsComponent {
    breadcrumbs = input<AixBreadcrumb[]>([]);

    constructor() {}
}
