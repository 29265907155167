<div class="begin-button">
    <button
        class="aix-btn aix-btn--primary br-btn br-btn--primary u-text-center u-full-width u-flex"
        type="button"
        [data-testing]="'begin-button'"
        (click)="isOpen = !isOpen"
    >
        <span class="begin-button-label" [class.begin-button-label__no-menu]="options().length < 2"
            >{{ label() }}</span
        >
        <span class="begin-button-chevron">
            <span class="fal fa-chevron-down begin-button-chevron__icon"></span>
        </span>
    </button>
    <ul class="begin-button-menu aix-flex-grid aix-flex-grid__col" [hidden]="!isOpen">
        @for (element of options(); track element) {
        <li class="u-pt8 u-pb8 u-pr20 u-pl20">
            <aix-button
                class="u-text-left u-full-width"
                (click)="optionClicked(element)"
                [buttonType]="linkButtonType"
                [buttonLabel]="element.label"
                [data-testing]="element.value.toLowerCase() + '-menu-button'"
            >
            </aix-button>
        </li>
        }
    </ul>
</div>
