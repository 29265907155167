import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    HostBinding,
    input,
    model,
    OnChanges,
    OnInit,
    output,
    SimpleChanges,
    viewChild,
    inject
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';
import { NgStyle } from '@angular/common';

@Component({
    selector: 'aix-textarea',
    styleUrls: ['./aix-textarea.component.scss'],
    templateUrl: './aix-textarea.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AixTextareaComponent)
        }
    ],
    standalone: true,
    imports: [NgStyle, AixDataTestingDirective]
})
export class AixTextareaComponent implements OnInit, ControlValueAccessor, OnChanges {
    private cd = inject(ChangeDetectorRef);

    _propagateChanges: (value: string) => void = () => ({});
    _propagateTouches: () => void = () => ({});

    config = input<any>();
    isStandalone = input<boolean>(true);
    isRequired = input<boolean>(false);
    isDisabled = input<boolean>(false);
    isDirty = model<boolean>(false);
    isValid = input<boolean>(false);
    inputStyle = input<any>({});
    showLabel = input<boolean>(true);
    height = input<number>(140);
    initialValue = input<string>('');
    hint = input<string>('');

    valueChanges = output<string>();
    setDirty = output();

    inputField = viewChild<ElementRef<HTMLInputElement>>('inputField');
    labelElement = viewChild<ElementRef<HTMLElement>>('labelElement');

    @HostBinding('attr.aix-control')
    aixControl: string;

    paddingTop = 28;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    ngOnInit() {
        setTimeout(() => this.calculateMargin(), 0);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.initialValue) {
            (<ElementRef>this.inputField()).nativeElement.value = this.initialValue();
        }
    }

    calculateMargin() {
        if (this.labelElement()) {
            this.paddingTop = (<ElementRef>this.labelElement()).nativeElement.clientHeight + 4;
            this.cd.detectChanges();
        }
    }

    onUserInput(evt: Event | ClipboardEvent) {
        const val = (evt.target as HTMLInputElement).value;
        if (!this.isDirty()) {
            this.setDirty.emit();

            // It's standalone, we set the dirty state here instead through the store
            if (this.isStandalone()) {
                this.isDirty.set(true);
            }
        }
        this._propagateChanges(val);
        this.valueChanges.emit(val);
    }

    writeValue(value: any) {
        (<ElementRef>this.inputField()).nativeElement.value = value;
    }

    registerOnChange(fn: (value: string) => void) {
        this._propagateChanges = fn;
    }

    registerOnTouched(fn: () => void) {
        this._propagateTouches = fn;
    }
}
