import {
    Directive,
    ElementRef,
    HostBinding,
    input,
    OnChanges,
    OnDestroy,
    Renderer2,
    SimpleChanges,
    inject
} from '@angular/core';
import { isSuccess, RemoteData } from 'ngx-remotedata';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

export interface LoadingConfig {
    state$: Observable<RemoteData<any>[]>;
    loadThreshold: number;
}

@Directive({
    selector: '[aixLoading]',
    standalone: true
})
export class AixLoadingDirective implements OnChanges, OnDestroy {
    private el = inject(ElementRef);
    private renderer = inject(Renderer2);

    aixLoadingConfig = input.required<LoadingConfig>();

    @HostBinding('class')
    class = 'u-relative';

    isLoading$: Observable<boolean>;

    eLoader: any;
    spawned = false;

    subscriptions: Subscription[] = [];

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    despawn() {
        if (this.spawned && this.eLoader) {
            this.renderer.removeChild(this.el.nativeElement, this.eLoader);
            this.spawned = false;
        }
    }

    spawn() {
        if (!this.spawned) {
            this.eLoader = this.renderer.createElement('div');
            this.renderer.setAttribute(this.eLoader, 'class', 'shimmer');
            this.renderer.appendChild(this.el.nativeElement, this.eLoader);
            this.spawned = true;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.aixLoadingConfig && changes.aixLoadingConfig.firstChange) {
            this.isLoading$ = this.aixLoadingConfig().state$.pipe(
                map(states => {
                    const isLoading =
                        states.filter(isSuccess).length < this.aixLoadingConfig().loadThreshold;
                    return isLoading;
                })
            );
            this.subscriptions.push(
                this.isLoading$.subscribe((val: boolean) => {
                    if (val) {
                        this.spawn();
                    } else {
                        this.despawn();
                    }
                })
            );
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
