import { importProvidersFrom } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AixSharedLibModule } from '@trade-platform/ui-shared';
import { DynamicFormsModule } from '@trade-platform/dynamic-forms';
import { AixUiComponentsModule } from '@trade-platform/ui-components';
import { AixUiUtilsModule } from '@trade-platform/ui-utils';
import { AgGridModule } from 'ag-grid-angular';
import { DateFnsModule } from 'ngx-date-fns';
import { RemoteDataModule } from 'ngx-remotedata';
import { LetDirective, PushPipe } from '@ngrx/component';

export const ORDERS_ROUTES: Routes = [
    {
        path: '',
        loadComponent: () => import('./base.orders.component').then(m => m.AixOrdersComponent),
        providers: [
            importProvidersFrom(
                CommonModule,
                FormsModule,
                ReactiveFormsModule,
                RouterModule,
                AixSharedLibModule,
                DynamicFormsModule,
                AixUiComponentsModule,
                AixUiUtilsModule,
                AgGridModule,
                DateFnsModule,
                RemoteDataModule,
                LetDirective,
                PushPipe
            )
        ]
    }
];
