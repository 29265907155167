import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchHttpError } from '@trade-platform/ui-shared';
import { getFromStorage, setToStorage, withStorage } from '@trade-platform/ui-utils';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
    ApplyBrandingAction,
    ApplyBrandingFailureAction,
    ApplyBrandingSuccessAction,
    BrandingActionTypes,
    ClearPreviewBrandingAction,
    ClearPreviewBrandingSuccessAction,
    GetActiveBrandingAction,
    GetActiveBrandingSuccessAction,
    GetActivePreviewBrandingAction,
    GetActivePreviewBrandingSuccessAction,
    LoadBrandingAction,
    LoadBrandingFailureAction,
    LoadBrandingSuccessAction,
    PreviewBrandingAction,
    PreviewBrandingSuccessAction,
    SaveBrandingAction,
    SaveBrandingFailureAction,
    SaveBrandingSuccessAction
} from './actions';
import { Brand } from '../../models/branding/model';
import { BrandingService } from './service';

@Injectable()
export class BrandingEffects {
    private actions$ = inject(Actions);
    private service = inject(BrandingService);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    getActiveBranding$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetActiveBrandingAction>(BrandingActionTypes.GET_ACTIVE_BRANDING),
            map(() => {
                return new GetActiveBrandingSuccessAction(getFromStorage('activeBrand') as Brand);
            })
        )
    );

    loadBranding$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadBrandingAction>(BrandingActionTypes.LOAD_BRANDING),
            switchMap(() => {
                return this.service.loadBranding().pipe(
                    map(branding => {
                        return new LoadBrandingSuccessAction(branding);
                    }),

                    catchHttpError(error => of(new LoadBrandingFailureAction({ error })))
                );
            })
        )
    );

    applyBranding$ = createEffect(() =>
        this.actions$.pipe(
            ofType<ApplyBrandingAction>(BrandingActionTypes.APPLY_BRANDING),
            switchMap(() => {
                return this.service.loadBranding().pipe(
                    map(branding => {
                        setToStorage('activeBrand', branding);
                        return new ApplyBrandingSuccessAction(branding);
                    }),
                    catchHttpError(error => of(new ApplyBrandingFailureAction({ error })))
                );
            })
        )
    );

    saveBranding$ = createEffect(() =>
        this.actions$.pipe(
            ofType<SaveBrandingAction>(BrandingActionTypes.SAVE_BRANDING),
            switchMap(action => {
                return this.service.saveBranding(action.payload).pipe(
                    map(branding => {
                        return new SaveBrandingSuccessAction(branding);
                    }),

                    catchHttpError(error => of(new SaveBrandingFailureAction({ error })))
                );
            })
        )
    );

    previewBranding$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PreviewBrandingAction>(BrandingActionTypes.PREVIEW_BRANDING),
            map(action => {
                setToStorage('activePreviewBrand', action.payload);
                return new PreviewBrandingSuccessAction(action.payload);
            })
        )
    );

    clearPreviewBranding$ = createEffect(() =>
        this.actions$.pipe(
            ofType<ClearPreviewBrandingAction>(BrandingActionTypes.CLEAR_PREVIEW_BRANDING),
            map(() => {
                withStorage(storage => storage.removeItem('activePreviewBrand'));
                return new ClearPreviewBrandingSuccessAction();
            })
        )
    );

    getActivePreviewBranding$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetActivePreviewBrandingAction>(BrandingActionTypes.GET_ACTIVE_PREVIEW_BRANDING),
            map(() => {
                return new GetActivePreviewBrandingSuccessAction(
                    getFromStorage('activePreviewBrand') as Brand
                );
            })
        )
    );
}
