import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    input,
    OnChanges,
    OnInit,
    output,
    SimpleChanges,
    inject
} from '@angular/core';

@Component({
    selector: 'aix-pdf',
    templateUrl: './aix-pdf.component.html',
    styleUrls: ['./aix-pdf.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class AixPdfComponent implements OnInit, OnChanges {
    page = input(1);
    width = input(400);
    height = input(300);
    rotation = input(0);
    pdf = input<any>();
    onRenderError = output<any>();

    scaleType = 'fit';

    scale = 1;
    scaleFit = 1;

    elem: HTMLElement;
    canvas: HTMLCanvasElement;
    ctx: CanvasRenderingContext2D;

    renderTask: any;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        const ref = inject(ElementRef);

        this.elem = ref.nativeElement;
    }

    ngOnInit() {
        this.canvas = this.elem.querySelector('canvas') as HTMLCanvasElement;
        this.ctx = this.canvas.getContext('2d') as CanvasRenderingContext2D;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.page || changes.width || changes.height) {
            this.renderPage(this.page());
        }
    }

    renderPage(num: number) {
        if (this.renderTask && this.renderTask._internalRenderTask) {
            this.renderTask._internalRenderTask.cancel();
        }

        this.pdf().getPage(num).then(this.onGetPage.bind(this));
    }

    updateScale(page: any) {
        const viewport = page.getViewport({ scale: 1 });

        switch (this.scaleType) {
            case 'fit':
                if (this.rotation() === 0 || this.rotation() === 180) {
                    this.scale = this.elem.getBoundingClientRect().height / (viewport.height + 10);
                } else {
                    this.scale = this.elem.getBoundingClientRect().width / (viewport.height + 10);
                }

                this.scaleFit = this.scale;
                break;

            case 'showAll':
            case 'none':
                break;
        }
    }

    onGetPage(page: any) {
        this.updateScale(page);

        const viewport = page.getViewport({ scale: this.scale });

        this.renderTask = page.render({
            canvasContext: this.ctx,
            viewport: viewport
        });

        this.renderTask.promise
            .then(this.onRenderPage.bind(this))
            .catch(this.onRenderPageError.bind(this));
    }

    onRenderPage() {}

    onRenderPageError(error: Error) {
        this.onRenderError.emit(error);
    }
}
