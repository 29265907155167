import {
    ChangeDetectorRef,
    Component,
    input,
    OnDestroy,
    OnInit,
    viewChild,
    inject
} from '@angular/core';
import { DocumentViewerDownloadService } from '@trade-platform/ui-shared';
import { OrderFormCommentsService } from '../order-comments/order-form-comments.helper';
import { AixAbstractUploadComponent } from '../abstract-upload';
import { AixFilelistComponent } from './filelist/filelist';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../base.orders.store.facade';
import { AsyncPipe } from '@angular/common';
import { AixUploadDocumentComponent } from '@advisor-ui/app-components';
import { AixLabelUploadedFileDocumentsComponent } from './file-documents/label-uploaded-file-documents';
import { AixLabelUploadedFormDocumentsComponent } from './form-documents/label-uploaded-form-documents';
import {
    AixCheckboxComponent,
    AixDataTestingDirective,
    AixModalComponent
} from '@trade-platform/ui-components';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'aix-file-upload',
    templateUrl: './file-upload.html',
    styleUrls: ['./file-upload.scss'],
    standalone: true,
    imports: [
        AixDataTestingDirective,
        AixUploadDocumentComponent,
        AixLabelUploadedFileDocumentsComponent,
        AixLabelUploadedFormDocumentsComponent,
        AixFilelistComponent,
        AixModalComponent,
        AsyncPipe,
        AixCheckboxComponent,
        FormsModule
    ]
})
export class AixFileUploadComponent
    extends AixAbstractUploadComponent
    implements OnInit, OnDestroy
{
    environment: IEnvironment;
    storeFacade: BaseOrdersStoreFacade;
    docDownloader: DocumentViewerDownloadService;
    commentsService: OrderFormCommentsService;
    cd: ChangeDetectorRef;

    filelistRef = viewChild<AixFilelistComponent>('filelistRef');

    enableIgnore = input<boolean>(false);
    isReadOnly = input<boolean>(false);
    type = input<string>(this.fileType.supplemental);
    instructionalText = input<string>('');

    acceptIgnoreRequired = (button: string) =>
        !this.acknowledgeIgnoreRequired && button !== 'Cancel';

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        const environment = inject<IEnvironment>(ENVIRONMENT);
        const storeFacade = inject<BaseOrdersStoreFacade>(ORDERS_STORE_FACADE);
        const docDownloader = inject(DocumentViewerDownloadService);
        const commentsService = inject(OrderFormCommentsService);
        const cd = inject(ChangeDetectorRef);

        super(environment, storeFacade, docDownloader, commentsService, cd);
        this.environment = environment;
        this.storeFacade = storeFacade;
        this.docDownloader = docDownloader;
        this.commentsService = commentsService;
        this.cd = cd;
    }
}
