import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchHttpError } from '@trade-platform/ui-shared';
import {
    OrderReviewComplete,
    OrderReviewCompleteActionTypes,
    OrderReviewCompleteFailure,
    OrderReviewCompleteSuccess
} from './actions';
import { OrderReviewCompleteService } from './service';

@Injectable()
export class OrderReviewCompleteEffects {
    private actions$ = inject(Actions);
    private orderReviewCompleteService = inject(OrderReviewCompleteService);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    orderReviewComplete$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderReviewComplete>(OrderReviewCompleteActionTypes.ORDER_REVIEW_COMPLETE),
            switchMap(action => {
                return this.orderReviewCompleteService
                    .orderReviewComplete(action.payload.orderId, action.payload.required)
                    .pipe(
                        map(_ => new OrderReviewCompleteSuccess()),
                        catchHttpError(error => of(new OrderReviewCompleteFailure({ error })))
                    );
            })
        )
    );
}
