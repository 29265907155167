import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Data, Route } from '@angular/router';
import { Store } from '@ngrx/store';
import { AixErrorAction, AppState, ErrorWrapper, Profile, Role } from '@trade-platform/ui-shared';
import { isSuccess } from 'ngx-remotedata';
import { map } from 'rxjs/operators';
import { getFromStorage } from '@trade-platform/ui-utils';

type ProfileCheckFn = (profile: Profile) => boolean;
interface ProfileCheckData extends Data {
    profileChecklist: ProfileCheckFn[];
}

export const profileHasRoles = (roles: Role[], profile: Profile) => {
    return roles.every(role => profile.roles.some(profileRole => profileRole.name === role.name));
};

/*
 * Validates all profile checking functions against the current user
 */

@Injectable()
export class ProfileChecklistGuard {
    private store = inject<Store<AppState>>(Store);

    profile$ = this.store.select(state => state.profile);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    validateChecklist(profileChecklist: ProfileCheckFn[], path?: string) {
        return this.profile$.pipe(
            map(profile => {
                if (isSuccess(profile)) {
                    const result = profileChecklist.every(profileCheckFn => {
                        const prof = getFromStorage('profile') as Profile;
                        const value = profileCheckFn(profile.value);

                        if (!value) {
                            const message = path
                                ? `User "${prof?.email}" tried to access to "/${path}" but feature "${profileCheckFn.name}" is not available.`
                                : `User "${prof?.email}" tried to access to a route but feature "${profileCheckFn.name}" is not available.`;

                            this.store.dispatch(
                                new AixErrorAction({ error: new ErrorWrapper(message) })
                            );
                        }

                        return value;
                    });

                    return isSuccess(profile) && result;
                }
                return false;
            })
        );
    }

    canLoad(route: Route) {
        return this.validateChecklist(
            (route.data as ProfileCheckData).profileChecklist,
            route.path
        );
    }

    canActivate(route: ActivatedRouteSnapshot) {
        return this.validateChecklist((route.data as ProfileCheckData).profileChecklist);
    }
}
