import { Component, ElementRef, forwardRef, input, model, output, viewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { v4 as uuid } from 'uuid';
import { AixTooltipDirective } from '../../directives/tooltip/aix-tooltip';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';

export interface AixRadioGroupOptions {
    disabled?: boolean;

    [key: string]: any;
}

@Component({
    selector: 'aix-radio-group',
    styleUrls: ['./aix-radio-group.component.scss'],
    templateUrl: './aix-radio-group.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AixRadioGroupComponent)
        }
    ],
    standalone: true,
    imports: [AixDataTestingDirective, AixTooltipDirective]
})
export class AixRadioGroupComponent implements ControlValueAccessor {
    _propagateChanges: (value: string) => void = () => ({});
    _propagateTouches: () => void = () => ({});

    radio = viewChild<ElementRef<HTMLElement>>('radio');

    options = input<AixRadioGroupOptions[]>();
    labelField = input.required<string>();
    valueField = input.required<string>();
    descriptionField = input<string>('');
    tooltipField = input<string>('');
    config = input<any>();
    isRequired = input<boolean>(false);
    isDisabled = input<boolean>(false);
    isValid = input<boolean>(false);
    title = input<string>('');
    value = model<any>(null);
    placeholder = input<string>('');

    valueChanges = output<string>();
    setDirty = output();

    private isDirty = false;

    name = `radioGroup${uuid()}`;

    constructor() {}

    valueToSend(rawValue: string) {
        this.value.set(rawValue ?? null);
        this.valueChanges.emit(rawValue);
        return rawValue;
    }

    onUserInput(evt: Event | ClipboardEvent) {
        if (!this.isDirty) {
            this.setDirty.emit();
            this.isDirty = true;
        }
        const val = this.valueToSend((evt.target as HTMLInputElement).value);
        this._propagateChanges(val);
    }

    writeValue(value: any) {
        this.valueToSend(value);
        if (value === null && (<ElementRef>this.radio())?.nativeElement) {
            (<ElementRef>this.radio()).nativeElement.querySelector(
                `input[name=${this.name}]`
            ).checked = false;
        }
    }

    registerOnChange(fn: (value: string) => void) {
        this._propagateChanges = fn;
    }

    registerOnTouched(fn: () => void) {
        this._propagateTouches = fn;
    }
}
