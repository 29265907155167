import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchHttpError } from '../utils/catch-http-error';
import {
    LoadProductAction,
    LoadProductExploreAction,
    LoadProductExploreFailureAction,
    LoadProductExploreFiltersAction,
    LoadProductExploreFiltersFailureAction,
    LoadProductExploreFiltersSuccessAction,
    LoadProductExploreMaterialsAction,
    LoadProductExploreMaterialsFailureAction,
    LoadProductExploreMaterialsSuccessAction,
    LoadProductExploreSuccessAction,
    LoadProductFailureAction,
    LoadProductFamilyAction,
    LoadProductFamilyFailureAction,
    LoadProductFamilySuccessAction,
    LoadProductFiltersAction,
    LoadProductFiltersFailureAction,
    LoadProductFiltersSuccessAction,
    LoadProductsAction,
    LoadProductsExploreAction,
    LoadProductsExploreFailureAction,
    LoadProductsExploreSuccessAction,
    LoadProductsFailureAction,
    LoadProductsSuccessAction,
    LoadProductSuccessAction,
    ProductRecordActionTypes,
    SendProductExploreAction,
    SendProductExploreFailureAction,
    SendProductExploreSuccessAction
} from './actions';
import { ProductService } from './service';

@Injectable()
export class ProductEffects {
    private actions$ = inject(Actions);
    private service = inject(ProductService);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    loadProducts$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadProductsAction>(ProductRecordActionTypes.LOAD_PRODUCTS),
            switchMap(action =>
                this.service.getProducts(action.orderType, action.filters).pipe(
                    map(products => new LoadProductsSuccessAction(products)),
                    catchHttpError(error => of(new LoadProductsFailureAction({ error })))
                )
            )
        )
    );

    loadProductFamily$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadProductFamilyAction>(ProductRecordActionTypes.LOAD_PRODUCT_FAMILY),
            switchMap(action =>
                this.service.getProductFamily(action.fundId).pipe(
                    map(product => new LoadProductFamilySuccessAction(product)),
                    catchHttpError(error => of(new LoadProductFamilyFailureAction({ error })))
                )
            )
        )
    );

    loadProduct$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadProductAction>(ProductRecordActionTypes.LOAD_PRODUCT),
            switchMap(action =>
                this.service.getProduct(action.productId).pipe(
                    map(product => new LoadProductSuccessAction(product)),
                    catchHttpError(error => of(new LoadProductFailureAction({ error })))
                )
            )
        )
    );

    loadFiltersProduct$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadProductFiltersAction>(ProductRecordActionTypes.LOAD_PRODUCT_FILTERS),
            switchMap(action =>
                this.service.getProductsFilters(action.orderType).pipe(
                    map(product => new LoadProductFiltersSuccessAction(product)),
                    catchHttpError(error => of(new LoadProductFiltersFailureAction({ error })))
                )
            )
        )
    );

    loadProductsExplore$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadProductsExploreAction>(ProductRecordActionTypes.LOAD_PRODUCTS_EXPLORE),
            switchMap(action =>
                this.service.getProductsExplore(action.options).pipe(
                    map(products => new LoadProductsExploreSuccessAction(products)),
                    catchHttpError(error => of(new LoadProductsExploreFailureAction({ error })))
                )
            )
        )
    );

    loadFiltersExplore$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadProductExploreFiltersAction>(
                ProductRecordActionTypes.LOAD_PRODUCT_EXPLORE_FILTERS
            ),
            switchMap(action =>
                this.service.getProductExploreFilters().pipe(
                    map(product => new LoadProductExploreFiltersSuccessAction(product)),
                    catchHttpError(error =>
                        of(new LoadProductExploreFiltersFailureAction({ error }))
                    )
                )
            )
        )
    );

    loadProductExplore$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadProductExploreAction>(ProductRecordActionTypes.LOAD_PRODUCT_EXPLORE),
            switchMap(action =>
                this.service.getProductExplore(action.payload.fundSponsorId).pipe(
                    map(product => new LoadProductExploreSuccessAction(product)),
                    catchHttpError(error => of(new LoadProductExploreFailureAction({ error })))
                )
            )
        )
    );

    sendProductExplore$ = createEffect(() =>
        this.actions$.pipe(
            ofType<SendProductExploreAction>(ProductRecordActionTypes.SEND_PRODUCT_EXPLORE),
            switchMap(action =>
                this.service.sendProductExplore(action.payload).pipe(
                    map(product => new SendProductExploreSuccessAction(product)),
                    catchHttpError(error => of(new SendProductExploreFailureAction({ error })))
                )
            )
        )
    );

    loadProductExploreMaterials$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadProductExploreMaterialsAction>(
                ProductRecordActionTypes.LOAD_PRODUCT_EXPLORE_MATERIALS
            ),
            switchMap(action =>
                this.service
                    .getProductExploreMaterials(action.payload.fundSponsorId, action.payload.filter)
                    .pipe(
                        map(materials => new LoadProductExploreMaterialsSuccessAction(materials)),
                        catchHttpError(error =>
                            of(new LoadProductExploreMaterialsFailureAction({ error }))
                        )
                    )
            )
        )
    );
}
