import { ChangeDetectorRef, Component, input, OnDestroy, OnInit, inject } from '@angular/core';
import { DocumentViewerDownloadService } from '@trade-platform/ui-shared';
import { OrderFormCommentsService } from '../order-comments/order-form-comments.helper';
import { AixAbstractUploadComponent } from '../abstract-upload';
import {
    getFilteredFormIds,
    getUserUploadedFormFiles
} from '../process/overview/utils/order-utils';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    AixLoadingComponent,
    AixModalComponent,
    AixNotificationComponent,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../base.orders.store.facade';
import { RemoteDataModule } from 'ngx-remotedata';
import { AsyncPipe } from '@angular/common';
import { AixFormFilelistComponent } from './form-filelist/form-filelist';
import { AixUploadDocumentComponent } from '@advisor-ui/app-components';

@Component({
    selector: 'aix-form-upload',
    templateUrl: './form-upload.html',
    styleUrls: ['./form-upload.scss'],
    standalone: true,
    imports: [
        AixLoadingComponent,
        AixFormFilelistComponent,
        AixModalComponent,
        AixUploadDocumentComponent,
        AixNotificationComponent,
        AixButtonComponent,
        AixDataTestingDirective,
        AsyncPipe,
        RemoteDataModule
    ]
})
export class AixFormUploadComponent
    extends AixAbstractUploadComponent
    implements OnInit, OnDestroy
{
    environment: IEnvironment;
    storeFacade: BaseOrdersStoreFacade;
    docDownloader: DocumentViewerDownloadService;
    commentsService: OrderFormCommentsService;
    cd: ChangeDetectorRef;

    isReadOnly = input<boolean>(false);

    cancelButtonType = BUTTON_TYPE.link;
    continueButtonType = BUTTON_TYPE.primary;
    closeUploaderButtonType = BUTTON_TYPE.primary;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        const environment = inject<IEnvironment>(ENVIRONMENT);
        const storeFacade = inject<BaseOrdersStoreFacade>(ORDERS_STORE_FACADE);
        const docDownloader = inject(DocumentViewerDownloadService);
        const commentsService = inject(OrderFormCommentsService);
        const cd = inject(ChangeDetectorRef);

        super(environment, storeFacade, docDownloader, commentsService, cd);
        this.environment = environment;
        this.storeFacade = storeFacade;
        this.docDownloader = docDownloader;
        this.commentsService = commentsService;
        this.cd = cd;
    }

    isStartUploadDisabled(): boolean {
        return this.selectedFiles.length === 0 || this.hasExtraFormUploads();
    }

    hasExtraFormUploads(): boolean {
        return this.getExtraUploadsCount() > 0;
    }

    getExtraUploadsCount(): number {
        const order = this.storeFacade.order;
        if (!order) {
            return 0;
        }
        return (
            this.selectedFiles.length -
            (getFilteredFormIds(order, this.acceptedFormIds()).length -
                getUserUploadedFormFiles(order, this.acceptedFormIds()).length)
        );
    }
}
