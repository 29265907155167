import {
    ChangeDetectionStrategy,
    Component,
    output,
    ViewEncapsulation,
    inject
} from '@angular/core';
import { Router } from '@angular/router';
import { labels } from './order-offline.constants';
import { getFirmTypeLabel } from '../utils/order-utils';
import { DocumentViewerDownloadService } from '@trade-platform/ui-shared';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../../../base.orders.store.facade';

@Component({
    selector: 'aix-order-offline',
    templateUrl: './order-offline.html',
    styleUrls: ['./order-offline.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AixButtonComponent, AixDataTestingDirective]
})
export class AixOrderOfflineComponent {
    storeFacade = inject<BaseOrdersStoreFacade>(ORDERS_STORE_FACADE);
    private router = inject(Router);
    private docDownloader = inject(DocumentViewerDownloadService);

    showPdf = output<void>();
    onClickDownload = output<void>();

    viewPDFButtonType = BUTTON_TYPE.primary;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    getSignaturesLabel() {
        const order = this.storeFacade.order;
        return labels.collectSignatures(getFirmTypeLabel(order));
    }

    onClickViewPdf() {
        this.showPdf.emit();
    }

    onClickDownloadLink() {
        const order = this.storeFacade.order;
        this.docDownloader.getDocument({
            href: `${this.storeFacade.apiUrl}/${order.id}/download/`,
            name: order.title
        });
    }

    onClickGoToOrders() {
        this.router.navigate(this.storeFacade.routes.status());
    }
}
