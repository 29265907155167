import { Injectable, inject } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import * as Sentry from '@sentry/browser';
import { ApiFormError } from '@trade-platform/ui-utils';
import { get as _get } from 'lodash-es';
import { filter, tap } from 'rxjs/operators';
import { AixAction } from '../aix-action';
import { ErrorWrapper } from '../models/common';
import { LatestActionsService } from './latest-actions.service';

const isFailure = (action: AixAction) => action.type.toLowerCase().substr(-7) === 'failure';
const isApiFormError = (action: AixAction) => action.payload.error instanceof ApiFormError;
const isHttpFailure = (action: AixAction) => {
    const debugText = _get(action, 'payload.error.debugText');
    try {
        return debugText && debugText.toLowerCase().includes('http failure response');
    } catch (error) {
        return false;
    }
};
const isHttpError = (action: AixAction) => {
    const status = parseInt(_get(action, 'payload.status'), 10);
    return !isNaN(status) && status > 400;
};

@Injectable()
export class SentryCatchallErrorsEffect {
    private actions$ = inject(Actions);
    latestActionsService = inject(LatestActionsService);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    catchAllErrors$ = createEffect(
        () =>
            this.actions$.pipe(
                filter(isFailure),
                filter(err => !isApiFormError(err)),
                filter(err => !isHttpFailure(err)),
                filter(err => !isHttpError(err)),
                tap((action: any) => {
                    this.latestActionsService.setCurrentSentryContext();

                    // Remove non-useful information from ErrorWrapper payload before creating error;
                    let wrapperError = null;
                    if (
                        action.payload &&
                        action.payload.error &&
                        action.payload.error instanceof ErrorWrapper
                    ) {
                        wrapperError = {
                            payload: action.payload.error.debugText,
                            type: action.type
                        };
                    }
                    const error = new Error(
                        `Failure Action dispatched: ${JSON.stringify(
                            wrapperError ? wrapperError : action
                        )}`
                    );
                    Sentry.captureException(error);
                })
            ),
        { dispatch: false }
    );

    saveLatestActions$ = createEffect(
        () =>
            this.actions$.pipe(
                tap(action => {
                    this.latestActionsService.addAction(action);
                })
            ),
        { dispatch: false }
    );
}
