import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';

export interface AdminPushUpdatedFormToOrderBody {
    orderId: string;
    formId: string;
}

@Injectable()
export class OrderAdminService {
    private http = inject(HttpClient);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    convertOrderNigoToNeedsClarification(orderId: string): Observable<any> {
        return this.http.post<null>(
            `${this.environment.adminApi}orders/${orderId}/nigoToNeedsClarification`,
            {}
        );
    }

    convertOrderToWetsign(orderId: string): Observable<any> {
        return this.http.post<null>(
            `${this.environment.adminApi}orders/${orderId}/convertToWetsign`,
            {}
        );
    }

    adminMarkOrderComplete(orderId: string): Observable<any> {
        return this.http.post<null>(
            `${this.environment.adminApi}orders/${orderId}/markComplete`,
            {}
        );
    }

    adminResubmitOrder(orderId: string): Observable<any> {
        return this.http.post<null>(`${this.environment.adminApi}orders/${orderId}/resubmit`, {});
    }

    adminPushUpdatedFormToOrder(orderId: string, formId: string): Observable<any> {
        return this.http.patch<null>(
            `${this.environment.adminApi}orders/${orderId}/forms/${formId}`,
            {}
        );
    }

    adminMoveOrderBackInWorkflow(orderId: string): Observable<any> {
        return this.http.post<null>(
            `${this.environment.adminApi}orders/${orderId}/moveBackInWorkflow`,
            {}
        );
    }

    adminMoveOrderForwardInWorkflow(orderId: string): Observable<any> {
        return this.http.post<null>(
            `${this.environment.adminApi}orders/${orderId}/moveForwardInWorkflow`,
            {}
        );
    }
}
