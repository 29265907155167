import { Outage } from '@trade-platform/ui-shared';

/**
 * Outage decoding constants
 */
const OUTAGE_GLOBAL_COMPONENT = 'global';
const OUTAGE_EXCLUSION_PREFIX = 'not:';

export const getOutagesForTenant = (
    outages: Outage[],
    filterForCritical = false,
    showOnlyErrors = false
) => {
    const tenantOutages = [];
    if (outages?.length > 0) {
        // Check if the existing outages are relevant to this tenant
        const tenantCode = window.location.host.split('.')[0];
        for (const outage of outages) {
            if (showOnlyErrors) {
                outage.messages = outage.messages.filter(m => m.status !== 'ok');
            }
            const isGlobal = outage.tenants.some(tenant => tenant === OUTAGE_GLOBAL_COMPONENT);
            const isTenantIncluded = outage.tenants.some(tenant => tenant === tenantCode);
            const isTenantExcluded = outage.tenants.some(
                tenant => tenant === `${OUTAGE_EXCLUSION_PREFIX}${tenantCode}`
            );
            if (
                (isTenantIncluded || (isGlobal && !isTenantExcluded)) &&
                (!filterForCritical || outage.status === 'error')
            ) {
                tenantOutages.push(outage);
            }
        }
    }
    return tenantOutages;
};
