import {
    Component,
    forwardRef,
    input,
    OnChanges,
    OnDestroy,
    OnInit,
    output,
    SimpleChanges,
    inject
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import {
    ControlValueAccessor,
    FormsModule,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { HoldingOption } from '@trade-platform/ui-shared';
import {
    AixDataTestingDirective,
    AixDropdownComponent,
    AixNextPendingFieldDirective
} from '@trade-platform/ui-components';

@Component({
    selector: 'aix-custodial-arrangement',
    templateUrl: './custodial-arrangement.html',
    styleUrls: ['./custodial-arrangement.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AixCustodialArrangementComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: AixCustodialArrangementComponent
        }
    ],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        AixDataTestingDirective,
        AixDropdownComponent,
        AixNextPendingFieldDirective
    ]
})
export class AixCustodialArrangementComponent
    implements ControlValueAccessor, OnInit, OnChanges, OnDestroy
{
    private _fb = inject(UntypedFormBuilder);

    holdingOptions = input<HoldingOption[]>([]);
    labelType = input<'purchase' | 'maintenance' | 'recordkeeper'>('purchase');
    isRequired = input<boolean>(true);

    onCancel = output<null>();

    holdingOption: number | string | null;
    form: UntypedFormGroup;

    subscriptions: Subscription[] = [];
    itemMessage: string | null;

    private _onChange: (value: any | null | undefined) => void;
    private _destroy$: Subject<void> = new Subject<void>();

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        this._createFormGroup();
        this._setupObservables();
    }

    ngOnInit() {
        this.patchForm();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.holdingOptions()?.length === 0) {
            if (this.labelType() === 'recordkeeper') {
                this.itemMessage = `No recordkeepers available, please contact <a class="br-link" href="mailto:support@aixplatform.com">support@aixplatform.com</a>.`;
            } else {
                this.itemMessage = `No custodial arrangements available, please contact <a class="br-link" href="mailto:support@aixplatform.com">support@aixplatform.com</a>.`;
            }
        } else {
            this.itemMessage = null;
        }

        if (changes && changes.isRequired) {
            this.form.updateValueAndValidity();
        }

        this.patchForm();
    }

    patchForm() {
        if (this.holdingOptions()?.length === 1) {
            this.holdingOption = this.holdingOptions()[0].id;
            this.form.patchValue({
                holdingOption: [this.holdingOptions()[0]]
            });
        } else {
            this.holdingOption = null;
            this.form.patchValue({
                holdingOption: null
            });
        }

        setTimeout(() => {
            const control = this.form.get('holdingOption');
            control?.updateValueAndValidity();

            this.validate(control as UntypedFormControl);

            if (this.holdingOptions()?.length === 1) {
                control?.disable();
            } else {
                control?.enable();
            }
        }, 100);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());

        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }

    validate(ctrl: UntypedFormControl) {
        return !this.isRequired() ||
            ((this.form.get('holdingOption')?.valid || this.form.get('holdingOption')?.disabled) &&
                this.form.get('holdingOption')?.value.length > 0)
            ? null
            : { invalid: true };
    }

    writeValue(value: any | null | undefined): void {
        if (!value) {
            return;
        }
    }

    registerOnChange(fn: (value: any | null | undefined) => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        // TODO: implement this method
        // throw new Error('registerOnTouched not implemented');
    }

    clearField(path: string) {
        this.form.get(path)?.setValue('');
    }

    private _createFormGroup() {
        this.form = this._fb.group({
            holdingOption: this._fb.control(null, [Validators.required])
        });
    }

    private _setupObservables() {
        this.form.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(value => {
            if (this._onChange) {
                this._onChange(value);
            }
        });
    }
}
