import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { extractStreamValue } from '@trade-platform/ui-utils';
import {
    DataWhenAction,
    FieldConfig,
    FieldRelation,
    FieldRelationAction,
    FieldWhenAction,
    FieldWhenOperator,
    isDataWhenAction,
    isFieldGroupLightConfig,
    isFieldWhenAction,
    RadiogroupFieldConfig,
    RelationConnector,
    RelationWhenAction
} from '@trade-platform/form-fields';
import { getStoreObservableByPath } from '../dynamic-form.utils';
import { DynamicFormState } from './model';
import {
    FormUID,
    getDataByPath,
    getDataPathByFieldConfig,
    getFormControlStateByRefId
} from './utils';

@Injectable()
export class DynamicFormRelations {
    private store = inject<Store<Record<string, DynamicFormState>>>(Store);

    formUID: FormUID;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    setFormUID(formUID: FormUID) {
        this.formUID = formUID;
    }

    fieldToRelations(field: FieldConfig, action: FieldRelationAction): boolean {
        if (!isFieldGroupLightConfig(field) && field.relations && field.relations.length > 0) {
            const actionRelation = field.relations.filter(rel => rel.action === action);
            if (actionRelation.length > 0) {
                const relation = actionRelation[0];
                switch (relation.action) {
                    case 'SHOW':
                        return this.fieldToRelations_SHOW(relation);
                    case 'DISABLE':
                        return this.fieldToRelations_DISABLE(
                            relation as FieldRelation<FieldWhenAction>
                        );
                    case 'REQUIRE':
                        return this.fieldToRelations_REQUIRE(
                            relation as FieldRelation<FieldWhenAction>
                        );
                    case 'SET_DATA':
                        return this.fieldToRelations_SET_DATA(
                            relation as FieldRelation<FieldWhenAction>
                        );
                    case 'SET_MAP':
                        return this.fieldToRelations_SET_MAP(
                            relation as FieldRelation<FieldWhenAction>
                        );
                }
            }
        }
        switch (action) {
            case 'SHOW':
                return true;
            case 'DISABLE':
            case 'REQUIRE':
                return false;
            default:
                throw new Error(`Unknown FieldRelationAction "${action}"`);
        }
    }

    fieldToConditionals(
        field: FieldConfig,
        action: FieldRelationAction
    ): { newValue?: any; conditionResult: boolean } {
        if (!isFieldGroupLightConfig(field) && field.relations && field.relations.length > 0) {
            const actionCondition = field.relations.filter(rel => rel.action === action);
            for (const condition of actionCondition) {
                switch (condition.action) {
                    case 'SET_LABEL':
                    case 'SET_PLACEHOLDER': {
                        const conditionResult = this.fieldToConditionals_SET_CONFIG_PROPERTY(
                            condition as FieldRelation<FieldWhenAction>
                        );
                        if (conditionResult) {
                            return {
                                newValue: condition.newValue,
                                conditionResult
                            };
                        }
                    }
                }
            }
        }
        switch (action) {
            case 'SET_LABEL':
            case 'SET_PLACEHOLDER':
                return { conditionResult: false };
            default:
                throw new Error(`Unknown FieldConditionalAction "${action}"`);
        }
    }

    private getConnectiveFunction(
        connective: RelationConnector
    ): (v1: boolean, v2: boolean) => boolean {
        switch (connective) {
            case 'OR':
                return (v1, v2) => v1 || v2;
            case 'AND':
            case undefined:
                return (v1, v2) => v1 && v2;
            default:
                throw new Error(`Unknown dynamic form connective "${connective}"`);
        }
    }

    /**
     * The Identity Value (aka Neutral Element) of a boolean is another boolean that does not modify the value of the former.
     * https://en.wikipedia.org/wiki/Identity_element
     * The OR identity is false because:
     * - false || true == true
     * - false || false == false
     * The AND identity is true because:
     * - true && true == true
     * - true && false == false
     */
    private getConnectiveNeutralElement(connective: RelationConnector): boolean {
        switch (connective) {
            case 'OR':
                return false;
            case 'AND':
            case undefined:
                return true;
            default:
                throw new Error(`Unknown dynamic form connective "${connective}"`);
        }
    }

    private _greaterThanComparisonFunction = (v1: any, v2: any) =>
        v1 > v2 && !isNaN(parseInt(v1, 10));
    private _lowerThanComparisonFunction = (v1: any, v2: any) =>
        v1 < v2 && !isNaN(parseInt(v1, 10));
    private _truthyComparisonFunction = (v1: any, _: any) => !!v1;
    private _falsyComparisonFunction = (v1: any, _: any) => (Array.isArray(v1) ? !v1.length : !v1);
    private _equalToComparisonFunction = (v1: any, v2: any) => v1 === v2;
    private _propertyTruthyComparisonFunction = (v1: any, v2: any) => (v1 ? !!v1[v2] : false);
    private _propertyFalsyComparisonFunction = (v1: any, v2: any) => (v1 ? !v1[v2] : true);
    private __differentComparisonFunction = (v1: any, v2: any) => v1 !== v2;

    getComparisonFunction(operator: FieldWhenOperator) {
        switch (operator) {
            case 'GREATER_THAN':
                return this._greaterThanComparisonFunction;
            case 'LOWER_THAN':
                return this._lowerThanComparisonFunction;
            case 'TRUTHY':
                return this._truthyComparisonFunction;
            case 'FALSY':
                return this._falsyComparisonFunction;
            case 'DIFFERENT':
                return this.__differentComparisonFunction;
            case 'PROPERTY_IS_TRUTHY':
                return this._propertyTruthyComparisonFunction;
            case 'PROPERTY_IS_FALSY':
                return this._propertyFalsyComparisonFunction;
            case 'EQUAL_TO':
            case undefined:
                return this._equalToComparisonFunction;
            default:
                throw new Error(`Unknown dynamic form comparison "${operator}"`);
        }
    }

    // radio group relations have to match, no only with the control value, but also the elements in the `otherValues` array and `'*'`
    private _getRadioGroupOptionFromOtherOptions(
        relatedConfig: RadiogroupFieldConfig,
        value: string
    ) {
        const valueField = relatedConfig.valueField as string;

        const oneOf = relatedConfig.oneOf as any[];

        const oneOfItemFound = oneOf.find(oneOfItem => {
            return (
                oneOfItem[valueField] === value ||
                (oneOfItem.otherValues &&
                    oneOfItem.otherValues.find((anyValue: any) => anyValue === value))
            );
        });

        if (oneOfItemFound !== undefined) {
            return oneOfItemFound[valueField];
        }

        const wildcardItemFound = oneOf.find(oneOfItem => {
            return (
                oneOfItem[valueField] === '*' ||
                (oneOfItem.otherValues &&
                    oneOfItem.otherValues.find((anyValue: any) => anyValue === '*'))
            );
        });

        if (wildcardItemFound !== undefined) {
            return wildcardItemFound[valueField];
        }

        return null;
    }

    private fieldToRelations_SHOW(relation: FieldRelation | RelationWhenAction): boolean {
        const connective = this.getConnectiveFunction(relation.connective as RelationConnector);
        const connectiveIdentity = this.getConnectiveNeutralElement(
            relation.connective as RelationConnector
        );

        try {
            return relation.when.reduce(
                (
                    acc: boolean,
                    rel: RelationWhenAction | FieldWhenAction | DataWhenAction,
                    index
                ) => {
                    // Performance optimization: shortcircuit reduce() to avoid unnecessary calculations
                    if (index > 0) {
                        if (relation.connective === 'AND' && acc === false) {
                            return false;
                        }
                        if (relation.connective === 'OR' && acc === true) {
                            return true;
                        }
                    }

                    if (isFieldWhenAction(rel)) {
                        const comparison = this.getComparisonFunction(
                            rel.operator as FieldWhenOperator
                        );

                        const relatedControlState = getFormControlStateByRefId(
                            this.store,
                            this.formUID,
                            rel.refId
                        );
                        if (relatedControlState) {
                            const relatedFieldConfigRelations = this.fieldToRelations(
                                relatedControlState.fieldConfig as FieldConfig,
                                'SHOW'
                            );
                            let value = getDataByPath<any>(
                                this.store,
                                this.formUID,
                                getDataPathByFieldConfig(relatedControlState.fieldConfig)
                            );
                            const relatedConfig = relatedControlState.fieldConfig as FieldConfig;
                            if (relatedConfig.type === 'radioGroup') {
                                value = this._getRadioGroupOptionFromOtherOptions(
                                    relatedConfig,
                                    value
                                );
                            }
                            const comp =
                                comparison(value, rel.value) && relatedFieldConfigRelations;
                            return connective(acc, Boolean(comp));
                        } else {
                            return connective(acc, false);
                        }
                    } else if (isDataWhenAction(rel)) {
                        const comparison = this.getComparisonFunction(
                            rel.operator as FieldWhenOperator
                        );
                        let value = getDataByPath(this.store, this.formUID, rel.dataPath);
                        // When the value is not in the form data store, we fall back to the global app store
                        if (value === undefined) {
                            value = extractStreamValue(
                                getStoreObservableByPath(rel.dataPath, this.store)
                            );
                        }
                        const comp = comparison(value, rel.value);
                        return connective(acc, Boolean(comp));
                    } else {
                        return connective(acc, this.fieldToRelations_SHOW(rel));
                    }
                },
                connectiveIdentity
            );
        } catch (e) {
            return false;
        }
    }

    fieldToRelations_DISABLE(relation: FieldRelation | RelationWhenAction): boolean {
        const connective = this.getConnectiveFunction(relation.connective as RelationConnector);
        const connectiveIdentity = this.getConnectiveNeutralElement(
            relation.connective as RelationConnector
        );

        try {
            return relation.when.reduce(
                (acc: boolean, rel: RelationWhenAction | FieldWhenAction | DataWhenAction) => {
                    if (isFieldWhenAction(rel)) {
                        const relatedControlState = getFormControlStateByRefId(
                            this.store,
                            this.formUID,
                            rel.refId
                        );
                        const comparison = this.getComparisonFunction(
                            rel.operator as FieldWhenOperator
                        );
                        let value = getDataByPath<any>(
                            this.store,
                            this.formUID,
                            getDataPathByFieldConfig(relatedControlState.fieldConfig)
                        );
                        const relatedConfig = relatedControlState.fieldConfig as FieldConfig;
                        if (relatedConfig.type === 'radioGroup') {
                            value = this._getRadioGroupOptionFromOtherOptions(relatedConfig, value);
                        }
                        const comp = comparison(value, rel.value);
                        return connective(acc, comp);
                    } else if (isDataWhenAction(rel)) {
                        const comparison = this.getComparisonFunction(
                            rel.operator as FieldWhenOperator
                        );
                        let value = getDataByPath(this.store, this.formUID, rel.dataPath);
                        // When the value is not in the form data store, we fall back to the global app store
                        if (value === undefined) {
                            value = extractStreamValue(
                                getStoreObservableByPath(rel.dataPath, this.store)
                            );
                        }
                        const comp = comparison(value, rel.value);
                        return connective(acc, Boolean(comp));
                    } else {
                        return connective(acc, this.fieldToRelations_DISABLE(rel));
                    }
                },
                connectiveIdentity
            );
        } catch (e) {
            return false;
        }
    }

    fieldToRelations_REQUIRE(relation: FieldRelation | RelationWhenAction): boolean {
        const connective = this.getConnectiveFunction(relation.connective as RelationConnector);
        const connectiveIdentity = this.getConnectiveNeutralElement(
            relation.connective as RelationConnector
        );

        try {
            return relation.when.reduce(
                (acc: boolean, rel: RelationWhenAction | FieldWhenAction | DataWhenAction) => {
                    if (isFieldWhenAction(rel)) {
                        const relatedControlState = getFormControlStateByRefId(
                            this.store,
                            this.formUID,
                            rel.refId
                        );
                        const comparison = this.getComparisonFunction(
                            rel.operator as FieldWhenOperator
                        );
                        let value = getDataByPath<any>(
                            this.store,
                            this.formUID,
                            getDataPathByFieldConfig(relatedControlState.fieldConfig)
                        );
                        const relatedConfig = relatedControlState.fieldConfig as FieldConfig;
                        if (relatedConfig.type === 'radioGroup') {
                            value = this._getRadioGroupOptionFromOtherOptions(relatedConfig, value);
                        }
                        const comp = comparison(value, rel.value);
                        return connective(acc, comp);
                    } else if (isDataWhenAction(rel)) {
                        const comparison = this.getComparisonFunction(
                            rel.operator as FieldWhenOperator
                        );
                        let value = getDataByPath(this.store, this.formUID, rel.dataPath);
                        // When the value is not in the form data store, we fall back to the global app store
                        if (value === undefined) {
                            value = extractStreamValue(
                                getStoreObservableByPath(rel.dataPath, this.store)
                            );
                        }
                        const comp = comparison(value, rel.value);
                        return connective(acc, Boolean(comp));
                    } else {
                        return connective(acc, this.fieldToRelations_REQUIRE(rel));
                    }
                },
                connectiveIdentity
            );
        } catch (e) {
            return false;
        }
    }

    fieldToRelations_SET_DATA(relation: FieldRelation | RelationWhenAction): boolean {
        const connective = this.getConnectiveFunction(relation.connective as RelationConnector);
        const connectiveIdentity = this.getConnectiveNeutralElement(
            relation.connective as RelationConnector
        );

        try {
            return relation.when.reduce(
                (acc: boolean, rel: RelationWhenAction | FieldWhenAction | DataWhenAction) => {
                    if (isFieldWhenAction(rel)) {
                        const relatedControlState = getFormControlStateByRefId(
                            this.store,
                            this.formUID,
                            rel.refId
                        );
                        const comparison = this.getComparisonFunction(
                            rel.operator as FieldWhenOperator
                        );
                        let value = getDataByPath<any>(
                            this.store,
                            this.formUID,
                            getDataPathByFieldConfig(relatedControlState.fieldConfig)
                        );
                        const relatedConfig = relatedControlState.fieldConfig as FieldConfig;
                        if (relatedConfig.type === 'radioGroup') {
                            value = this._getRadioGroupOptionFromOtherOptions(relatedConfig, value);
                        }
                        const comp = comparison(value, rel.value);
                        return connective(acc, comp);
                    } else if (isDataWhenAction(rel)) {
                        const comparison = this.getComparisonFunction(
                            rel.operator as FieldWhenOperator
                        );
                        let value = getDataByPath(this.store, this.formUID, rel.dataPath);
                        // When the value is not in the form data store, we fall back to the global app store
                        if (value === undefined) {
                            value = extractStreamValue(
                                getStoreObservableByPath(rel.dataPath, this.store)
                            );
                        }
                        const comp = comparison(value, rel.value);
                        return connective(acc, Boolean(comp));
                    } else {
                        return connective(acc, this.fieldToRelations_SET_DATA(rel));
                    }
                },
                connectiveIdentity
            );
        } catch (e) {
            return false;
        }
    }

    fieldToRelations_SET_MAP(
        relation:
            | FieldRelation<RelationWhenAction | FieldWhenAction | DataWhenAction>
            | RelationWhenAction
    ): boolean {
        const connective = this.getConnectiveFunction(relation.connective as RelationConnector);
        const connectiveIdentity = this.getConnectiveNeutralElement(
            relation.connective as RelationConnector
        );

        return relation.when.reduce(
            (acc: boolean, rel: RelationWhenAction | FieldWhenAction | DataWhenAction) => {
                if (isFieldWhenAction(rel)) {
                    const comparison = this.getComparisonFunction(
                        rel.operator as FieldWhenOperator
                    );
                    const relatedControlState = getFormControlStateByRefId(
                        this.store,
                        this.formUID,
                        rel.refId
                    );
                    if (relatedControlState) {
                        const relatedFieldConfigRelations = this.fieldToRelations(
                            relatedControlState.fieldConfig as FieldConfig,
                            'SHOW'
                        );
                        let value = getDataByPath<any>(
                            this.store,
                            this.formUID,
                            getDataPathByFieldConfig(relatedControlState.fieldConfig)
                        );
                        const relatedConfig = relatedControlState.fieldConfig as FieldConfig;
                        if (relatedConfig.type === 'radioGroup') {
                            value = this._getRadioGroupOptionFromOtherOptions(relatedConfig, value);
                        }
                        const comp = comparison(value, rel.value) && relatedFieldConfigRelations;
                        return connective(acc, Boolean(comp));
                    } else {
                        return connective(acc, false);
                    }
                } else if (isDataWhenAction(rel)) {
                    const comparison = this.getComparisonFunction(
                        rel.operator as FieldWhenOperator
                    );
                    let value = getDataByPath(this.store, this.formUID, rel.dataPath);
                    // When the value is not in the form data store, we fall back to the global app store
                    if (value === undefined || value === null) {
                        value = extractStreamValue(
                            getStoreObservableByPath(rel.dataPath, this.store)
                        );
                    }
                    const comp = comparison(value, rel.value);
                    return connective(acc, Boolean(comp));
                } else {
                    return connective(acc, this.fieldToRelations_SET_MAP(rel));
                }
            },
            connectiveIdentity
        );
    }

    fieldToConditionals_SET_CONFIG_PROPERTY(
        condition: FieldRelation | RelationWhenAction
    ): boolean {
        const connective = this.getConnectiveFunction(condition.connective as RelationConnector);
        const connectiveIdentity = this.getConnectiveNeutralElement(
            condition.connective as RelationConnector
        );

        try {
            return condition.when.reduce(
                (acc: boolean, rel: RelationWhenAction | FieldWhenAction | DataWhenAction) => {
                    if (isFieldWhenAction(rel)) {
                        const relatedControlState = getFormControlStateByRefId(
                            this.store,
                            this.formUID,
                            rel.refId
                        );
                        const comparison = this.getComparisonFunction(
                            rel.operator as FieldWhenOperator
                        );
                        let value = getDataByPath<any>(
                            this.store,
                            this.formUID,
                            getDataPathByFieldConfig(relatedControlState.fieldConfig)
                        );
                        const relatedConfig = relatedControlState.fieldConfig as FieldConfig;
                        if (relatedConfig.type === 'radioGroup') {
                            value = this._getRadioGroupOptionFromOtherOptions(relatedConfig, value);
                        }
                        const comp = comparison(value, rel.value);
                        return connective(acc, comp);
                    } else if (isDataWhenAction(rel)) {
                        const comparison = this.getComparisonFunction(
                            rel.operator as FieldWhenOperator
                        );
                        let value = getDataByPath(this.store, this.formUID, rel.dataPath);
                        // When the value is not in the form data store, we fall back to the global app store
                        if (value === undefined) {
                            value = extractStreamValue(
                                getStoreObservableByPath(rel.dataPath, this.store)
                            );
                        }
                        const comp = comparison(value, rel.value);
                        return connective(acc, Boolean(comp));
                    } else {
                        return connective(acc, this.fieldToConditionals_SET_CONFIG_PROPERTY(rel));
                    }
                },
                connectiveIdentity
            );
        } catch (e) {
            return false;
        }
    }
}
