import {
    ComponentRef,
    Directive,
    effect,
    inject,
    input,
    Type,
    ViewContainerRef
} from '@angular/core';
import { DefaultDropdownCellrendererComponent } from './default-dropdown.cellrenderer';
import { WrappedOption } from './dropdown';

export interface DropdownCellrenderer {
    option: WrappedOption;
    setOption(option: WrappedOption): void;
}

@Directive({
    selector: '[aixDropdownCellrenderer]',
    standalone: true
})
export class DropdownCellrendererDirective {
    private container = inject(ViewContainerRef);

    option = input<WrappedOption>();
    cellRenderer = input<Type<DropdownCellrenderer>>();

    component: ComponentRef<DropdownCellrenderer>;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        const cellRenderer = this.cellRenderer() || DefaultDropdownCellrendererComponent;
        this.component = this.container.createComponent(cellRenderer);

        effect(() => {
            this.component.instance.setOption(<WrappedOption>this.option());
        });
    }
}
