import { Directive, ElementRef, input, OnDestroy, OnInit, inject } from '@angular/core';
import { ControlFieldConfig } from '@trade-platform/form-fields';
import { DynamicPendingFieldsManagerService } from './dynamic-pending-fields-manager.service';

export interface DynamicNextPendingField {
    config: ControlFieldConfig;
    margin?: number;
}

@Directive({
    selector: '[aixDynamicNextPendingField]',
    standalone: true
})
export class AixDynamicNextPendingFieldDirective implements OnInit, OnDestroy {
    private elem = inject(ElementRef);
    pendingFieldsManager = inject(DynamicPendingFieldsManagerService);

    field = input<DynamicNextPendingField>(
        {
            config: {
                type: 'group',
                group: '',
                name: '',
                validation: []
            }
        },
        { alias: 'aixDynamicNextPendingField' }
    );

    object = Object;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    ngOnInit() {
        setTimeout(
            () =>
                this.pendingFieldsManager.addField(this.field().config.refId as string, {
                    config: this.field().config,
                    elem: this.elem.nativeElement,
                    margin: this.field().margin ?? 0
                }),
            0
        );
    }

    ngOnDestroy() {
        setTimeout(
            () => this.pendingFieldsManager.removeField(this.field().config.refId as string),
            0
        );
    }
}
