import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    input,
    OnDestroy,
    OnInit,
    output,
    inject
} from '@angular/core';
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';
import {
    AixErrorBoxComponent,
    ErrorWrapper,
    eSignEnvelopeRecipient
} from '@trade-platform/ui-shared';
import { Observable, Subscription } from 'rxjs';
import { ProfileStoreFacade } from '@advisor-ui/app-services';
import { recipientType } from '@trade-platform/lib-enums';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    AixHeaderSectionComponent,
    AixInputComponent,
    AixTextareaComponent,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';

import { AixSignerComponent } from '../signer/signer.component';
import { AixSignerFilterPipe } from '../pipes/signers.pipe';
import { RemoteData } from 'ngx-remotedata';
import { BaseOrdersStoreFacade, DocuSignForm, ORDERS_STORE_FACADE } from '@advisor-ui/orders';

@Component({
    selector: 'aix-docu-sign',
    templateUrl: './recipients.html',
    styleUrls: ['./recipients.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AixHeaderSectionComponent,
        AixDataTestingDirective,
        AixButtonComponent,
        AixSignerComponent,
        FormsModule,
        ReactiveFormsModule,
        AixInputComponent,
        AixTextareaComponent,
        AixErrorBoxComponent,
        AixSignerFilterPipe
    ]
})
export class AixOrderProcessDocuSignRecipientsComponent implements OnInit, OnDestroy {
    private environment = inject<IEnvironment>(ENVIRONMENT);
    store = inject<BaseOrdersStoreFacade>(ORDERS_STORE_FACADE);
    profileStore = inject(ProfileStoreFacade);
    private cd = inject(ChangeDetectorRef);

    signers = input<eSignEnvelopeRecipient[]>();
    states = input<Observable<RemoteData<any, string | ErrorWrapper>>[]>();

    cancelESign = output();
    submitForm = output<DocuSignForm>();

    docusignEmail: string;
    emailBody: string;

    sendForm: UntypedFormGroup = new UntypedFormGroup({
        emailFrom: new UntypedFormControl({ value: '', disabled: true }),
        emailSubject: new UntypedFormControl(null, [
            Validators.required,
            Validators.maxLength(100)
        ]),
        emailBody: new UntypedFormControl(null, [Validators.required])
    });

    recipientType = recipientType;

    subscriptions: Subscription[] = [];

    cancelEsignButtonType = BUTTON_TYPE.link;
    sendEsignButtonType = BUTTON_TYPE.primary;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        const environment = this.environment;

        this.docusignEmail = environment.contactDetails.docusignEmail;
    }

    ngOnInit() {
        this.sendForm.valueChanges.subscribe(value => {
            this.cd.detectChanges();
        });

        const order = this.store.order;

        let advisor;
        let advisorSignature;
        if (order.advisor && order.advisor.id && order.advisor.firstName) {
            advisor = `${order.advisor.firstName} ${order.advisor.lastName}`;
            advisorSignature = `${advisor}\n${order.advisor.email}`;
        }

        const subject = advisor
            ? `${advisor} Sent Documents for Review`
            : `Documents for ${order.fund.name}`;
        const body = `Please review the investment documents for ${order.fund.name}.`;
        const signature = advisorSignature ? `\n\nThank you,\n${advisorSignature}` : '';

        this.emailBody = body + signature;
        (this.sendForm.get('emailSubject') as UntypedFormControl).setValue(subject);
        (this.sendForm.get('emailBody') as UntypedFormControl).setValue(this.emailBody);
        this.sendForm
            .get('emailFrom')
            ?.setValue(`AIX Platform via DocuSign <${this.docusignEmail}>`);

        this.sendForm.get('emailSubject')?.updateValueAndValidity();
        this.sendForm.updateValueAndValidity();
    }

    onSubmit($event: MouseEvent) {
        $event.preventDefault();
        this.sendForm.updateValueAndValidity();
        if (this.sendForm.valid) {
            this.submitForm.emit(this.sendForm.value);
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
