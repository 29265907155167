import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform, inject } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Auth } from '@trade-platform/ui-shared';
import { getFromStorage } from '@trade-platform/ui-utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'aixSecure',
    standalone: true
})
export class SecurePipe implements PipeTransform {
    private http = inject(HttpClient);
    private sanitizer = inject(DomSanitizer);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    transform(url: string): Observable<SafeUrl> {
        const auth: Auth | undefined = getFromStorage('auth');
        const authToken = `Bearer ${auth?.accessToken}`;
        return this.http
            .get(url, {
                responseType: 'blob',
                headers: {
                    Authorization: authToken
                }
            })
            .pipe(
                map(blob => {
                    // We're bypassing the Angular sanitizer here because marks the URLs as unsafe and breaks rendering in the AIX image viewwer.
                    // This is a known code location that fails a static security scan, but is accepted behavior. The URL that is skipping sanitization is made on the fly by the browser and not subject to user input, meaning it's not a vector for an XSS/injection attack.
                    // TRJ - 08/12/22
                    return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
                })
            );
    }
}
