import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchHttpError } from '../utils/catch-http-error';
import {
    InvestorActionTypes,
    LoadInvestorByTaxIdAction,
    LoadInvestorByTaxIdFailureAction,
    LoadInvestorByTaxIdSuccessAction
} from './actions';
import { InvestorService } from './service';

@Injectable()
export class InvestorEffects {
    private actions$ = inject(Actions);
    private service = inject(InvestorService);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    loadInvestorByTaxId$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadInvestorByTaxIdAction>(InvestorActionTypes.LOAD_INVESTOR_BY_TAX_ID),
            switchMap(action =>
                this.service
                    .getInvestorByTaxId(action.payload.taxId, action.payload.personTypeFilter)
                    .pipe(
                        map(
                            investor =>
                                new LoadInvestorByTaxIdSuccessAction({
                                    investor: investor,
                                    reducerSuffix: action.payload.reducerSuffix
                                })
                        ),
                        catchHttpError(error =>
                            of(
                                new LoadInvestorByTaxIdFailureAction({
                                    error: error,
                                    reducerSuffix: action.payload.reducerSuffix
                                })
                            )
                        )
                    )
            )
        )
    );
}
