import { inject, Injectable } from '@angular/core';
import { CanActivateFn, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AppState, getDataFromState } from '@trade-platform/ui-shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Ensures a Profile exists
@Injectable()
export class ProfileGuard {
    store = inject<Store<AppState>>(Store);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return getDataFromState(this.store.pipe(select(state => state.profile))).pipe(
            map(profile => !!profile)
        );
    }
}

export const canActivateRoute: CanActivateFn = () => {
    return inject(ProfileGuard).canActivate();
};
