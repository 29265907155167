import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    input,
    OnDestroy,
    OnInit,
    output,
    inject
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    AixFlagComponent,
    AixLoadingComponent,
    AixTagComponent,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { Subscription } from 'rxjs';
import { BannerInfo, ProductRecord, ProductType } from '../../models/product/model';
import { canBuy } from './can-buy';
import { Router } from '@angular/router';
import {
    AixProductCardBeginButtonComponent,
    AixProductCardButtonOption
} from './product-card-begin-button';
import { ProfileStoreFacade, routeConstants } from '@advisor-ui/app-services';
import { AixEffectActions } from '../../store/utils/aix-dispatcher.service';
import { UpdateProductFavoritesSuccessAction } from '../../store/product-favorites/actions';
import { isFeatureEnabled } from '@trade-platform/ui-shared';

export interface ProductCardSelection {
    fundSelected: number;
    shareClassSelected: number;
    typeSelected: ProductType;
}

@Component({
    selector: 'aix-product-card',
    templateUrl: './product-card.html',
    styleUrls: ['./product-card.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AixDataTestingDirective,
        FormsModule,
        ReactiveFormsModule,
        AixButtonComponent,
        AixFlagComponent,
        AixTagComponent,
        AixProductCardBeginButtonComponent,
        AixLoadingComponent
    ]
})
export class AixProductCardComponent implements OnInit, OnDestroy {
    private profileStore = inject(ProfileStoreFacade);
    private router = inject(Router);
    actions$ = inject(AixEffectActions);
    private cd = inject(ChangeDetectorRef);

    showPrice = input(true);
    productCard = input.required<ProductRecord>();
    productOverviewEnabled = input(false);
    language = input<any>({});
    cardSelected = output<ProductCardSelection>();
    cardFavorite = output<ProductRecord>();

    isLoading = false;
    productCardOptions: { label: string; value: string }[] = [];
    isMaintenance = false;
    startMaintenanceButtonType = BUTTON_TYPE.primary;

    isOnboarded: boolean;
    banners: BannerInfo[] = [];
    canPurchase: boolean;
    fundOrderType: ProductType = 'product';

    canBuy = canBuy;
    userCanBuy = false;

    subscriptions: Subscription[] = [];

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        this.isMaintenance = window.location.href.search('maintenance') > 0;
    }

    ngOnInit() {
        this.userCanBuy = canBuy();
        if (!this.isMaintenance) {
            if (this.productCard().shareClass.some(s => s.canPurchase) && this.userCanBuy) {
                const org = this.profileStore.profile.organization;
                this.productCardOptions.push({
                    label: org?.languageOptions?.startOrder?.beginButtonText
                        ? org?.languageOptions.startOrder.beginButtonText
                        : 'Start Purchase',
                    value: 'buy'
                });
                this.canPurchase = true;
            } else {
                this.canPurchase = false;
            }
            if (this.productCard().hasProductMaterial && isFeatureEnabled('productMaterials')) {
                this.productCardOptions.push({
                    label: 'View Product Materials',
                    value: 'product-materials'
                });
            }
            if (
                this.productCard().shareClass.some(s => s.productOverview) &&
                isFeatureEnabled('productOverview')
            ) {
                this.productCardOptions.push({
                    label: 'View Product Overview',
                    value: 'product-overview'
                });
            }
        }
        if (this.productCard().shareClass.some(s => s.isOnboarded)) {
            this.isOnboarded = true;
        } else {
            this.isOnboarded = false;
        }
        this.productCard().shareClass.forEach(s => {
            if (s.bannerInfo && s.bannerInfo.text) {
                if (
                    !this.banners.find(
                        b => b.text === s.bannerInfo?.text && b.color === s.bannerInfo?.color
                    )
                ) {
                    this.banners.push(s.bannerInfo);
                }
            }
        });
        this.fundOrderType = this.productCard().shareClass[0]?.fundOrderType || 'product';

        this.subscriptions.push(
            this.actions$
                .ofClass<UpdateProductFavoritesSuccessAction>(UpdateProductFavoritesSuccessAction)
                .subscribe(() => {
                    this.isLoading = false;
                    this.cd.detectChanges();
                })
        );
    }

    onClickFavorite(productCard: ProductRecord) {
        this.isLoading = true;
        this.cardFavorite.emit(productCard);
    }

    fundStructureLabel() {
        if (
            this.productCard().shareClass.some(
                s => s.fundOrderType === 'cit' || s.fundOrderType === '3-38'
            )
        ) {
            return 'Structure';
        } else {
            return 'Security Type';
        }
    }

    onClickStart() {
        this.cardSelected.emit({
            fundSelected: this.productCard().id,
            shareClassSelected: this.productCard().shareClass[0].id,
            typeSelected: this.fundOrderType
        });
    }

    onCardClick(evt: AixProductCardButtonOption) {
        switch (evt.value) {
            case 'buy':
                this.onClickStart();
                break;

            case 'product-materials':
                this.router.navigate(
                    routeConstants.routes.products.detail(
                        this.productCard().id,
                        this.productCard().shareClass[0].id
                    )
                );
                break;

            case 'product-overview':
                this.router.navigate(
                    routeConstants.routes.products.detailOverview(
                        this.productCard().id,
                        this.productCard().shareClass[0].id
                    )
                );
                break;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
