import {
    AixDeactivateGuardComponent,
    AixUnloadGuardDirective,
    checkSessionOnFormChange,
    KeepAliveService,
    ProfileStoreFacade
} from '@advisor-ui/app-services';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    OnDestroy,
    OnInit,
    SecurityContext,
    viewChild,
    ViewEncapsulation,
    inject
} from '@angular/core';
import {
    FormsModule,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormGroup
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import {
    DynamicFormComponent,
    FieldEvent,
    generateRefIdMappings,
    parseFieldConfig
} from '@trade-platform/dynamic-forms';
import { FieldConfig } from '@trade-platform/form-fields';
import { commentStatus, commentTag } from '@trade-platform/lib-enums';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    AixLoadingComponent,
    AixModalComponent,
    AixNotificationComponent,
    AixPageHeaderComponent,
    AixPageSectionComponent,
    BUTTON_TYPE,
    NotificationConfig
} from '@trade-platform/ui-components';
import {
    AixEffectActions,
    AixErrorBoxComponent,
    AixProgressComponent,
    AixStepProgressComponent,
    AUTH_PERSON_COUNT,
    BaseOrder,
    computeSetFormDataAction,
    dynamicFormActions,
    ErrorWrapper,
    getFormErrorFromState,
    Investor,
    Order,
    OrderForm,
    OrderFormComment,
    SetFormDataAction,
    TitleService
} from '@trade-platform/ui-shared';
import {
    AixSanitizePipe,
    ApiFormError,
    ENVIRONMENT,
    IEnvironment,
    LogService,
    removeEmptyObjectsFromArray
} from '@trade-platform/ui-utils';
import { cloneDeep } from 'lodash-es';
import { isInProgress, RemoteData, RemoteDataModule } from 'ngx-remotedata';
import { BehaviorSubject, combineLatest, fromEvent, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Logger } from 'typescript-logging';
import {
    CONFIRM_ORDER_CANCELLATION_MSG,
    CONFIRM_ORDER_CANCELLATION_MSG_SUB,
    CONFIRM_ORDER_CANCELLATION_TITLE,
    ERROR_CANCEL_ORDER_MESSAGE,
    ERROR_CONTACT
} from '../constants';
import { OrderFormCommentsPipe } from '../order-comments/order-form-comments-pipe';
import { calculateNextForm, FINISHED_FORM_ID } from './utils';
import { AixCancelChangesModalComponent, SafeHtmlPipe } from '@advisor-ui/app-components';
import { getLanguage } from '../../../../language/language.base';
import { OrderOverview } from '../../../../language/language.interface';
import { hasReadOnlyAccess } from '../utils';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../base.orders.store.facade';
import { AsyncPipe, NgClass, TitleCasePipe } from '@angular/common';
import { AixSidebarComponent } from './sidebar/sidebar';

@Component({
    selector: 'aix-orders-process',
    templateUrl: './process.html',
    styleUrls: ['./process.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AixPageHeaderComponent,
        AixLoadingComponent,
        AixErrorBoxComponent,
        AixPageSectionComponent,
        NgClass,
        AixStepProgressComponent,
        AixProgressComponent,
        AixNotificationComponent,
        AixDataTestingDirective,
        DynamicFormComponent,
        AixButtonComponent,
        FormsModule,
        ReactiveFormsModule,
        AixSidebarComponent,
        AixModalComponent,
        AixCancelChangesModalComponent,
        AsyncPipe,
        TitleCasePipe,
        AixSanitizePipe,
        RemoteDataModule,
        SafeHtmlPipe
    ]
})
export class AixOrdersProcessComponent
    extends AixUnloadGuardDirective
    implements OnInit, OnDestroy, AixDeactivateGuardComponent
{
    private environment = inject<IEnvironment>(ENVIRONMENT);
    store = inject<BaseOrdersStoreFacade>(ORDERS_STORE_FACADE);
    route = inject(ActivatedRoute);
    router = inject(Router);
    private profileStoreFacade = inject(ProfileStoreFacade);
    private titleService = inject(TitleService);
    private _fb = inject(UntypedFormBuilder);
    private orderFormCommentsPipe = inject(OrderFormCommentsPipe);
    private keepAliveService = inject(KeepAliveService);
    private logService = inject(LogService);
    private cd = inject(ChangeDetectorRef);
    private sanitizer = inject(DomSanitizer);
    private actions$ = inject(AixEffectActions);

    @HostBinding('class.host-flex')
    flex = true;

    dynamicFormRef = viewChild.required<DynamicFormComponent>('dynamicFormRef');
    dynamicFormNativeRef = viewChild.required('dynamicFormRef', { read: ElementRef });
    modal = viewChild<AixModalComponent>('modal');
    cancelOrderConfirmModal = viewChild<AixModalComponent>('cancelOrderConfirmModal');
    notification = viewChild<AixNotificationComponent>('textNotification');
    prefillNotification = viewChild<AixNotificationComponent>('prefillNotification');
    progressNotification = viewChild<AixNotificationComponent>('progressNotification');
    serverValidationErrorNotification = viewChild<AixNotificationComponent>(
        'serverValidationErrorNotification'
    );
    cancelChangesModal = viewChild<AixCancelChangesModalComponent>('cancelChangesModal');

    language: OrderOverview = getLanguage('orderOverview');
    breadcrumbText = this.language ? this.language.breadcrumbs[this.store.type] : this.store.type;

    subscriptions: Subscription[] = [];
    progressUpdated = false;
    progress = 0;
    savedProgress = 0;
    savingProgress = false;
    movingForward = false;
    isReadOnly: boolean | undefined | null = false;
    orderId: string;

    readonly loadOrderReducerSuffix = this.store.type;
    readonly updateOrderReducerSuffix = 'orderUpdate';
    readonly updateOrderProgressReducerSuffix = 'orderProgressUpdate';
    isLoading$: Observable<boolean>;
    states: Observable<RemoteData<any, any>>[];

    formError$: Observable<ApiFormError | ErrorWrapper>;

    commentsForm: UntypedFormGroup;
    activeComments: OrderFormComment[];
    totalComments = 0;
    currentFormId: string;
    nextForm: OrderForm;
    readonly prefillText =
        'This form was recently completed for the same account registration. The information previously entered has been pre-populated for your convenience. Please review for accuracy and completeness.';

    progressNotificationLanguage = {
        ok: 'Your progress has been saved.',
        error: `We're sorry, we were unable to save your progress, please try again or contact ${this.environment.contactDetails.supportEmail}.`
    };
    progressNotificationConfig: NotificationConfig<string> = {
        status: 'ok',
        message: ''
    };
    serverValidationErrorNotificationMessages: string[] = [];
    defaultServerValidationErrorNotificationMessage = `We're sorry, we were unable to validate all the information on this form, please try again or contact ${this.environment.contactDetails.supportEmail}.`;

    modalActive = {
        title: '',
        buttons: [] as string[],
        content: ''
    };
    readonly modalOptions = {
        buttons: {
            formNotOnboarded: ['Cancel', 'Continue To Order Overview']
        },
        content: {
            formNotOnboarded:
                "You will be returned to the order overview screen. Please make sure you've completed all other forms before returning to order overview to prevent any loss of data."
        }
    };

    notificationText = '';
    readonly notificationLanguage = {
        nigo: 'Please complete all action items noted on this form to continue.'
    };
    readonly LOG: Logger;
    CONFIRM_ORDER_CANCELLATION_MSG = CONFIRM_ORDER_CANCELLATION_MSG;
    CONFIRM_ORDER_CANCELLATION_MSG_SUB = CONFIRM_ORDER_CANCELLATION_MSG_SUB;
    CONFIRM_ORDER_CANCELLATION_TITLE = CONFIRM_ORDER_CANCELLATION_TITLE;
    ERROR_CANCEL_ORDER_MESSAGE = ERROR_CANCEL_ORDER_MESSAGE;
    ERROR_CONTACT = ERROR_CONTACT;

    goToNextFieldButtonType = BUTTON_TYPE.link;
    saveProgressButtonType = BUTTON_TYPE.secondary;
    continueProgressButtonType = BUTTON_TYPE.primary;

    // Discard changes modal
    canDeactivate$ = new BehaviorSubject(false);
    nextState: RouterStateSnapshot;

    isFormInitiated = false;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        super();
        this.LOG = this.logService.getLogger('app.modules.orders-process');
        this.titleService.setTitle('Orders');
        this.breadcrumbText = this.breadcrumbText ?? '';

        this.formError$ = getFormErrorFromState(this.store.orderUpdateRemoteData$);

        this.states = [
            this.store.orderRemoteData$,
            this.store.orderFormCommentsRemoteData$,
            this.store.orderFormRemoteData$,
            this.store.orderUpdateRemoteData$
        ];

        this.isLoading$ = combineLatest([
            this.store.orderRemoteData$,
            this.store.orderFormCommentsRemoteData$,
            this.store.orderFormRemoteData$,
            this.store.orderUpdateRemoteData$,
            this.store.orderCancelRemoteData$
        ]).pipe(map(states => states.some(isInProgress)));
    }

    ngOnInit() {
        this.store.actions.clearInvestor.dispatch({ reducerSuffix: 'primary' });
        this.store.actions.clearInvestor.dispatch({ reducerSuffix: 'joint' });

        this.commentsForm = this._fb.group({});

        const authPersonsTotalCount = new Array(AUTH_PERSON_COUNT).fill(null);
        authPersonsTotalCount.forEach((item, index) => {
            const i = ++index;
            this.store.actions.clearInvestor.dispatch({ reducerSuffix: `authPerson${i}` });
        });

        this.subscriptions.push(
            fromEvent(this.dynamicFormNativeRef().nativeElement, FieldEvent.SCROLL).subscribe(
                (event: any) => {
                    const element = (event as CustomEvent).detail;
                    const headerOffset = 205;
                    const elementPosition = element.getBoundingClientRect().top;
                    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth'
                    });
                }
            ),
            this.route.params.subscribe(params => {
                this.canDeactivate$.next(false);
                this.serverValidationErrorNotification()?.closeNotification();
                this.orderId = this.sanitizer.sanitize(
                    SecurityContext.URL,
                    params['orderId']
                ) as string;
                const formId = this.sanitizer.sanitize(SecurityContext.URL, params['formId']);
                this.currentFormId = formId || '';
                this.movingForward = false;
                this.store.actions.getOrder.dispatch({
                    orderId: this.orderId,
                    reducerSuffix: this.loadOrderReducerSuffix
                });
            }),
            this.store.actions.getOrder.success$
                .pipe(
                    filter(action => action.payload.reducerSuffix === this.loadOrderReducerSuffix)
                )
                .subscribe(action => {
                    if (!this.currentFormId) {
                        this.currentFormId = Object.keys(action.payload.order.forms)[0];
                    }
                    this.store.actions.getForm.dispatch({
                        orderId: action.payload.order.id,
                        formId: this.currentFormId
                    });
                    const currentForm = action.payload.order.forms[this.currentFormId];
                    if (
                        currentForm.status === 'pristine' &&
                        Object.keys(currentForm.fields).length > 0
                    ) {
                        // show pre-fill message
                        this.prefillNotification()?.openNotification();
                    } else {
                        this.prefillNotification()?.closeNotification();
                    }
                }),
            this.store.actions.getForm.success$.subscribe(action => {
                const rawFields = action.payload.form.fields;
                const fields: FieldConfig[] = parseFieldConfig(
                    rawFields as FieldConfig[],
                    this.environment.enableDebugging
                );
                this.setForm(fields, this.currentFormId);
            }),
            this.store.actions.updateOrder.success$
                .pipe(
                    filter(action => action.payload.reducerSuffix === this.updateOrderReducerSuffix)
                )
                .subscribe(action => {
                    this.progressNotification()?.closeNotification();

                    const order = this.store.order;
                    this.calculateNextForm(action.payload.result as Order, this.currentFormId);
                    this.progress = 0;
                    this.savedProgress = 0;
                    if (this.nextForm.id === FINISHED_FORM_ID) {
                        this.router.navigate(this.store.routes.process.overview(order.id));
                    } else {
                        this.router.navigate(this.store.routes.form(order.id, this.nextForm.id));
                    }
                }),
            this.store.actions.updateOrder.success$
                .pipe(
                    filter(
                        action =>
                            action.payload.reducerSuffix === this.updateOrderProgressReducerSuffix
                    )
                )
                .subscribe(action => {
                    this.store.actions.getOrder.dispatch({
                        orderId: this.orderId,
                        reducerSuffix: this.loadOrderReducerSuffix
                    });

                    this.savedProgress = this.progress;
                    this.savingProgress = false;

                    this.dynamicFormRef()?.destroyPreviousForm();

                    this.progressNotificationConfig.status = 'ok';
                    this.progressNotificationConfig.message = this.progressNotificationLanguage.ok;
                    this.progressNotification()?.openNotification();
                    this.cd.detectChanges();
                }),
            this.store.actions.updateOrder.failure$
                .pipe(
                    filter(
                        action =>
                            action.payload.reducerSuffix === this.updateOrderProgressReducerSuffix
                    )
                )
                .subscribe(action => {
                    this.savingProgress = false;
                    if (action.payload.error instanceof ApiFormError) {
                        (action.payload.error as ApiFormError).messages.forEach(validationError => {
                            if (validationError.message) {
                                this.serverValidationErrorNotificationMessages.push(
                                    validationError.message
                                );
                            }
                            this.dynamicFormRef()?.injectServerValidation(validationError);
                        });
                        this.serverValidationErrorNotification()?.openNotification();
                    } else {
                        this.progressNotificationConfig.status = 'error';
                        this.progressNotificationConfig.message =
                            this.progressNotificationLanguage.error;
                        this.progressNotification()?.openNotification();
                    }
                    this.cd.detectChanges();
                }),
            this.store.actions.updateOrder.failure$
                .pipe(
                    filter(action => action.payload.reducerSuffix === this.updateOrderReducerSuffix)
                )
                .subscribe(action => {
                    if (action.payload.error instanceof ApiFormError) {
                        (action.payload.error as ApiFormError).messages.forEach(validationError => {
                            if (validationError.message) {
                                this.serverValidationErrorNotificationMessages.push(
                                    validationError.message
                                );
                            }
                        });
                        this.serverValidationErrorNotification()?.openNotification();
                    }
                    this.cd.detectChanges();
                }),
            this.store.actions.cancel.success$.subscribe(action => {
                this.movingForward = true;
                this.router.navigate(this.store.routes.status());
            }),
            this.store.actions.showSecondAuthPerson.success$.subscribe(action => {
                const value = {
                    order: {
                        forms: {
                            [this.currentFormId]: {
                                fields: { showSecondAuthorizedPerson: true }
                            }
                        }
                    }
                };
                this.dynamicFormRef()?.patchValue(value);
            }),
            this.store.actions.hideSecondAuthPerson.success$.subscribe(action => {
                const value = {
                    order: {
                        forms: {
                            [this.currentFormId]: {
                                fields: { showSecondAuthorizedPerson: false }
                            }
                        }
                    }
                };
                this.dynamicFormRef()?.patchValue(value);
            }),
            this.store.actions.showFirstAuthPerson.success$.subscribe(action => {
                const value = {
                    order: {
                        forms: {
                            [this.currentFormId]: {
                                fields: { [`showCorpPerson${action.payload}`]: true }
                            }
                        }
                    }
                };
                this.dynamicFormRef()?.patchValue(value);
            }),
            this.store.actions.hideFirstAuthPerson.success$.subscribe(action => {
                const value = {
                    order: {
                        forms: {
                            [this.currentFormId]: {
                                fields: { [`showCorpPerson${action.payload}`]: false }
                            }
                        }
                    }
                };
                this.dynamicFormRef()?.patchValue(value);
            }),
            this.store.actions.getFormComments.success$.subscribe(action => {
                this.activeComments = this.orderFormCommentsPipe.transform(
                    action.payload,
                    false,
                    false,
                    [this.currentFormId],
                    commentTag.change
                );
                this.totalComments = this.activeComments ? this.activeComments.length : 0;
                action.payload.forEach((comment: { id: string; status: string }) => {
                    if (comment.id) {
                        this.commentsForm.registerControl(
                            `${comment.id}`,
                            this._fb.control(comment.status === commentStatus.resolved)
                        );
                        this.commentsForm.patchValue({
                            [comment.id]: comment.status === commentStatus.resolved
                        });
                    }
                });

                // Check for unresolved NIGO comments, show NIGO notification if necessary;
                if (
                    this.activeComments.find(comment => comment.status === commentStatus.unresolved)
                ) {
                    this.notificationText = this.notificationLanguage.nigo;
                    this.notification()?.openNotification();
                } else {
                    this.notificationText = '';
                    this.notification()?.closeNotification();
                }
            }),
            this.formError$.subscribe(err => {
                if (err instanceof ApiFormError && err.messages && err.messages.length) {
                    err.messages.forEach(validationError =>
                        this.dynamicFormRef()?.injectServerValidation(validationError)
                    );
                }
            }),
            this.store.actions.resolveFormComments.success$.subscribe(action => {
                this.updateOrder(
                    action.payload.reducerSuffix === this.updateOrderProgressReducerSuffix
                );
            }),
            this.store.actions.resolveFormComments.failure$.subscribe(action => {
                this.savingProgress = false;
                this.LOG.debug(
                    () => `Comments resolution failure, error is ${action.payload.error.debugText}`
                );
                // TODO: Do whatever is needed to reset view, as the user should not be moved to the next form;
                // TODO: Show error message in error box to the user that the resolve comments calls failed?;
                // TODO: Reload comments?;
            }),
            this.store.orderSuccess$.subscribe(order => {
                const profile = this.profileStoreFacade.profile;
                this.isReadOnly = hasReadOnlyAccess(profile, order.firm.id);
            }),
            checkSessionOnFormChange(this.dynamicFormRef(), this.keepAliveService),
            this.actions$.ofClass<SetFormDataAction>(SetFormDataAction).subscribe(action => {
                computeSetFormDataAction(this.dynamicFormRef(), action);
            })
        );
    }

    onFormInitiated(initialized: boolean) {
        this.isFormInitiated = initialized;
        this.cd.detectChanges();
    }

    private calculateNextForm(order: BaseOrder, formId: string) {
        this.nextForm = calculateNextForm(order, formId);
    }

    private setForm(fields: FieldConfig[], formId: string) {
        const order = cloneDeep(this.store.order);
        this.calculateNextForm(order, formId);
        this.currentFormId = formId;

        // Remove all forms that are not the current form
        for (const prop in order.forms) {
            if (prop !== this.currentFormId) {
                delete order.forms[prop];
            }
        }

        this.dynamicFormRef()?.initializeForm(fields, { order }, undefined, dynamicFormActions);

        if (order.account?.investors && order.account.investors.length === 3) {
            this.store.actions.showSecondAuthPerson.dispatch();
        }
    }

    private isProgressSaved(): boolean {
        return this.savedProgress > 0 && this.savedProgress === this.progress;
    }

    private checkValidity(): boolean {
        return this.dynamicFormRef()?.isFormValid || this.isProgressSaved();
    }

    gotoForm(formId: string) {
        this.progressNotification()?.closeNotification();

        const order = this.store.order;
        const form = order.forms[formId];
        if (form && form.isOnboarded === false) {
            this.modalActive.title = form.name;
            this.modalActive.buttons = this.modalOptions.buttons.formNotOnboarded;
            this.modalActive.content = this.modalOptions.content.formNotOnboarded;
            this.modal()?.openModal();
        } else {
            this.router.navigate(this.store.routes.form(order.id, formId));
        }
    }

    modalSelected(e: string) {
        switch (e) {
            case 'Continue To Order Overview':
                this.goToOrderOverview();
                break;
            default:
                break;
        }
    }

    isDirty() {
        return this.dynamicFormRef()?.isFormDirty;
    }

    canLeave(): boolean {
        return !this.dynamicFormRef()?.isFormDirty || this.movingForward;
    }

    canDeactivate(nextState: RouterStateSnapshot) {
        if (this.isDirty() && !this.movingForward && !this.isReadOnly) {
            this.nextState = nextState;
            this.cancelChangesModal()?.openModal();
            return this.canDeactivate$.asObservable();
        }
        return true;
    }

    cancelChangesModalSelected(e: string) {
        if (e === 'Yes, continue') {
            this.movingForward = true;
            this.canDeactivate$.next(true);
            this.router.navigateByUrl(this.nextState.url);
        } else {
            this.canDeactivate$.next(false);
        }
        this.cancelChangesModal()?.closeModal();
    }

    goToOrderOverview() {
        const order = this.store.order;
        if (order) {
            this.router.navigate(this.store.routes.process.overview(order.id));
        }
    }

    private updateOrder(skipValidity = false) {
        const order = cloneDeep(this.store.order);
        const formValue = cloneDeep(removeEmptyObjectsFromArray(this.dynamicFormRef()?.value));

        // Create order object if form doesn't contain any field
        if (!formValue.order) {
            formValue.order = {};
        }

        formValue.order.id = order.id;

        if (order.account) {
            if (formValue?.order?.account?.investors) {
                if (formValue.order.account.investors[0].id) {
                    // Match investor by id
                    formValue.order.account.investors = formValue.order.account.investors.map(
                        (formInvestor: Investor) => {
                            const foundOrderInvestor = order.account?.investors.find(
                                orderInvestor => {
                                    return (
                                        !!orderInvestor.id &&
                                        !!formInvestor.id &&
                                        orderInvestor.id === formInvestor.id
                                    );
                                }
                            );
                            if (foundOrderInvestor) {
                                // The investor already exists, so we merge investors
                                return {
                                    ...foundOrderInvestor,
                                    ...formInvestor
                                };
                            } else {
                                // It's a new investor
                                if (formInvestor.id === '') {
                                    formInvestor.id = undefined;
                                }
                                return formInvestor;
                            }
                        }
                    );
                } else {
                    // Match investor by index
                    formValue.order.account.investors = (
                        formValue.order.account.investors as Investor[]
                    ).map((formInvestor, index) => {
                        const orderInvestor: Investor = order.account?.investors[index] || {};

                        // Clean blanks ids in case there's a default value
                        if (formInvestor.id === '') {
                            formInvestor.id = undefined;
                        }

                        if (orderInvestor) {
                            return {
                                ...orderInvestor,
                                ...formInvestor
                            };
                        } else {
                            return formInvestor;
                        }
                    });
                }

                order.account.investors.forEach((orderInvestor: Investor) => {
                    const investorExistsInForm = (
                        formValue.order.account.investors as Investor[]
                    ).find(formInvestor => formInvestor.id === orderInvestor.id);

                    // Add missing investors that are not in the form
                    if (!investorExistsInForm) {
                        if (orderInvestor.id === '') {
                            orderInvestor.id = undefined;
                        }
                        formValue.order.account.investors.push(orderInvestor);
                    }
                });

                // Sort investors in the same order that are saved
                const orderInvestors = order.account.investors.map(
                    orderInvestor => orderInvestor.id
                );
                const mapOrder = (investors: any[], correctOrder: any[], key: string) => {
                    const sortMap = correctOrder.reduce((r, v, i) => ((r[v] = i), r), {});
                    return investors.sort((inv, b) => sortMap[inv[key]] - sortMap[b[key]]);
                };
                mapOrder(formValue.order.account.investors, orderInvestors, 'id');
            }
        }

        // Create forms object in case we don't have a value in the reactive form
        if (!formValue.order.forms) {
            formValue.order.forms = {};
        }
        if (!formValue.order.forms[this.currentFormId]) {
            formValue.order.forms[this.currentFormId] = {
                fields: {}
            };
        }

        formValue.order.forms[this.currentFormId].id = this.currentFormId;
        // const map: {[refId: string]: FieldMapType} = .....
        const fieldMaps = this.dynamicFormRef()?.fieldMap();
        formValue.order.forms[this.currentFormId].map = {
            ...fieldMaps,
            ...generateRefIdMappings(this.currentFormId, fieldMaps)
        };

        formValue.order.forms[this.currentFormId].fields = {
            ...formValue.order.forms[this.currentFormId].fields,
            ...this.dynamicFormRef()?.oneOfMap()
        };

        if (skipValidity || this.checkValidity()) {
            const reducerSuffix = skipValidity
                ? this.updateOrderProgressReducerSuffix
                : this.updateOrderReducerSuffix;
            const progress = this.progress;
            const hasUnresolvedComments = this.hasUnresolvedComments();
            const isFormValid = this.dynamicFormRef()?.isFormValid;

            this.store.actions.updateOrder.dispatch({
                updatePayload: formValue.order,
                updatePayloadExtras: {
                    formCompleted: progress === 100 && !hasUnresolvedComments && isFormValid
                },
                reducerSuffix
            });
        }
    }

    goToNextField() {
        this.dynamicFormRef()?.nextPendingField();
    }

    private resolveComments(saveProgress = false) {
        /**
         * Dispatch Comment Resolutions;
         */
        const rawCommentsValue = this.commentsForm.getRawValue();
        let resolvedActiveComments = null;
        if (this.activeComments && rawCommentsValue) {
            resolvedActiveComments = this.activeComments
                .filter(comment => rawCommentsValue[comment.id])
                .map(comment => comment.id);
        }

        // Dispatch resolve comment calls;
        this.store.actions.resolveFormComments.dispatch({
            data: {
                orderId: this.orderId,
                documentId: this.currentFormId
            },
            commentIds: resolvedActiveComments as number[],
            reducerSuffix: saveProgress
                ? this.updateOrderProgressReducerSuffix
                : this.updateOrderReducerSuffix
        });
    }

    onContinue() {
        const isProgressSaved = this.isProgressSaved();
        if (this.progress < 100 && this.progressUpdated && !isProgressSaved) {
            this.goToNextField();
        } else if (!this.dynamicFormRef()?.isFormValid && !isProgressSaved) {
            /**
             * 100% progress not always correlates with form validity.
             * @see NotificationConfig.makeFormInvalid
             */
            this.dynamicFormRef()?.nextBlockingNotification();
        } else {
            this.movingForward = true;
            this.resolveComments();
            this.serverValidationErrorNotificationMessages = [];
            this.serverValidationErrorNotification()?.closeNotification();
            this.progressNotification()?.closeNotification();
        }
    }

    onSaveProgress() {
        this.savingProgress = true;
        this.resolveComments(true);
        this.serverValidationErrorNotificationMessages = [];
        this.serverValidationErrorNotification()?.closeNotification();
        this.progressNotification()?.closeNotification();
    }

    cancelOrderConfirmModalSelected(e: string) {
        switch (e) {
            case 'Yes, continue':
                this.store.actions.cancel.dispatch({ orderId: this.orderId });
                break;
            default:
                break;
        }
    }

    cancelOrder() {
        this.cancelOrderConfirmModal()?.openModal();
    }

    updateProgress(amount: number) {
        this.progressUpdated = true;
        this.progress = amount;
    }

    hasUnresolvedComments(): boolean {
        return this.getUnresolvedComments() > 0;
    }

    getUnresolvedComments(): number {
        let unresolvedComments = 0;
        if (this.activeComments && this.activeComments.length > 0) {
            for (let i = 0; i < this.activeComments.length; i++) {
                const comment = this.activeComments[i];
                if (this.commentsForm.value[comment.id] === false) {
                    unresolvedComments++;
                }
            }
        }
        return unresolvedComments;
    }

    cssClasses() {
        return this.totalComments > 0 ? 'make-space-in-ie11' : '';
    }

    ngOnDestroy() {
        this.store.actions.updateOrder.reset();
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
