import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import {
    OrderApprovalBody,
    OrderApprovalResponse,
    orderApprovalResponseDecoder,
    OrderApprovalStartResponse,
    orderApprovalStartResponseDecoder
} from '@trade-platform/ui-shared';

@Injectable()
export class OrderApproveService {
    private http = inject(HttpClient);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    orderApproveStart(orderId: string) {
        return this.http
            .post<OrderApprovalStartResponse>(
                `${this.environment.api}orders/${orderId}/approvals/start`,
                null
            )
            .pipe(
                concatMap(p => fromPromise(orderApprovalStartResponseDecoder.decodeToPromise(p)))
            );
    }

    orderApproveResume(orderId: string) {
        return this.http.post<never>(
            `${this.environment.api}orders/${orderId}/approvals/resume`,
            null
        );
    }

    orderApprove(
        orderId: string,
        approvalId: number,
        body: OrderApprovalBody
    ): Observable<OrderApprovalResponse> {
        return this.http
            .post<OrderApprovalResponse>(
                `${this.environment.api}orders/${orderId}/approvals/${approvalId}`,
                body
            )
            .pipe(concatMap(p => fromPromise(orderApprovalResponseDecoder.decodeToPromise(p))));
    }
}
