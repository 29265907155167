import { Component, contentChildren, OnInit } from '@angular/core';
import { AixTabComponent } from './aix-tab.component';
import { NgClass } from '@angular/common';

@Component({
    selector: 'aix-tabs',
    templateUrl: 'aix-tabs.component.html',
    styleUrls: ['aix-tabs.component.scss'],
    standalone: true,
    imports: [NgClass]
})
export class AixTabsComponent implements OnInit {
    tabs = contentChildren(AixTabComponent);

    ngOnInit(): void {
        const activeTabs = this.tabs().filter(tab => tab.active());
        if (activeTabs.length === 0) {
            this.selectTab(this.tabs()[0]);
        }
    }

    selectTab(tab: any) {
        this.tabs().forEach(tab => tab.active.set(false));
        tab.active.set(true);
    }

    selectTabByIndex(index: number) {
        this.selectTab(this.tabs()[index]);
    }
}
