import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    inject
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
    AixEffectActions,
    AppState,
    Brand,
    ClearPreviewBrandingSuccessAction,
    getDataFromState,
    PreviewBrandingSuccessAction,
    Profile,
    RegisterToken
} from '@trade-platform/ui-shared';
import { Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ColorStyle, DynamicColorStyle, ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { AsyncPipe, NgStyle } from '@angular/common';
import { AixPhonePipe } from '@trade-platform/dynamic-forms';

@Component({
    selector: 'aix-footer',
    templateUrl: 'footer.html',
    styleUrls: ['footer.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgStyle, AsyncPipe, AixPhonePipe]
})
export class AixFooterComponent implements OnDestroy {
    private ref = inject(ChangeDetectorRef);
    router = inject(Router);
    store = inject<Store<AppState>>(Store);
    actions$ = inject(AixEffectActions);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    isOutages = false;

    profile$: Observable<Profile>;
    register$: Observable<RegisterToken>;
    supportEmail: string;
    supportPhone: string;
    isProfile = false;

    subscriptions: Subscription[] = [];

    activePreviewBrand: Brand | null = null;

    styles: DynamicColorStyle = {
        brandingStyle: {
            backgroundColor: null
        }
    };

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        const store = this.store;

        const profileState$ = store.pipe(select(state => state.profile));
        this.profile$ = getDataFromState(profileState$).pipe(debounceTime(50));
        const registerState$ = store.pipe(select(state => state.registerToken));
        this.register$ = getDataFromState(registerState$).pipe(debounceTime(50));
        this.subscriptions.push(
            this.actions$
                .ofClass<PreviewBrandingSuccessAction>(PreviewBrandingSuccessAction)
                .subscribe(action => {
                    if (action && action.payload) {
                        this.previewBrand(action.payload);
                    }
                }),
            this.actions$
                .ofClass<ClearPreviewBrandingSuccessAction>(ClearPreviewBrandingSuccessAction)
                .subscribe(action => {
                    this.clearPreviewBrand();
                }),
            this.router.events.subscribe(e => {
                if (e instanceof NavigationEnd) {
                    this.isOutages = e.url.indexOf('outage') !== -1;
                    this.ref.detectChanges();
                }
            }),
            this.profile$.subscribe((profile: Profile) => {
                this.isProfile = true;
                this.supportEmail =
                    profile.organization?.supportEmail ||
                    this.environment.contactDetails.supportEmail;
                this.supportPhone =
                    profile.organization?.supportPhoneNumber ||
                    this.environment.contactDetails.supportPhone;
            }),
            this.register$.subscribe((register: RegisterToken) => {
                this.supportEmail = this.environment.contactDetails.supportEmail;
                this.supportPhone = this.environment.contactDetails.supportPhone;
                this.ref.detectChanges();
            })
        );
    }

    previewBrand(brand: Brand) {
        this.activePreviewBrand = brand;

        if (brand.primaryColorDark) {
            this.styles.brandingStyle = {
                backgroundColor: brand.primaryColorDark
            };
        }
        this.ref.detectChanges();
    }

    getLinkStyle(): ColorStyle {
        return this.activePreviewBrand?.secondaryColor
            ? { color: this.activePreviewBrand.secondaryColor }
            : { color: null };
    }

    onHoverLink(e: any) {
        if (this.activePreviewBrand?.secondaryColor?.length) {
            e.style.color = this.activePreviewBrand.secondaryColor;
        }
    }

    onLeaveLink(e: any) {
        if (this.activePreviewBrand || this.activePreviewBrand === null) {
            e.style.color = null;
        }
    }

    clearPreviewBrand() {
        this.activePreviewBrand = null;
        this.styles.brandingStyle.backgroundColor = null;
        this.ref.detectChanges();
    }

    ngOnDestroy() {
        this.subscriptions.filter(sub => !!sub).forEach(sub => sub.unsubscribe());
    }
}
