import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ENVIRONMENT, IEnvironment, stringify } from '@trade-platform/ui-utils';
import { ProductMaterial, ProductMaterialPayload } from '../../models/product-materials/model';
import { productMaterialsDecoder } from '../../decoders/product-materials/decoder';

@Injectable()
export class ProductMaterialsService {
    private http = inject(HttpClient);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    loadProductMaterials(payload: ProductMaterialPayload): Observable<ProductMaterial[]> {
        const url: string = payload.options
            ? `${this.environment.api}funds/${payload.id}/materials?${stringify(payload.options)}`
            : `${this.environment.api}funds/${payload.id}/materials`;
        return this.http.get<ProductMaterial[]>(url).pipe(
            concatMap(response => {
                return fromPromise(productMaterialsDecoder.decodeToPromise(response));
            })
        );
    }
}
