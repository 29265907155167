import { Component, HostBinding, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { fromObservable } from '@trade-platform/ui-utils';
import { DynamicFormHelper } from '../../dynamic-form.helper';
import { ButtonFieldConfig } from '@trade-platform/form-fields';
import { Field } from '../field.interface';

import { AixDataTestingDirective } from '@trade-platform/ui-components';

@Component({
    selector: 'aix-dynamic-button',
    styleUrls: ['./button.component.scss'],
    templateUrl: './button.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [AixDataTestingDirective]
})
export class AixDynamicButtonComponent implements Field, OnInit {
    private helper = inject(DynamicFormHelper);
    private store = inject<Store<any>>(Store);

    // Static
    static HOST_CLASS = 'aix-flex-grid';

    // Decorators
    @HostBinding('class')
    classNames = AixDynamicButtonComponent.HOST_CLASS;

    // Other
    config: ButtonFieldConfig;
    solid = true;
    bold = false;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    ngOnInit() {
        this.classNames = this.config.classNames
            ? [
                  AixDynamicButtonComponent.HOST_CLASS,
                  ...this.helper.parseHostProperties(this.config.classNames.host)
              ].join(' ')
            : AixDynamicButtonComponent.HOST_CLASS;

        this.solid = this.config.solid === undefined ? true : this.config.solid;
        this.bold = this.config.bold === undefined ? false : this.config.bold;
    }

    onClickButton() {
        if (this.config.storeAction && this.config.storeAction !== '') {
            const actions =
                typeof this.config.storeAction === 'string'
                    ? [this.config.storeAction]
                    : [...this.config.storeAction];

            // WARNING: Expecting simple string storeActions. ParametrizedStoreActions are not supported
            actions.forEach(elem =>
                this.store.dispatch(
                    this.helper.actionFactory[elem as string](fromObservable(this.config.value))
                )
            );
        }
    }
}
