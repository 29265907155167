import { Pipe, PipeTransform, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'sanitizeHtml',
    standalone: true
})
export class SafeHtmlPipe implements PipeTransform {
    private sanitized = inject(DomSanitizer);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}
    transform(value: string) {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}
