import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchHttpError } from '@trade-platform/ui-shared';
import {
    OrderSigningComplete,
    OrderSigningCompleteActionTypes,
    OrderSigningCompleteFailure,
    OrderSigningCompleteSuccess
} from './actions';
import { OrderSigningCompleteService } from './service';

@Injectable()
export class OrderSigningCompleteEffects {
    private actions$ = inject(Actions);
    private orderSigningCompleteService = inject(OrderSigningCompleteService);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    orderSigningComplete$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderSigningComplete>(OrderSigningCompleteActionTypes.ORDER_SIGNING_COMPLETE),
            switchMap(action =>
                this.orderSigningCompleteService.orderSigningComplete(action.payload.orderId).pipe(
                    map(_ => new OrderSigningCompleteSuccess()),
                    catchHttpError(error => of(new OrderSigningCompleteFailure({ error })))
                )
            )
        )
    );
}
