import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { getFromStorage, setToStorage, withStorage } from '@trade-platform/ui-utils';
import { of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { catchHttpError } from '../utils/catch-http-error';
import {
    ClearPrivacyPolicyAction,
    LoadPrivacyPolicyAction,
    LoadPrivacyPolicyFailureAction,
    LoadPrivacyPolicyFromStorageAction,
    LoadPrivacyPolicySuccessAction,
    PrivacyPolicyActionTypes,
    SetPrivacyPolicyAction
} from './actions';
import { PrivacyPolicy } from '../../models/privacy-policy/model';
import { PrivacyPolicyService } from './service';

@Injectable()
export class PrivacyPolicyEffects {
    private actions$ = inject(Actions);
    private service = inject(PrivacyPolicyService);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    loadPrivacyPolicy$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadPrivacyPolicyAction>(PrivacyPolicyActionTypes.LOAD_PRIVACY_POLICY),
            switchMap(() =>
                this.service.getPrivacyPolicy().pipe(
                    map(privacyPolicy => {
                        setToStorage('privacyPolicy', privacyPolicy);
                        return new LoadPrivacyPolicySuccessAction(privacyPolicy);
                    }),
                    catchHttpError(error => of(new LoadPrivacyPolicyFailureAction({ error })))
                )
            )
        )
    );

    loadPrivacyPolicyFromStorage$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadPrivacyPolicyFromStorageAction>(
                PrivacyPolicyActionTypes.LOAD_PRIVACY_POLICY_FROM_LOCAL_STORAGE
            ),
            map(() => {
                const privacyPolicy = getFromStorage('privacyPolicy') as PrivacyPolicy;
                return new SetPrivacyPolicyAction(privacyPolicy);
            })
        )
    );

    setPrivacyPolicy$ = createEffect(() =>
        this.actions$.pipe(
            ofType<SetPrivacyPolicyAction>(PrivacyPolicyActionTypes.SET_PRIVACY_POLICY),
            tap((action: SetPrivacyPolicyAction) => {
                setToStorage('privacyPolicy', action.payload);
            })
        )
    );

    clearPrivacyPolicy$ = createEffect(() =>
        this.actions$.pipe(
            ofType<ClearPrivacyPolicyAction>(PrivacyPolicyActionTypes.CLEAR_PRIVACY_POLICY),
            tap((action: ClearPrivacyPolicyAction) => {
                withStorage(storage => storage.removeItem('privacyPolicy'));
            })
        )
    );
}
