import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    input,
    model,
    OnDestroy,
    signal,
    ViewEncapsulation
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';
import { isArray } from 'lodash-es';

@Component({
    selector: 'aix-show-more',
    templateUrl: './aix-show-more.html',
    styleUrls: ['./aix-show-more.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AixDataTestingDirective]
})
export class AixShowMoreComponent implements OnDestroy {
    title = input<string>();
    filters = input<any[]>();
    elementView = input<HTMLElement>();

    activeFilter = model.required<any[]>();

    numberShowList = signal(7);
    showMore = signal(false);
    hasMore = computed(() => {
        const nativeElement: HTMLElement = (<HTMLElement>this.elementView())
            ?.children[0] as HTMLElement;

        if (this.filters()) {
            return this.calculateTotalLines(nativeElement) > this.numberShowList();
        }

        return false;
    });
    pixelsToShow = computed(() => {
        const nativeElement: HTMLElement = (<HTMLElement>this.elementView())
            ?.children[0] as HTMLElement;

        return this.hasMore() && this.showMore()
            ? 'auto'
            : `${this.calculateMinimizedHeight(nativeElement)}px`;
    });

    showMoreSelectedNumber = 0;

    subscriptions: Subscription[] = [];

    constructor() {
        effect(() => {
            this.showSelectedNumber();
        });
    }

    showSelectedNumber() {
        this.showMoreSelectedNumber = 0;
        if (this.activeFilter() && this.filters()) {
            const activeFilter = isArray(this.activeFilter())
                ? this.activeFilter()
                : [this.activeFilter()];
            activeFilter.forEach(f => {
                const pos = this.filters()
                    ?.map(e => e.id)
                    .indexOf(f.id);
                if (pos && pos + 1 > this.numberShowList()) {
                    this.showMoreSelectedNumber++;
                }
            });
        }
    }

    change() {
        this.showMore.set(!this.showMore());
        this.showSelectedNumber();
    }

    calculateTotalLines(nativeElement: Element) {
        let totalLines = 0;
        if (nativeElement && nativeElement.childNodes && nativeElement.childNodes.length > 0) {
            nativeElement.childNodes.forEach(node => {
                if (node.nodeName === 'UL') {
                    // UL Elements are coming in with a last child node of a #comment, so this subtracts 1 from the length
                    totalLines += node.childNodes.length - 1;
                } else if (node.nodeName !== '#comment' && !!node.textContent) {
                    totalLines++;
                }
            });
        }
        return totalLines;
    }

    calculateMinimizedHeight(nativeElement: Element) {
        let maxHeight = 0;
        if (nativeElement && nativeElement.childNodes && nativeElement.childNodes.length > 0) {
            nativeElement.childNodes.forEach(node => {
                if (node.nodeName === 'UL') {
                    for (let i = 0; i < this.numberShowList(); i++) {
                        const elem = node.childNodes[i] as HTMLElement;
                        if (elem?.nodeName !== '#comment' && !!elem?.textContent) {
                            maxHeight +=
                                elem.offsetHeight +
                                parseInt(getComputedStyle(elem).marginTop) +
                                parseInt(getComputedStyle(elem).marginBottom);
                        }
                    }
                } else if (node.nodeName !== '#comment' && !!node.textContent) {
                    maxHeight =
                        (<HTMLElement>node).offsetHeight +
                        parseInt(getComputedStyle(<HTMLElement>node).marginTop) +
                        parseInt(getComputedStyle(<HTMLElement>node).marginBottom);
                }
            });
        }
        return maxHeight;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
