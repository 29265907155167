import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchHttpError } from '@trade-platform/ui-shared';
import {
    OrderSubmit,
    OrderSubmitActionTypes,
    OrderSubmitFailure,
    OrderSubmitSuccess
} from './actions';
import { OrderSubmitService } from './service';

@Injectable()
export class OrderSubmitEffects {
    private actions$ = inject(Actions);
    private orderSubmitService = inject(OrderSubmitService);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    orderSubmit$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderSubmit>(OrderSubmitActionTypes.ORDER_SUBMIT),
            switchMap(action =>
                this.orderSubmitService.orderSubmit(action.payload.orderId).pipe(
                    map(_ => new OrderSubmitSuccess()),
                    catchHttpError(error =>
                        of(
                            new OrderSubmitFailure({
                                error
                            })
                        )
                    )
                )
            )
        )
    );
}
