import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import {
    canActivateRoute,
    canLoadByRole,
    canLoadFeatureGuard,
    ExternalDocusignRedirectGuard,
    LogoutGuard,
    profileFeatures
} from '@advisor-ui/app-services';
import { constants } from '@trade-platform/ui-shared';
import { entityType, featureType, orderType } from '@trade-platform/lib-enums';

export const routes: Routes = [
    {
        path: '',
        loadComponent: () => import('./login/login.component').then(m => m.AixLoginComponent)
    },

    {
        matcher: (url: UrlSegment[]) => {
            return url.length === 1 && url[0].path.startsWith('access_token')
                ? { consumed: url }
                : null;
        },
        loadComponent: () => import('./login/login.component').then(m => m.AixLoginComponent)
    }, // Redirect from Auth0

    {
        path: 'register',
        loadComponent: () =>
            import('./register/register.component').then(m => m.AixRegisterComponent)
    },

    {
        path: 'outage',
        loadChildren: () => import('@advisor-ui/outage').then(m => m.OUTAGE_ROUTES)
    },

    {
        /**
         * Doesn't need the ProfileGuard,
         * Profile is only required after the Terms and conditions are accepted.
         */
        path: 'end-user-access-agreement',
        loadComponent: () => import('@advisor-ui/app-components').then(m => m.AixEuaaComponent)
    },

    {
        path: 'privacy-policy',
        canActivate: [canActivateRoute],
        loadComponent: () =>
            import('@advisor-ui/app-components').then(m => m.AixPrivacyPolicyComponent)
    },

    {
        path: 'accounts',
        canActivate: [canActivateRoute],
        canMatch: [canLoadByRole, canLoadFeatureGuard],
        data: {
            feature: profileFeatures.accounts,
            roles: [
                constants.profileTypes.ADVISOR,
                constants.profileTypes.ACTIVE_INVESTOR,
                constants.profileTypes.ASSISTANT,
                constants.profileTypes.ADMIN
            ]
        },
        loadChildren: () => import('@advisor-ui/accounts').then(m => m.ACCOUNTS_ROUTES)
    },

    {
        path: 'products',
        canActivate: [canActivateRoute],
        canMatch: [canLoadByRole],
        data: {
            type: orderType.buy,
            feature: profileFeatures.purchases,
            roles: [
                constants.profileTypes.ACTIVE_INVESTOR,
                constants.profileTypes.ADMIN,
                constants.profileTypes.ADVISOR,
                constants.profileTypes.ADVISOR_READ_ONLY,
                constants.profileTypes.ASSISTANT,
                constants.profileTypes.AUTHORIZER,
                constants.profileTypes.REVIEWER,
                constants.profileTypes.SIGNER,
                constants.profileTypes.SUBMITTER,
                constants.profileTypes.SYSADMIN
            ],
            tenantTypes: [entityType.firm, entityType.fundSponsor]
        },
        loadChildren: () => import('@advisor-ui/products').then(m => m.PRODUCTS_ROUTES)
    },
    {
        path: 'orders/process',
        canActivate: [canActivateRoute],
        canMatch: [canLoadByRole],
        data: {
            roles: [
                constants.profileTypes.ACTIVE_INVESTOR,
                constants.profileTypes.ADMIN,
                constants.profileTypes.ADVISOR,
                constants.profileTypes.ASSISTANT,
                constants.profileTypes.REVIEWER,
                constants.profileTypes.AUTHORIZER,
                constants.profileTypes.SIGNER
            ]
        },
        loadChildren: () => import('@advisor-ui/orders').then(m => m.ORDERS_ROUTES)
    },
    {
        path: 'orders/buy',
        canActivate: [canActivateRoute],
        canMatch: [canLoadByRole],
        data: {
            type: orderType.buy,
            feature: 'purchases',
            roles: [
                constants.profileTypes.ACTIVE_INVESTOR,
                constants.profileTypes.ADMIN,
                constants.profileTypes.ADVISOR,
                constants.profileTypes.ASSISTANT,
                constants.profileTypes.REVIEWER,
                constants.profileTypes.AUTHORIZER,
                constants.profileTypes.SIGNER
            ]
        },
        loadChildren: () => import('@advisor-ui/purchase').then(m => m.PURCHASE_ORDERS_ROUTES)
    },
    {
        path: 'orders/maintenance',
        canActivate: [canActivateRoute],
        canMatch: [canLoadByRole, canLoadFeatureGuard],
        data: {
            type: orderType.maintenance,
            feature: profileFeatures.accountMaintenance,
            roles: [
                constants.profileTypes.ACTIVE_INVESTOR,
                constants.profileTypes.ADMIN,
                constants.profileTypes.ADVISOR,
                constants.profileTypes.ASSISTANT,
                constants.profileTypes.REVIEWER,
                constants.profileTypes.AUTHORIZER,
                constants.profileTypes.SIGNER
            ]
        },
        loadChildren: () => import('@advisor-ui/maintenance').then(m => m.MAINTENANCE_ROUTES)
    },

    {
        path: 'orders/product-materials',
        canActivate: [canActivateRoute],
        canMatch: [canLoadByRole, canLoadFeatureGuard],
        data: {
            type: orderType.productMaterials,
            feature: featureType.productMaterialsOrders,
            roles: [
                constants.profileTypes.ACTIVE_INVESTOR,
                constants.profileTypes.ADMIN,
                constants.profileTypes.ADVISOR,
                constants.profileTypes.ADVISOR_READ_ONLY,
                constants.profileTypes.ASSISTANT,
                constants.profileTypes.AUTHORIZER,
                constants.profileTypes.REVIEWER,
                constants.profileTypes.SIGNER,
                constants.profileTypes.SUBMITTER,
                constants.profileTypes.SYSADMIN
            ]
        },
        loadChildren: () =>
            import('@advisor-ui/product-materials-orders').then(m => m.PRODUCT_MATERIALS_ROUTES)
    },

    {
        path: 'orders/access-request',
        loadChildren: () => import('@advisor-ui/access-request').then(m => m.ACCESS_REQUEST_ROUTES)
    },

    // Default /orders to /orders/buy
    {
        path: 'orders',
        redirectTo: 'orders/buy'
    },

    {
        path: 'products/maintenance',
        canActivate: [canActivateRoute],
        canMatch: [canLoadByRole],
        data: {
            type: orderType.maintenance,
            feature: profileFeatures.accountMaintenance,
            roles: [
                constants.profileTypes.ACTIVE_INVESTOR,
                constants.profileTypes.ADMIN,
                constants.profileTypes.ADVISOR,
                constants.profileTypes.ADVISOR_READ_ONLY,
                constants.profileTypes.ASSISTANT,
                constants.profileTypes.AUTHORIZER,
                constants.profileTypes.REVIEWER,
                constants.profileTypes.SIGNER,
                constants.profileTypes.SUBMITTER,
                constants.profileTypes.SYSADMIN
            ],
            tenantTypes: [entityType.firm, entityType.fundSponsor]
        },
        loadChildren: () =>
            import('@advisor-ui/maintenance-products').then(m => m.MAINTENANCE_PRODUCTS_ROUTES)
    },

    {
        path: 'admin',
        canActivate: [canActivateRoute],
        canMatch: [canLoadByRole],
        data: { roles: [constants.profileTypes.SYSADMIN] },
        loadChildren: () => import('@advisor-ui/admin').then(m => m.ADMIN_ROUTES)
    },

    {
        path: 'profile',
        canActivate: [canActivateRoute],
        loadChildren: () => import('@advisor-ui/profile').then(m => m.PROFILE_ROUTES)
    },

    {
        path: 'logout',
        canActivate: [LogoutGuard],
        loadComponent: () => import('./logout/logout').then(m => m.AixLogoutComponent)
    },

    {
        path: 'externalDocusignRedirect',
        canActivate: [ExternalDocusignRedirectGuard],
        loadComponent: () =>
            import('@advisor-ui/app-components').then(m => m.AixPageNotFoundComponent)
    },

    {
        path: '**',
        canActivate: [canActivateRoute],
        loadComponent: () =>
            import('@advisor-ui/app-components').then(m => m.AixPageNotFoundComponent)
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true,
            enableTracing: false,
            paramsInheritanceStrategy: 'always'
        })
    ],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule {}
