import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchHttpError } from '@trade-platform/ui-shared';
import {
    OrderMakeChangesAction,
    OrderMakeChangesActionTypes,
    OrderMakeChangesFailureAction,
    OrderMakeChangesSuccessAction
} from './actions';
import { OrderMakeChangesService } from './service';

@Injectable()
export class OrderMakeChangesEffects {
    private actions$ = inject(Actions);
    private service = inject(OrderMakeChangesService);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    orderMakeChanges$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderMakeChangesAction>(OrderMakeChangesActionTypes.ORDER_MAKE_CHANGES),
            switchMap(action =>
                this.service.orderMakeChanges(action.payload.orderId).pipe(
                    map(res => new OrderMakeChangesSuccessAction(res)),
                    catchHttpError(error => of(new OrderMakeChangesFailureAction({ error })))
                )
            )
        )
    );
}
