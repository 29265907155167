import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { investorDecoder } from '../../decoders/investor/decoder';
import { Investor } from '../../models/investor/model';

@Injectable()
export class InvestorService {
    private http = inject(HttpClient);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    getInvestorByTaxId(
        taxId: string,
        personTypeFilter: 'corporation' | 'human'
    ): Observable<Investor> {
        return this.http
            .post<Investor>(
                `${this.environment.api}investors/tid?personTypeFilter=${personTypeFilter}`,
                { taxId }
            )
            .pipe(
                concatMap(p => {
                    return fromPromise(investorDecoder.decodeToPromise(p));
                })
            );
    }
}
