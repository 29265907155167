import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'aix-button',
    templateUrl: './aix-button.component.html',
    styleUrls: ['./aix-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [],
    host: {
        '[class.aix-button__disabled]': 'isDisabled()',
        '[class]': 'buttonType()',
        '[attr.disabled]': "isDisabled() ? '' : null"
    }
})
export class AixButtonComponent {
    isDisabled = input<boolean | undefined | null>(false);
    buttonType = input<BUTTON_TYPE>(BUTTON_TYPE.primary);
    buttonLabel = input<string | undefined>();
    icon = input<string>();
    iconRight = input<string>();
}

export enum BUTTON_TYPE {
    primary = 'aix-btn aix-btn--primary br-btn--primary br-btn',
    secondary = 'aix-btn aix-btn--secondary br-btn--secondary',
    tertiaryInverted = 'aix-btn aix-btn--tertiary-inverted',
    link = 'aix-btn aix-btn--link br-link',
    linkBold = 'aix-btn aix-btn--link br-link u-fw500'
}
