import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
    OrderApprove,
    OrderApproveActionTypes,
    OrderApproveFailure,
    OrderApproveResume,
    OrderApproveResumeFailure,
    OrderApproveResumeSuccess,
    OrderApproveStart,
    OrderApproveStartFailure,
    OrderApproveStartSuccess,
    OrderApproveSuccess
} from './actions';
import { OrderApproveService } from './service';
import { catchHttpError } from '@trade-platform/ui-shared';

@Injectable()
export class OrderApproveEffects {
    private actions$ = inject(Actions);
    private orderApproveService = inject(OrderApproveService);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    orderApproveStart$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderApproveStart>(OrderApproveActionTypes.ORDER_APPROVE_START),
            switchMap(action =>
                this.orderApproveService.orderApproveStart(action.payload.orderId).pipe(
                    map(res => new OrderApproveStartSuccess()),
                    catchHttpError(error => of(new OrderApproveStartFailure({ error })))
                )
            )
        )
    );

    orderApproveResume$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderApproveResume>(OrderApproveActionTypes.ORDER_APPROVE_RESUME),
            switchMap(action =>
                this.orderApproveService.orderApproveResume(action.payload.orderId).pipe(
                    map(res => new OrderApproveResumeSuccess()),
                    catchHttpError(error => of(new OrderApproveResumeFailure({ error })))
                )
            )
        )
    );

    orderApprove$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderApprove>(OrderApproveActionTypes.ORDER_APPROVE),
            switchMap(action =>
                this.orderApproveService
                    .orderApprove(
                        action.payload.orderId,
                        action.payload.approvalId,
                        action.payload.body
                    )
                    .pipe(
                        map(res => new OrderApproveSuccess(res)),
                        catchHttpError(error => of(new OrderApproveFailure({ error })))
                    )
            )
        )
    );
}
