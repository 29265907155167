import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { Order, orderDecoder, SettlementDetails } from '@trade-platform/ui-shared';

@Injectable()
export class SettlementDetailsService {
    private http = inject(HttpClient);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    update(orderId: string, settlementDetails: SettlementDetails): Observable<Order> {
        return this.http
            .patch<Order>(
                `${this.environment.api}orders/${orderId}/settlementDetails`,
                settlementDetails
            )
            .pipe(concatMap(p => fromPromise(orderDecoder.decodeToPromise(p))));
    }
}
