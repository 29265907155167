import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import {
    OrderFormComment,
    OrderFormCommentCreatePayload,
    orderFormCommentDecoder,
    OrderFormCommentListPayload,
    OrderFormCommentRequestPayload,
    OrderFormCommentResolvePayload,
    orderFormCommentsDecoder,
    OrderFormCommentUpdatePayload
} from '@trade-platform/ui-shared';
import { isBoolean } from 'lodash-es';

@Injectable()
export class OrderFormCommentService {
    private http = inject(HttpClient);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    getOrderFormComment(payload: OrderFormCommentRequestPayload): Observable<OrderFormComment> {
        return this.http
            .get<OrderFormComment>(
                `${this.environment.api}orders/${payload.orderId}/documents/${payload.documentId}/comments/${payload.id}`
            )
            .pipe(concatMap(p => fromPromise(orderFormCommentDecoder.decodeToPromise(p))));
    }

    getOrderFormComments(payload: OrderFormCommentListPayload): Observable<OrderFormComment[]> {
        return this.http
            .get<OrderFormComment[]>(
                `${this.environment.api}orders/${payload.data.orderId}/comments`
            )
            .pipe(concatMap(p => fromPromise(orderFormCommentsDecoder.decodeToPromise(p))));
    }

    addOrderFormComment(payload: OrderFormCommentCreatePayload): Observable<OrderFormComment> {
        return this.http
            .post<OrderFormComment>(
                `${this.environment.api}orders/${payload.data.orderId}/documents/${payload.data.documentId}/comments`,
                {
                    text: payload.text,
                    commentType: payload.commentType,
                    commentPrivate: isBoolean(payload.commentPrivate)
                        ? payload.commentPrivate
                        : undefined,
                    documentName: payload.documentName,
                    commentTag: payload.commentTag,
                    parentId: payload.parentId
                }
            )
            .pipe(concatMap(p => fromPromise(orderFormCommentDecoder.decodeToPromise(p))));
    }

    deleteOrderFormComment(payload: OrderFormCommentRequestPayload): Observable<any> {
        return this.http.delete(
            `${this.environment.api}orders/${payload.orderId}/documents/${payload.documentId}/comments/${payload.id}`
        );
    }

    updateOrderFormComment(payload: OrderFormCommentUpdatePayload): Observable<OrderFormComment> {
        return this.http
            .put<OrderFormComment>(
                `${this.environment.api}orders/${payload.data.orderId}/documents/${payload.data.documentId}/comments/${payload.data.id}`,
                {
                    text: payload.text,
                    commentTag: payload.commentTag,
                    commentPrivate: isBoolean(payload.commentPrivate)
                        ? payload.commentPrivate
                        : undefined
                }
            )
            .pipe(concatMap(p => fromPromise(orderFormCommentDecoder.decodeToPromise(p))));
    }

    resolveOrderComment(payload: OrderFormCommentResolvePayload): Observable<OrderFormComment> {
        return this.http
            .post<OrderFormComment>(
                `${this.environment.api}orders/comments/${payload.data.id}/resolve`,
                {
                    isResolved: payload.isResolved
                }
            )
            .pipe(concatMap(p => fromPromise(orderFormCommentDecoder.decodeToPromise(p))));
    }
}
