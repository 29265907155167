<div class="inline-dropdown inline-dropdown__remove">
    <button
        [disabled]="disabled()"
        [ngStyle]="styles().links"
        [class]="buttonStyle()"
        class="aix-btn aix-btn--link br-link inline-dropdown__menu-button"
        [data-testing]="'manage-button'"
        type="button"
    >
        <span [class]="icon()"></span>{{ label() }}
    </button>
    @if (!disabled()) {
    <ul
        class="inline-dropdown-menu aix-flex-grid aix-flex-grid__col"
        [ngClass]="{ 'inline-dropdown-menu--right': rightAlign() }"
    >
        @for (element of dropdownOptions(); track element) {
        <li>
            <button
                class="aix-btn aix-btn--link br-link"
                [attr.data-testing]="element.toLowerCase() + '-button'"
                (click)="optionClicked(element)"
                [ngStyle]="styles().links"
                [disabled]="disabledOptions().includes(element)"
                type="button"
            >
                {{ element }}
            </button>
        </li>
        }
    </ul>
    }
</div>
