import { Injectable, inject } from '@angular/core';
import { OrderFormCommentsPipe } from './order-form-comments-pipe';
import { OrderFormComment } from '@trade-platform/ui-shared';
import { commentTag } from '@trade-platform/lib-enums';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../base.orders.store.facade';

@Injectable()
export class OrderFormCommentsService {
    private orderFormCommentsPipe = inject(OrderFormCommentsPipe);
    storeFacade = inject<BaseOrdersStoreFacade>(ORDERS_STORE_FACADE);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    getComments(id: string | null): OrderFormComment[] {
        const orderFormComments = this.storeFacade.orderFormComments;
        if (id && orderFormComments && orderFormComments.length > 0) {
            return this.orderFormCommentsPipe.transform(
                orderFormComments,
                false,
                false,
                [id],
                commentTag.change
            );
        }
        return [];
    }
}
