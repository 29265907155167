import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AixFootnoteService } from './aix-footnote.service';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'aix-footnotes',
    templateUrl: './aix-footnotes.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AsyncPipe]
})
export class AixFootnotesContainerComponent {
    footnoteService = inject(AixFootnoteService);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    returnToFootnote(footnoteId: string) {
        const el = document.getElementById('aix-footnote-' + footnoteId);
        if (el !== null) {
            el.scrollIntoView({ behavior: 'smooth' });
        }
    }
}
