import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    input,
    OnInit,
    output,
    inject
} from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { NgStyle } from '@angular/common';

@Component({
    selector: 'aix-image-viewer',
    templateUrl: './aix-image-viewer.component.html',
    styleUrls: ['./aix-image-viewer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgStyle]
})
export class AixImageViewerComponent implements OnInit {
    private ref = inject(ElementRef);
    private cd = inject(ChangeDetectorRef);

    src = input<string | SafeUrl>();
    loadImage = output();

    containerStyles: any = {};
    imageStyles: any = {};

    height: number;
    canvasHeight = { height: 'auto' };
    rowWidth = 300;
    rowHeight = 300;
    scaleRatio = 1;
    rotation = 0;

    viewport: any;
    itemContainer: any;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    ngOnInit() {
        this.itemContainer = this.ref.nativeElement.querySelector('.canvas');
        this.rowWidth = this.ref.nativeElement.getBoundingClientRect().width;
        this.rowHeight = this.ref.nativeElement.getBoundingClientRect().height;
    }

    onLoadImage(event: any) {
        this.rotation = 0;
        this.loadImage.emit();
        this.viewport = { width: event.target.naturalWidth, height: event.target.naturalHeight };
        this.fit();
    }

    initDisplayList() {
        const rowHeight = this.isPortrait() ? this.rowHeight : this.rowWidth;
        this.height = this.ref.nativeElement.clientHeight;

        const mt = 0;
        const ml = 0;

        this.imageStyles = {
            transform: `rotate(${this.rotation}deg)`,
            'margin-top': `${mt}px`,
            'margin-left': `${ml}px`
        };

        this.canvasHeight = {
            height: rowHeight + 25 + 6 + 'px'
        };

        if (this.height > 0) {
            this.updateDisplayList();
        }

        this.cd.detectChanges();
    }

    updateDisplayList() {
        const rowHeight = this.isPortrait() ? this.rowHeight : this.rowWidth;
        const rowWidth = this.isPortrait() ? this.rowWidth : this.rowHeight;

        this.containerStyles.styles = {
            height: `${rowHeight + 24}px`,
            width: `${rowWidth}px`
        };
    }

    zoomIn() {
        this.scaleRatio =
            (this.ref.nativeElement.getBoundingClientRect().width - 25) / this.viewport.width;
        const maxHeight: number = this.viewport.height * this.scaleRatio * 3;

        if (this.isPortrait()) {
            this.rowWidth += 50 * (this.viewport.width / this.viewport.height);
            this.rowHeight += 50;
        } else {
            this.rowWidth += 50;
            this.rowHeight += 50 * (this.viewport.height / this.viewport.width);
        }

        this.ref.nativeElement.scrollTop += 50;

        if (this.rowHeight > maxHeight) {
            this.rowHeight = maxHeight;
        }

        this.initDisplayList();
    }

    zoomOut() {
        this.scaleRatio =
            (this.ref.nativeElement.getBoundingClientRect().width - 25) / this.viewport.width;

        if (this.isPortrait()) {
            this.rowHeight -= 50;
            this.rowWidth -= 50 * (this.viewport.width / this.viewport.height);

            if (this.rowHeight < 200) {
                this.rowHeight = 200;
                this.rowWidth = 200 * (this.viewport.width / this.viewport.height);
            }
        } else {
            this.rowWidth -= 50;
            this.rowHeight -= 50 * (this.viewport.height / this.viewport.width);

            if (this.rowHeight < 200) {
                this.rowWidth = 200;
                this.rowHeight = 200 * (this.viewport.height / this.viewport.width);
            }
        }

        this.ref.nativeElement.scrollTop -= 50;

        this.initDisplayList();
    }

    rotate() {
        this.rotation += 90;

        if (this.rotation === 360) {
            this.rotation = 0;
        }

        this.fit();
    }

    fit() {
        if (this.viewport) {
            const scaleRatioW =
                (this.ref.nativeElement.getBoundingClientRect().width - 25) / this.viewport.width;

            if (this.isPortrait()) {
                // Fit portrait
                this.scaleRatio = scaleRatioW;
                this.rowWidth = this.viewport.width * this.scaleRatio;
                this.rowHeight = this.viewport.height * this.scaleRatio;
                this.initDisplayList();
            } else {
                // Fit landscape
                this.scaleRatio = (this.viewport.width * scaleRatioW) / this.viewport.height;
                this.rowWidth = this.viewport.width * this.scaleRatio;
                this.rowHeight = this.viewport.width * scaleRatioW;
                this.initDisplayList();
            }
        }
    }

    isPortrait() {
        return this.rotation === 0 || this.rotation === 180;
    }
}
