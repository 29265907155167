import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { OrderCancelEnvelopeRequest } from '@trade-platform/ui-shared';

@Injectable()
export class OrderCancelEnvelopeService {
    private http = inject(HttpClient);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    orderCancelEnvelopeService(payload: OrderCancelEnvelopeRequest): Observable<boolean> {
        return this.http.post<boolean>(
            `${this.environment.api}orders/${payload.orderId}/cancelEnvelope`,
            {
                voidedReason: payload.voidedReason
            }
        );
    }
}
