import { Injectable, inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
    AixAction,
    AixEffectActions,
    AppState,
    CloseSharedRepCodesGridNotification,
    DocumentViewerHideNotification,
    DocumentViewerSetDocument,
    DocumentViewerSetForm,
    DocumentViewerSetOffline,
    DocumentViewerSetPermissions,
    DocumentViewerShowNotification,
    OpenSharedRepCodesGridNotification,
    OpenUnshareRepCodeModal
} from '@trade-platform/ui-shared';
import { extractStreamValue } from '@trade-platform/ui-utils';

@Injectable({ providedIn: 'root' })
export class ComponentsStoreFacade {
    store = inject<Store<AppState>>(Store);
    private actions$ = inject(AixEffectActions);

    /**
     * Document Viewer
     */
    readonly documentViewerActions = {
        showNotification: {
            success$: this.actions$.ofClass(DocumentViewerShowNotification)
        },
        hideNotification: {
            success$: this.actions$.ofClass(DocumentViewerHideNotification)
        },
        setDocument: {
            success$: this.actions$.ofClass(DocumentViewerSetDocument)
        },
        setForm: {
            success$: this.actions$.ofClass(DocumentViewerSetForm)
        },
        setOffline: {
            success$: this.actions$.ofClass(DocumentViewerSetOffline)
        },
        setPermissions: {
            success$: this.actions$.ofClass(DocumentViewerSetPermissions)
        }
    };
    readonly documentViewer$ = this.store.pipe(select(state => state.documentViewer));

    get documentViewer() {
        return extractStreamValue(this.documentViewer$);
    }

    /**
     * Shared Rep Codes Grid
     */
    readonly sharedRepCodesGridActions = {
        openNotification: {
            success$: this.actions$.ofClass(OpenSharedRepCodesGridNotification)
        },
        closeNotification: {
            success$: this.actions$.ofClass(CloseSharedRepCodesGridNotification)
        },
        openUnshareRepCodeModal: {
            success$: this.actions$.ofClass(OpenUnshareRepCodeModal)
        }
    };
    readonly sharedRepCodesGrid$ = this.store.pipe(select(state => state.sharedRepCodesGrid));
    get sharedRepCodesGrid() {
        return extractStreamValue(this.sharedRepCodesGrid$);
    }

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    dispatch(action: AixAction) {
        this.store.dispatch(action);
    }
}
