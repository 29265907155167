import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { planDecoder } from '../../decoders/plan/decoder';
import { Plan } from '../../models/plan/model';

@Injectable()
export class PlanService {
    private http = inject(HttpClient);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    getPlanByTaxId(taxId: string, planNumber: string): Observable<Plan> {
        return this.http
            .get<Plan>(
                `${this.environment.api}planFilings?sponsorEin=${taxId}&planNumber=${planNumber}`
            )
            .pipe(
                concatMap(p => {
                    return fromPromise(planDecoder.decodeToPromise(p));
                })
            );
    }
}
