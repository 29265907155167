import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
    DocumentValueActionTypes,
    LoadDocumentValuesAction,
    LoadDocumentValuesFailureAction,
    LoadDocumentValuesSuccessAction
} from './actions';
import { DocumentValuesService } from './service';
import { catchHttpError } from '@trade-platform/ui-shared';

@Injectable()
export class DocumentValuesEffects {
    private actions$ = inject(Actions);
    private service = inject(DocumentValuesService);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    loadDocumentValues$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadDocumentValuesAction>(DocumentValueActionTypes.LOAD_DOCUMENT_VALUES),
            switchMap(action =>
                this.service.loadDocumentValues(action.payload).pipe(
                    map(values => new LoadDocumentValuesSuccessAction(values)),
                    catchHttpError(error => of(new LoadDocumentValuesFailureAction({ error })))
                )
            )
        )
    );
}
