import { ChangeDetectionStrategy, Component, input, OnChanges, SimpleChanges } from '@angular/core';
import { Column, GridApi } from 'ag-grid-community';

@Component({
    selector: 'aix-grid-columns',
    templateUrl: './grid-columns.html',
    styleUrls: ['./grid-columns.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: []
})
export class AixGridColumnsComponent implements OnChanges {
    gridApi = input<GridApi>();
    columns: Column[] = [];
    isColumnListVisible = false;

    toggleColumList() {
        this.isColumnListVisible = !this.isColumnListVisible;
    }

    toggleColumVisibility(col: Column) {
        this.gridApi()?.setColumnsVisible([col.getColId()], !col.isVisible());
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['columnApi'].currentValue) {
            // this.columnApi.getAllColumns() => TS2531: Object is possibly 'null'.
            this.columns = (this.gridApi()?.getAllGridColumns() ?? []).filter(
                c => !c.getColDef().lockVisible
            );
        }
    }
}
