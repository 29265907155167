<ul class="filter-list">
    @for (filter of filters(); track filter.id) {
    <li
        class="br-primary__color--hover"
        [data-testing]="'filter-list-item-' + filter.id"
        [class.br-link]="isActiveFilter(filter?.id)"
        [class.u-fw600]="isActiveFilter(filter?.id) && boldSelection()"
        (mousedown)="selectFilter(filter)"
    >
        <label class="checkbox-custom checkbox-custom-label-size u-mr0 u-mt2">
            <input
                type="checkbox"
                [data-testing]="'filter-list-checkbox'"
                (click)="$event.defaultPrevented"
                [checked]="isActiveFilter(filter?.id)"
            />
            <span
                [class.checkbox-custom__indicator-partial]="hasPartialSelect() && !isActiveFilter(filter?.id) && filter?.id === 'all' && activeFilter().length > 0"
                class="checkbox-custom__indicator"
            ></span>
        </label>
        <span
            class="filter-list-label"
            [data-testing]="'filter-list-label-' + filter.id"
            [class.br-primary__color]="isActiveFilter(filter?.id)"
        >
            {{ filter.label }} @if (filter.tooltip) {
            <span aixTooltip [aixTooltipContent]="filter.tooltip"></span>
            }
        </span>
    </li>
    }
</ul>
