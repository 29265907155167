import { SafeHtmlPipe } from '@advisor-ui/app-components';
import { profileHasRoles, ProfileStoreFacade } from '@advisor-ui/app-services';
import { AsyncPipe, CurrencyPipe, TitleCasePipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    OnDestroy,
    OnInit,
    viewChild,
    ViewEncapsulation,
    inject
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { orderStatus, orderStatusIndex, orderType } from '@trade-platform/lib-enums';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    AixFootnoteDirective,
    AixFootnotesContainerComponent,
    AixLoadingComponent,
    AixModalComponent,
    AixNotificationComponent,
    AixPageHeaderComponent,
    AixPageSectionComponent,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import {
    AixErrorBoxComponent,
    BaseOrder,
    constants,
    Order,
    OrderForm,
    OrderStep,
    TitleService
} from '@trade-platform/ui-shared';
import { scrollToTop } from '@trade-platform/ui-utils';
import { isInProgress, RemoteData, RemoteDataModule } from 'ngx-remotedata';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { getLanguage } from '../../../../../language/language.base';
import { OrderOverview } from '../../../../../language/language.interface';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../../base.orders.store.facade';
import {
    CONFIRM_ORDER_CANCELLATION_MSG,
    CONFIRM_ORDER_CANCELLATION_MSG_SUB,
    CONFIRM_ORDER_CANCELLATION_TITLE,
    ERROR_CANCEL_ORDER_MESSAGE,
    ERROR_CONTACT
} from '../../constants';
import { getFilesToDisplay } from '../../order-document-viewer/document-viewer/document-viewer.helper';
import { hasReadOnlyAccess } from '../../utils';
import { FsFriendsAndFamilyFundsUIData } from './document-links/fs-friends-family-ui';
import { AixMakeChangesComponent } from './docusign/make-changes/make-changes';
import { AixOrderStepsComponent } from './steps/order-steps';

export type DetailViewState = 'preview' | 'wet-sign' | 'docu-sign' | 'map-signatures';

@Component({
    selector: 'aix-order-overview',
    templateUrl: './overview.html',
    styleUrls: ['./overview.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AixPageHeaderComponent,
        AixLoadingComponent,
        AixErrorBoxComponent,
        AixNotificationComponent,
        AixPageSectionComponent,
        AixButtonComponent,
        AixDataTestingDirective,
        AixOrderStepsComponent,
        LetDirective,
        AixFootnoteDirective,
        AixFootnotesContainerComponent,
        AixModalComponent,
        AixMakeChangesComponent,
        AsyncPipe,
        TitleCasePipe,
        CurrencyPipe,
        RemoteDataModule,
        SafeHtmlPipe
    ]
})
export class AixOrdersOverviewComponent implements OnInit, OnDestroy {
    storeFacade = inject<BaseOrdersStoreFacade>(ORDERS_STORE_FACADE);
    route = inject(ActivatedRoute);
    private profileStoreFacade = inject(ProfileStoreFacade);
    private router = inject(Router);
    private titleService = inject(TitleService);
    private ref = inject(ChangeDetectorRef);

    @HostBinding('class.host-flex')
    flex = true;

    cancelOrderConfirmModal = viewChild<AixModalComponent>('cancelOrderConfirmModal');
    makeChangesConfirmModal = viewChild<AixMakeChangesComponent>('makeChangesConfirmModal');

    documentLinks: { [key: string]: { [key: string]: string } } = FsFriendsAndFamilyFundsUIData;

    states: Observable<RemoteData<any, any>>[];
    isLoading$: Observable<boolean>;

    buyOrder$: Observable<Order>;

    fundSponsorLogoNotFound = false;
    orderId = '';
    forms: { [name: string]: OrderForm };
    currentDocument = 0;
    subscriptions: Subscription[] = [];

    currentStep: OrderStep;

    orderType = orderType;
    isReadOnly: boolean | undefined | null = false;
    isOrderAdmin: boolean | undefined | null = false;

    language: OrderOverview = getLanguage('orderOverview');
    breadcrumbText = this.language
        ? this.language.breadcrumbs[this.storeFacade.type]
        : this.storeFacade.type;

    readonly reducerSuffix = this.storeFacade.type;
    readonly updateOrderReducerSuffix = 'orderUpdate';
    readonly footnote =
        'Amount is subject to change and represents the most recent information made available to the AIX Platform. For more information, please contact the issuer.';
    CONFIRM_ORDER_CANCELLATION_TITLE = CONFIRM_ORDER_CANCELLATION_TITLE;
    CONFIRM_ORDER_CANCELLATION_MSG = CONFIRM_ORDER_CANCELLATION_MSG;
    CONFIRM_ORDER_CANCELLATION_MSG_SUB = CONFIRM_ORDER_CANCELLATION_MSG_SUB;
    ERROR_CANCEL_ORDER_MESSAGE = ERROR_CANCEL_ORDER_MESSAGE;
    ERROR_CONTACT = ERROR_CONTACT;

    cancelOrderButtonType = BUTTON_TYPE.link;
    editOrderButtonType = BUTTON_TYPE.secondary;
    viewOrderButtonType = BUTTON_TYPE.secondary;
    viewOrderDetailsButtonType = BUTTON_TYPE.link;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        const storeFacade = this.storeFacade;

        this.titleService.setTitle('Orders');
        this.storeFacade.actions.resetOrder.dispatch({ reducerSuffix: this.reducerSuffix });
        this.breadcrumbText = this.breadcrumbText ?? '';

        this.buyOrder$ = storeFacade.orderSuccess$ as Observable<Order>;

        this.states = [
            this.storeFacade.orderRemoteData$,
            this.storeFacade.orderMakeChangesRemoteData$,
            this.storeFacade.orderFormsCompleteRemoteData$,
            this.storeFacade.orderUpdateFilesRemoteData$,
            this.storeFacade.orderFinalizeRemoteData$,
            this.storeFacade.orderSubmitRemoteData$,
            this.storeFacade.orderApproveRemoteData$,
            this.storeFacade.orderDocumentDeclineRemoteData$,
            this.storeFacade.orderDocumentUndeclineRemoteData$,
            this.storeFacade.documentTypesRemoteData$,
            this.storeFacade.orderFormCommentsRemoteData$,
            this.storeFacade.orderDocumentRemoveRemoteData$,
            this.storeFacade.orderSigningCompleteRemoteData$,
            this.storeFacade.orderSigningMethodRemoteData$,
            this.storeFacade.orderStartReviewRemoteData$,
            this.storeFacade.orderCancelReviewRemoteData$,
            this.storeFacade.orderReviewCompleteRemoteData$,
            this.storeFacade.orderMapSignaturesRemoteData$,
            this.storeFacade.orderFinishMapSignaturesRemoteData$
        ];

        this.isLoading$ = combineLatest([
            this.storeFacade.orderRemoteData$,
            this.storeFacade.orderMakeChangesRemoteData$,
            this.storeFacade.orderFormsCompleteRemoteData$,
            this.storeFacade.orderUpdateFilesRemoteData$,
            this.storeFacade.orderFinalizeRemoteData$,
            this.storeFacade.orderDocusignSendRemoteData$,
            this.storeFacade.orderSubmitRemoteData$,
            this.storeFacade.orderCancelRemoteData$,
            this.storeFacade.orderApproveRemoteData$,
            this.storeFacade.orderDocumentDeclineRemoteData$,
            this.storeFacade.orderDocumentUndeclineRemoteData$,
            this.storeFacade.documentTypesRemoteData$,
            this.storeFacade.orderFormCommentsRemoteData$,
            this.storeFacade.orderDocumentRemoveRemoteData$,
            this.storeFacade.orderSigningCompleteRemoteData$,
            this.storeFacade.orderSigningMethodRemoteData$,
            this.storeFacade.orderStartReviewRemoteData$,
            this.storeFacade.orderCancelReviewRemoteData$,
            this.storeFacade.orderReviewCompleteRemoteData$,
            this.storeFacade.orderMapSignaturesRemoteData$,
            this.storeFacade.orderFinishMapSignaturesRemoteData$
        ]).pipe(map(states => states.some(isInProgress)));
    }

    ngOnInit() {
        scrollToTop();

        this.subscriptions.push(
            this.route.params.subscribe(params => {
                this.orderId = params['orderId'];
                this.storeFacade.actions.loadDocumentTypes.dispatch(this.orderId);
            }),
            this.storeFacade.actions.cancel.success$.subscribe(action => {
                this.router.navigate(this.storeFacade.routes.status());
            }),
            this.storeFacade.orderSteps$.subscribe((step: { currentStep: OrderStep }) => {
                this.currentStep = step.currentStep;
                this.ref.detectChanges();
            }),
            this.storeFacade.actions.showMakeChangesModal.success$.subscribe(_ => {
                this.onEditOrder();
            }),
            this.storeFacade.orderSigningCompleteSuccess$.subscribe(action => {
                this.loadOrder();
            }),
            this.storeFacade.orderSuccess$.subscribe(order => {
                const profile = this.profileStoreFacade.profile;
                this.isReadOnly = hasReadOnlyAccess(profile, order.firm.id);
                this.isOrderAdmin = profileHasRoles(
                    [{ name: constants.profileTypes.ORDER_ADMIN }],
                    profile
                );
            })
        );
    }

    loadOrder(): void {
        this.storeFacade.actions.getOrder.dispatch({
            orderId: this.orderId,
            reducerSuffix: this.reducerSuffix
        });
    }

    cancelOrderConfirmModalSelected(e: string) {
        switch (e) {
            case 'Yes, continue':
                this.storeFacade.actions.cancel.dispatch({ orderId: this.orderId });
                break;
            default:
                break;
        }
    }

    cancelOrder() {
        this.cancelOrderConfirmModal()?.openModal();
    }

    editOrder() {
        this.storeFacade.actions.makeChanges.dispatch({ orderId: this.orderId });
    }

    onEditOrder() {
        const order = this.storeFacade.order;
        if (
            order &&
            (orderStatusIndex[order.status] <= orderStatusIndex[orderStatus.readyToSend] ||
                !order.envelopeId ||
                order.envelopeId === '')
        ) {
            this.editOrder();
        } else {
            this.makeChangesConfirmModal()?.open();
        }
    }

    checkOrderNeedsAction(order: BaseOrder): boolean {
        if (order) {
            return (
                order.status === orderStatus.nigo || order.status === orderStatus.needsClarification
            );
        }
        return false;
    }

    viewOrder(docId: string | null = null) {
        const order = this.storeFacade.order;
        const filesToDisplay = getFilesToDisplay(order);
        const id = docId ? docId : filesToDisplay[0].id;

        this.router.navigate(this.storeFacade.routes.documentViewer(this.orderId, id)).then(() => {
            // Once the routing is finished, override the default active "Forms" tab to "Comments" if the order needs action;
            if (this.checkOrderNeedsAction(order)) {
                this.storeFacade.actions.documentViewerSetTab.dispatch({ tab: 'Comments' });
            }
        });
    }

    viewOrderOverview() {
        this.router.navigate(this.storeFacade.routes.detailOverview(this.orderId));
    }

    getFundDocumentLinks(order: BaseOrder | null) {
        if (order) {
            if (order.fund.ticker) {
                return this.documentLinks[order.fund.ticker];
            }
        }
        return null;
    }

    ngOnDestroy() {
        this.storeFacade.actions.finalizeOrder.reset();
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
