import { inject, Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateFn,
    CanMatchFn,
    Data,
    Route,
    Router
} from '@angular/router';
import { AixErrorAction, ErrorWrapper, isFeatureEnabled, Profile } from '@trade-platform/ui-shared';
import { getFromStorage, LogService } from '@trade-platform/ui-utils';
import { Logger } from 'typescript-logging';
import { Store } from '@ngrx/store';

/*
 * Checks route feature against user's features
 */

@Injectable()
export class FeatureGuard {
    router = inject(Router);
    private logService = inject(LogService);
    private store = inject(Store);

    readonly LOG: Logger;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);
    constructor() {
        this.LOG = this.logService.getLogger('app.guards.feature-guard');
    }

    checkFeatureName(featureName: string, path?: string) {
        if (!featureName || featureName.length === 0) {
            this.LOG.debug(`No feature specified on route`);
            return false;
        }

        const isFeatureEnabledResult = isFeatureEnabled(featureName);

        if (!isFeatureEnabledResult) {
            const profile = getFromStorage('profile') as Profile;
            const message = path
                ? `User ${profile?.email} tried to access to "/${path}" but feature "${featureName}" is not available.`
                : `User ${profile?.email} tried to access to a route but feature "${featureName}" is not available.`;

            this.store.dispatch(new AixErrorAction({ error: new ErrorWrapper(message) }));
        }

        return isFeatureEnabledResult;
    }

    canLoad(route: Route): boolean {
        return this.checkFeatureName((route.data as Data).feature, route.path);
    }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        return this.checkFeatureName(route.data.feature);
    }
}

export const canLoadFeatureGuard: CanMatchFn = (route: Route) => {
    return inject(FeatureGuard).canLoad(route);
};

export const canActivateFeatureGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
    return inject(FeatureGuard).canActivate(route);
};
