import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { GRID_PAGE_SIZE } from '@advisor-ui/app-components';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../../base.orders.store.facade';
import { OrdersStatusHistory } from '@trade-platform/ui-shared';
import { format } from 'date-fns';
import { TitleCasePipe, AsyncPipe } from '@angular/common';
import { sanitizeHtml } from '@trade-platform/ui-utils';
import { AixStatusHistoryPipe } from './status-history-transform';
import { RemoteDataModule } from 'ngx-remotedata';
import {
    AixDataGridComponent,
    AixDataTestingDirective,
    AixHeaderSectionComponent,
    AixPaginationComponent
} from '@trade-platform/ui-components';
@Component({
    selector: 'aix-orders-detail-status',
    templateUrl: './status.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AixHeaderSectionComponent,
        AixDataGridComponent,
        AixDataTestingDirective,
        AixPaginationComponent,
        AsyncPipe,
        RemoteDataModule,
        AixStatusHistoryPipe
    ]
})
export class AixOrdersDetailStatusComponent implements OnInit, OnDestroy {
    route = inject(ActivatedRoute);
    router = inject(Router);
    store = inject<BaseOrdersStoreFacade>(ORDERS_STORE_FACADE);

    orderId: string;

    subscriptions: Subscription[] = [];

    gridOptions: GridOptions;
    gridApi: GridApi;
    rowHeight = 48;

    data: OrdersStatusHistory[] = [];

    public readonly paginationPageSize = GRID_PAGE_SIZE;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    ngOnInit() {
        this.setupGrid();

        this.subscriptions.push(
            (this.route.parent as ActivatedRoute).params.subscribe(params => {
                this.orderId = params['orderId'];
                this.store.actions.loadOrderStatusHistory.dispatch({ orderId: this.orderId });
            }),
            this.store.orderStatusHistoryFailure$.subscribe(a => console.log('error', a))
        );
    }

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.gridApi.refreshCells();
    }

    setupGrid() {
        interface DateValue {
            value: string;
        }

        interface StringValue {
            value: string;
        }

        const defaultEmptyRenderer = (data: StringValue) => {
            if (data && data.value) {
                return sanitizeHtml(data.value);
            } else {
                return '&mdash;';
            }
        };

        const dateRenderer = (date: DateValue) => {
            if (date && date.value) {
                return format(new Date(date.value), 'MM/d/yyyy hh:mm a');
            } else {
                return '&mdash;';
            }
        };

        const columnDefs: ColDef[] = [
            {
                headerName: 'Status',
                field: 'status',
                width: 280,
                sortable: true
            },
            {
                headerName: 'Company',
                field: 'updatedByOrganization',
                width: 230,
                sortable: true,
                cellRenderer: defaultEmptyRenderer
            },
            {
                headerName: 'User',
                field: 'updatedBy',
                width: 185,
                sortable: true,
                cellRenderer: defaultEmptyRenderer
            },
            {
                headerName: 'Entity Type',
                field: 'updatedByType',
                width: 120,
                sortable: true,
                cellRenderer: (params: { value: string }) => {
                    if (params && params.value) {
                        return sanitizeHtml(new TitleCasePipe().transform(params.value));
                    } else {
                        return '&mdash;';
                    }
                }
            },

            {
                headerName: 'Date Updated',
                field: 'createdAt',
                width: 195,
                sortable: true,
                cellRenderer: dateRenderer
            }
        ];
        // Set pagination size to large value in order to show all records. This data is not paginated on the server side
        this.gridOptions = {
            columnDefs: columnDefs,
            suppressRowHoverHighlight: true,
            rowHeight: this.rowHeight,
            paginationPageSize: this.paginationPageSize
        };
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
