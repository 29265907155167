import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchHttpError } from '@trade-platform/ui-shared';
import {
    OrderMarkNeedsClarificationAction,
    OrderMarkNeedsClarificationFailureAction,
    OrderMarkNeedsClarificationSuccessAction,
    OrderNeedsClarificationActionTypes
} from './actions';
import { OrderNeedsClarificationService } from './service';

@Injectable()
export class OrderNeedsClarificationEffects {
    private actions$ = inject(Actions);
    private orderNeedsClarificationService = inject(OrderNeedsClarificationService);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    markOrderNeedsClarification$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderMarkNeedsClarificationAction>(
                OrderNeedsClarificationActionTypes.ORDER_MARK_NEEDS_CLARIFICATION
            ),
            switchMap(action =>
                this.orderNeedsClarificationService
                    .markOrderNeedsClarification(action.payload.orderId)
                    .pipe(
                        map(res => new OrderMarkNeedsClarificationSuccessAction(res)),
                        catchHttpError(error =>
                            of(new OrderMarkNeedsClarificationFailureAction({ error }))
                        )
                    )
            )
        )
    );
}
