import {
    Component,
    HostBinding,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
    inject
} from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { DynamicFormHelper } from '../../dynamic-form.helper';
import { NotificationConfig } from '@trade-platform/form-fields';
import { Field } from '../field.interface';
import { DynamicFormControlState, DynamicFormState } from '../../dynamic-form-store/model';
import { Store } from '@ngrx/store';
import { DynamicFormStore } from '../../dynamic-form-store';
import { getControlDataBinding } from '../../dynamic-form-store/utils';
import { AixSanitizePipe, fromObservable, objectHasValue } from '@trade-platform/ui-utils';
import { caseOf, withDefault } from 'ts.data.maybe';
import { filter } from 'rxjs/operators';
import { AixDataTestingDirective, AixNotificationComponent } from '@trade-platform/ui-components';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'aix-dynamic-notification',
    styleUrls: ['./notification.component.scss'],
    templateUrl: './notification.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [AixNotificationComponent, AixDataTestingDirective, AsyncPipe, AixSanitizePipe]
})
export class AixDynamicNotificationComponent implements Field, OnInit, OnDestroy {
    private helper = inject(DynamicFormHelper);
    private store = inject<Store<Record<string, DynamicFormState>>>(Store);
    private formStore = inject(DynamicFormStore);

    // Static
    static HOST_CLASS = 'aix-form__container aix-flex-grid u-full-width';

    // Decorators
    @HostBinding('class')
    classNames = AixDynamicNotificationComponent.HOST_CLASS;

    @HostBinding('attr.aix-notification')
    aixNotification: string;

    // Store
    ctrlStore$: Observable<DynamicFormControlState>;
    valueToRender$ = new BehaviorSubject('');

    // Other
    config: NotificationConfig;
    private subscriptions: Subscription[] = [];

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    calculateClassNames() {
        this.classNames = this.config.classNames
            ? [
                  AixDynamicNotificationComponent.HOST_CLASS,
                  ...this.helper.parseHostProperties(this.config.classNames.host)
              ].join(' ')
            : AixDynamicNotificationComponent.HOST_CLASS;
    }

    ngOnInit() {
        this.calculateClassNames();

        const refId = this.config.refId as string;
        this.aixNotification = refId;

        // Control initialization
        this.formStore.addNonControl(this.config);
        this.ctrlStore$ = this.formStore.getControlStoreByRefId(refId);

        const { value$ } = getControlDataBinding(
            this.store,
            this.config,
            this.helper.observableMapFuncts
        );

        caseOf(
            {
                Just: data$ => {
                    const boundValue = fromObservable(withDefault(value$, null));
                    this.valueToRender$.next(objectHasValue(boundValue) ? boundValue : '');
                    // late binding subscription
                    this.subscriptions.push(
                        data$.pipe(filter(data => !!data)).subscribe(data => {
                            this.valueToRender$.next(data);
                        })
                    );
                },
                Nothing: () => this.valueToRender$.next(this.config.text)
            },
            value$
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.formStore.removeControl(this.config);
    }
}
