import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { JsonDecoder } from 'ts.data.json';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import {
    profileDecoder,
    resetMFADecoder,
    sharedExternalFirmsDecoder
} from '../../decoders/profile/decoder';
import { Profile, SharedExternalFirm, UpdateProfilePayload } from '../../models/profile/model';

@Injectable()
export class AixUsersService {
    private http = inject(HttpClient);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    getAdvisorLogged(): Observable<Profile> {
        return this.http
            .get<Profile>(`${this.environment.api}me`)
            .pipe(concatMap(p => fromPromise(profileDecoder.decodeToPromise(p))));
    }

    updateAdvisorLogged(payload: UpdateProfilePayload): Observable<Profile> {
        return this.http
            .put<Profile>(`${this.environment.api}me`, payload)
            .pipe(concatMap(p => fromPromise(profileDecoder.decodeToPromise(p))));
    }

    resetPassword() {
        return this.http
            .get<boolean>(`${this.environment.api}me/password_reset`)
            .pipe(concatMap(p => fromPromise(JsonDecoder.boolean.decodeToPromise(p))));
    }

    resetMFA() {
        return this.http
            .delete<null>(`${this.environment.api}me/mfa`)
            .pipe(concatMap((resp: null) => fromPromise(resetMFADecoder.decodeToPromise(resp))));
    }

    getSharedFirms(): Observable<SharedExternalFirm[]> {
        return this.http
            .get<any>(`${this.environment.api}users/accessGroups/listByUserWithSharing`)
            .pipe(concatMap(p => fromPromise(sharedExternalFirmsDecoder.decodeToPromise(p))));
    }
}
