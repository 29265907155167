import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
    selector: 'aix-print-button',
    template: `
        @if (type() === 'button') {
        <button class="aix-btn aix-btn--primary br-btn--primary" (click)="printPage()">
            {{ label() }}
        </button>
        } @if (type() === 'link') {
        <a class="aix-btn aix-btn--link br-link hide-if-print" (click)="printPage()">
            <i class="fa-regular fa-print"></i> {{ label() }}
        </a>
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: []
})
export class AixPrintButtonComponent {
    type = input<'button' | 'link'>('button');
    label = input<string>('Print');

    printPage() {
        // Most browsers can use this command effectively. This is the default since it returns a true or false and does not get blocked by Safari while SSE connections are live
        const execResult = document.execCommand('print', false);
        if (!execResult) {
            // Firefox returns false on the execCommand call, so this is a fallback option
            window.print();
        }
    }
}
