import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
    ELECTRONIC_SUBMISSION,
    PENDING_MANUAL_SUBMISSION,
    TA_SUBMISSION
} from '../../utils/order-utils';
import { AixOrderStepComponent } from '../step/order-step';
import { BaseOrder } from '@trade-platform/ui-shared';
import { approvalConfigType, orderStatus, orderStatusIndex } from '@trade-platform/lib-enums';
import { AixOrderOfflineComponent } from '../../offline/order-offline';
import { AixTemplatesComponent } from '@advisor-ui/app-components';

import { AixOrderSubmitConfirmationComponent } from '../../submit/confirmation/submit-confirmation';
import { AixExpansionPanelComponent } from '@trade-platform/ui-components';

@Component({
    selector: 'aix-submit-step',
    templateUrl: './submit-step.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AixTemplatesComponent,
        AixOrderSubmitConfirmationComponent,
        AixOrderOfflineComponent,
        AixExpansionPanelComponent
    ]
})
export class AixSubmitStepComponent extends AixOrderStepComponent implements OnInit {
    aixTemplatesInitialized = false;

    orderStatus = orderStatus;
    unfinishedForms = true;
    approvalType: 'electronicSubmission' | 'taSubmission' | 'pendingManualSubmission';

    ngOnInit() {
        this.order = this.storeFacade.order;
        if (this.order) {
            this.onOrderUpdated(this.order);
        }
    }

    onOrderUpdated(order: BaseOrder): void {
        if (order) {
            const currentApproval = order.currentApproval;
            if (currentApproval && currentApproval.type === approvalConfigType.manual) {
                this.approvalType = PENDING_MANUAL_SUBMISSION;
            } else if (order.status === orderStatus.pendingSubmissionToTransferAgent) {
                this.approvalType = TA_SUBMISSION;
            } else {
                this.approvalType = ELECTRONIC_SUBMISSION;
            }
            this.unfinishedForms = Object.keys(order.forms)
                .map(itm => order.forms[itm])
                .map(form => form.status !== orderStatus.completed)
                .reduce((pre, act) => pre || act, false);

            this.ref.detectChanges();
        }
    }

    isSubmitted(): boolean {
        return (
            this.order &&
            (this.order.status === orderStatus.pendingFirmApproval ||
                this.order.status === orderStatus.pendingCustodianApproval ||
                this.order.status === orderStatus.pendingFundSponsorApproval ||
                (orderStatusIndex[this.order.status] >
                    orderStatusIndex[orderStatus.pendingSubmissionToTransferAgent] &&
                    this.approvalType !== PENDING_MANUAL_SUBMISSION))
        );
    }
}
