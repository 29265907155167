import { Injectable, inject } from '@angular/core';
import { fromEventPattern } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from 'typescript-logging';
import { assign, EventObject, interpret, Machine, MachineOptions, State } from 'xstate';
import { stepsMachineConfig } from './steps-machine.config';
import { StepsEvent } from './steps-machine.events';
import { StepsContext } from './steps-machine.schema';
import { BaseOrder, BaseOrderStepsService, OrderStep } from '@trade-platform/ui-shared';
import { LogService } from '@trade-platform/ui-utils';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../../../../base.orders.store.facade';

@Injectable()
export class OrderStepsMachine {
    private logService = inject(LogService);
    private store = inject<BaseOrdersStoreFacade>(ORDERS_STORE_FACADE);
    private stepsService = inject(BaseOrderStepsService);

    readonly LOG: Logger;

    stepsMachineOptions: Partial<MachineOptions<StepsContext, StepsEvent>> = {
        services: {},
        guards: {},
        actions: {
            processOrder: assign({
                order: (ctx, event) => this.store.order,
                activeStep: (ctx, event) => this.stepsService.getActiveStep()
            }),
            processActiveStep: ctx =>
                this.stepsService.processActiveStep(
                    ctx.order as BaseOrder,
                    ctx.activeStep as OrderStep
                )
        }
    };

    private _stepsMachine = Machine(stepsMachineConfig).withConfig(this.stepsMachineOptions);
    private service = interpret(this._stepsMachine, { devTools: true }).start();

    stepsState$ = fromEventPattern<[State<StepsContext, StepsEvent>, EventObject]>(
        handler => {
            return this.service.onTransition(handler);
        },
        (_, service) => service.stop()
    ).pipe(map(([state, _]) => state));

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        this.LOG = this.logService.getLogger('xstate.services.steps-machine.service');
    }

    send(event: StepsEvent) {
        this.service.send(event);
    }
}
