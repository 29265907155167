import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';

@Injectable()
export class OrderSigningCompleteService {
    private http = inject(HttpClient);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    orderSigningComplete(orderId: string): Observable<any> {
        return this.http.post<any>(`${this.environment.api}orders/${orderId}/signingComplete`, {
            id: orderId
        });
    }
}
