import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import {
    OrderMarkNeedsClarificationResponse,
    orderMarkNeedsClarificationResponseDecoder
} from '@trade-platform/ui-shared';

@Injectable()
export class OrderNeedsClarificationService {
    private http = inject(HttpClient);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    markOrderNeedsClarification(orderId: string): Observable<OrderMarkNeedsClarificationResponse> {
        return this.http
            .post<OrderMarkNeedsClarificationResponse>(
                `${this.environment.api}orders/${orderId}/needsClarification`,
                {
                    id: orderId
                }
            )
            .pipe(
                concatMap(p =>
                    fromPromise(orderMarkNeedsClarificationResponseDecoder.decodeToPromise(p))
                )
            );
    }
}
