import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    input,
    model,
    OnChanges,
    output,
    SimpleChanges,
    inject
} from '@angular/core';
import { AixMargins } from '../aix-loading/aix-loading.component';
import * as PDFJS from 'pdfjs-dist';
import { AixPdfComponent } from './aix-pdf.component';
import { NgStyle } from '@angular/common';
import { Subject } from 'rxjs';

@Component({
    selector: 'aix-pdf-viewer',
    templateUrl: './aix-pdf-viewer.component.html',
    styleUrls: ['./aix-pdf-viewer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgStyle, AixPdfComponent]
})
export class AixPdfViewerComponent implements OnChanges {
    private ref = inject(ElementRef);
    private cd = inject(ChangeDetectorRef);

    page = model<number>();

    src = input<any>();
    authToken = input<string>();
    PDFJSready = input<boolean>();
    margin = input<AixMargins>();

    loadDocument = output();
    loadDocumentError = output<Error>();
    selectPage = output();
    updatePage = output<number>();

    isLoading$ = new Subject<boolean>();

    items: Array<any>;
    visibleProvider: Array<any> = [];

    height: number;
    canvasHeight = { height: 'auto', width: 'auto' };
    cellsPerPage = 0;
    numberOfCells = 0;
    rowWidth = 300;
    rowHeight = 300;
    scaleRatio = 1;
    rotation = 0;

    pdfLoaderTask: any;
    pdfDoc: any;
    viewport: any;
    totalPages: number;
    pdfTitle: string;

    reloadCount = 0;
    loadProgress = 100;

    itemContainer: any;
    itemSelected = false;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    init() {
        this.itemContainer = this.ref.nativeElement.querySelector('.canvas');
        this.rowWidth = this.ref.nativeElement.getBoundingClientRect().width;
        this.rowHeight = this.ref.nativeElement.getBoundingClientRect().height;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['PDFJSready'] && window.hasOwnProperty('PDFJS')) {
            this.init();
        }

        if (changes['src'] && !!changes['src'].currentValue) {
            this.renderPDF();
            this.goToPage(1);
        }

        if (changes['page'] && this.ref.nativeElement) {
            this.goToPage(<number>this.page());
        }
    }

    renderPDF() {
        const pdfSource = this.getPdfSource();

        this.loadProgress = 0;
        this.pdfLoaderTask = PDFJS.getDocument(pdfSource);

        this.isLoading$.next(true);
        this.pdfLoaderTask.onProgress = this.onLoadProgress.bind(this);
        this.pdfLoaderTask.promise.then(
            this.onLoadTask.bind(this),
            this.onLoadTaskError.bind(this)
        );
        this.cd.detectChanges();
    }

    getPdfSource() {
        return typeof this.src() === 'string' ? { url: this.src() } : this.src();
    }

    onLoadTask(pdfDoc: any) {
        this.rotation = 0;
        this.reloadCount = 0;
        this.loadProgress = -1;

        this.loadDocument.emit();
        this.isLoading$.next(false);

        this.pdfDoc = pdfDoc;
        this.totalPages = pdfDoc.numPages;
        this.items = [];

        for (let i = 1; i <= this.totalPages; i++) {
            this.items.push({
                page: i
            });
        }

        this.pdfDoc.getPage(1).then(this.onGetPage.bind(this));
        this.cd.detectChanges();
    }

    onGetPage(page: any) {
        this.viewport = page.getViewport({ scale: 1 });

        this.initDisplayList();
        this.fit();
        this.cd.detectChanges();
    }

    reload(error: Error) {
        this.reloadCount++;

        if (this.reloadCount < 10) {
            this.isLoading$.next(true);
            this.loadProgress = 0;
            const pdfSource = this.getPdfSource();
            this.pdfLoaderTask = PDFJS.getDocument(pdfSource);
            this.pdfLoaderTask.onProgress = this.onLoadProgress.bind(this);
            this.pdfLoaderTask.promise.then(
                this.onLoadTask.bind(this),
                this.onLoadTaskError.bind(this)
            );
        } else {
            this.isLoading$.next(false);
            this.loadProgress = -1;
            this.loadDocumentError.emit(error);
            this.cd.detectChanges();
        }
    }

    onRenderError(error: Error) {
        if (error.name === 'UnknownErrorException') {
            // Reload mechanism for Edge browser
            this.reload(error);
        }
    }

    onLoadTaskError(error: Error) {
        if (error.name === 'InvalidPDFException') {
            // Reload mechanism for Edge browser
            this.reload(error);
        } else {
            this.isLoading$.next(false);
            this.loadProgress = -1;
            this.loadDocumentError.emit(error);
            this.cd.detectChanges();
        }
    }

    onLoadProgress(progress: ProgressEvent) {
        this.isLoading$.next(true);
        if (progress.total) {
            this.loadProgress = Math.round((progress.loaded / progress.total) * 100);
        } else {
            this.loadProgress = 0;
        }
    }

    initDisplayList() {
        const rowHeight = this.isPortrait() ? this.rowHeight : this.rowWidth;
        this.height = this.ref.nativeElement.clientHeight;
        this.cellsPerPage = Math.round(this.height / rowHeight);

        if (this.cellsPerPage === 0) {
            this.cellsPerPage = 2;
        }

        this.numberOfCells = 3 * this.cellsPerPage;
        this.canvasHeight = {
            height: this.items.length * (rowHeight + 6) + 24 + 'px',
            width: 'auto'
        };

        if (this.height > 0) {
            this.updateDisplayList();
        }
    }

    @HostListener('scroll')
    updateDisplayList() {
        const rowHeight = this.isPortrait() ? this.rowHeight : this.rowWidth;
        const rowWidth = this.isPortrait() ? this.rowWidth : this.rowHeight;
        const firstCell: number = Math.max(
            Math.floor(this.ref.nativeElement.scrollTop / (rowHeight + 6)) - this.cellsPerPage,
            0
        );
        const cellsToCreate: number = Math.min(firstCell + this.numberOfCells, this.numberOfCells);

        this.visibleProvider = this.items.slice(firstCell, firstCell + cellsToCreate);

        for (let i = 0; i < this.visibleProvider.length; i++) {
            this.visibleProvider[i].styles = {
                top: (firstCell + i) * (rowHeight + 6) + 12 + 'px',
                left: '12px',
                height: `${rowHeight}px`,
                width: `${rowWidth}px`
            };
        }

        const page = Math.min(
            this.items.length - 1,
            Math.round(this.ref.nativeElement.scrollTop / rowHeight)
        );
        this.page.set(page);
        this.updatePage.emit(page);
    }

    /**
     * Simulates a scroll (by 1 pixel) on the native element, triggering the updateDisplayList()
     * function;
     *
     * NOTE: This seems a little hacky to me, and I don't like it, but calling that function
     * directly was not working correctly (without scrolling), after calling the fit() function;
     */
    simulateScroll() {
        this.ref.nativeElement.scrollTop = this.ref.nativeElement.scrollTop === 1 ? 0 : 1;
    }

    zoomIn() {
        this.scaleRatio =
            (this.ref.nativeElement.getBoundingClientRect().width - 25) / this.viewport.width;
        const maxHeight: number = this.viewport.height * this.scaleRatio * 3;

        if (this.isPortrait()) {
            this.rowWidth += 50 * (this.viewport.width / this.viewport.height);
            this.rowHeight += 50;
        } else {
            this.rowWidth += 50;
            this.rowHeight += 50 * (this.viewport.height / this.viewport.width);
        }

        this.ref.nativeElement.scrollTop += 50;

        if (this.rowHeight > maxHeight) {
            this.rowHeight = maxHeight;
        }

        this.initDisplayList();
        this.cd.detectChanges();
    }

    zoomOut() {
        this.scaleRatio =
            (this.ref.nativeElement.getBoundingClientRect().width - 25) / this.viewport.width;

        if (this.isPortrait()) {
            this.rowWidth -= 50 * (this.viewport.width / this.viewport.height);
            this.rowHeight -= 50;
        } else {
            this.rowWidth -= 50;
            this.rowHeight -= 50 * (this.viewport.height / this.viewport.width);
        }

        this.ref.nativeElement.scrollTop -= 50;

        if (this.rowHeight < 200) {
            this.rowHeight = 200;
        }

        this.initDisplayList();
        this.cd.detectChanges();
    }

    rotate() {
        this.rotation += 90;

        if (this.rotation === 360) {
            this.rotation = 0;
        }

        this.fit();
        this.cd.detectChanges();
    }

    fit() {
        if (this.viewport) {
            const scaleRatioW =
                (this.ref.nativeElement.getBoundingClientRect().width - 25) / this.viewport.width;

            if (this.isPortrait()) {
                // Fit portrait
                this.scaleRatio = scaleRatioW;
                this.rowWidth = this.viewport.width * this.scaleRatio;
                this.rowHeight = this.viewport.height * this.scaleRatio;
                this.initDisplayList();
            } else {
                // Fit landscape
                this.scaleRatio = (this.viewport.width * scaleRatioW) / this.viewport.height;
                this.rowWidth = this.viewport.width * this.scaleRatio;
                this.rowHeight = this.viewport.width * scaleRatioW;
                this.initDisplayList();
            }
        }
    }

    isPortrait() {
        return this.rotation === 0 || this.rotation === 180;
    }

    goToPage(page: number) {
        this.ref.nativeElement.scrollTop = this.rowHeight * (page - 1);

        if (this.items) {
            this.updateDisplayList();
        }
    }

    onClickPage(item: any) {
        this.selectPage.emit(item.page);
    }

    download(pdfTitle: string) {
        this.pdfTitle = pdfTitle;
        this.pdfDoc.getData().then(this.onDownloadIsReady.bind(this));
    }

    onDownloadIsReady(data: any) {
        const blob = new Blob([data], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        const filename = 'download.pdf';

        const a = document.createElement('a');
        a.href = blobUrl;
        a.target = '_parent';

        if (a.hasOwnProperty('download')) {
            (<any>a).download = this.pdfTitle || filename;
        }

        document.body.appendChild(a);

        a.click();
        if (a.parentNode !== null) {
            a.parentNode.removeChild(a);
        }
    }
}
