import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AixStepProgressComponent, BaseOrder, OrderFormComment } from '@trade-platform/ui-shared';
import { AixOrderStepComponent } from '../step/order-step';
import { commentStatus, commentTag, formStatus, orderStatus } from '@trade-platform/lib-enums';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    AixExpansionPanelComponent,
    AixHeaderSectionComponent,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { AixFormlistComponent, FormListItem } from '../../formlist/formlist';
import { RemoteDataModule } from 'ngx-remotedata';
import { AixTemplatesComponent } from '@advisor-ui/app-components';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'aix-forms-step',
    templateUrl: './forms-step.html',
    styleUrls: ['./forms-step.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AixTemplatesComponent,
        AixStepProgressComponent,
        AixDataTestingDirective,
        AixHeaderSectionComponent,
        AixFormlistComponent,
        AixButtonComponent,
        AixExpansionPanelComponent,
        AsyncPipe,
        RemoteDataModule
    ]
})
export class AixFormsStepComponent extends AixOrderStepComponent implements OnInit {
    totalFormComments = 0;
    totalUnresolvedFormComments = -1;

    orderComments: OrderFormComment[];
    completedForms = 0;
    totalForms = 0;
    unfinishedForms = true;
    totalNotOnboardedForms = 0;

    orderStatus = orderStatus;
    primaryButtonType = BUTTON_TYPE.primary;

    ngOnInit() {
        this.subscriptions.push(
            this.storeFacade.actions.orderFormsComplete.success$.subscribe(action => {
                this.loadOrder();
            }),
            this.storeFacade.actions.getFormComments.success$.subscribe(action => {
                this.updateComments(action.payload);
            })
        );

        this.order = this.storeFacade.order;
        if (this.order) {
            this.onOrderUpdated(this.order);
        }
    }

    onOrderUpdated(order: BaseOrder): void {
        const { forms } = order;

        this.totalForms = Object.keys(forms).filter(
            item =>
                forms[item].isOnboarded ||
                (!forms[item].isOnboarded && forms[item].status === formStatus.completed)
        ).length;
        this.completedForms = Object.keys(forms).filter(
            item => forms[item].status === formStatus.completed
        ).length;

        this.unfinishedForms = Object.keys(forms)
            .map(itm => forms[itm])
            .map(form => form.status !== formStatus.completed && form.isOnboarded !== false)
            .reduce((pre, act) => pre || act, false);

        this.totalNotOnboardedForms = Object.keys(forms)
            .map(itm => forms[itm])
            .filter(f => f.isOnboarded === false).length;
        this.loadOrderFormComments();
        this.ref.detectChanges();
    }

    updateComments(comments: OrderFormComment[]) {
        this.orderComments = this.orderFormCommentsPipe
            .transform(comments, false, false, undefined, commentTag.change)
            .filter((c: OrderFormComment) => c.isOnForm);
        this.totalFormComments = this.orderComments.length;
        this.totalUnresolvedFormComments = this.orderComments.filter(
            c => c.status === commentStatus.unresolved
        ).length;
        this.ref.detectChanges();
    }

    goToForm(item?: FormListItem) {
        if (item && item.form.isOnboarded === false) {
            this.router.navigate(
                this.storeFacade.routes.documentViewer(this.orderId, item.document.id)
            );
        } else {
            this.router.navigate(
                this.storeFacade.routes.form(
                    this.orderId,
                    item
                        ? item.form.id
                        : Object.keys(this.order.forms)
                              .map(itm => this.order.forms[itm])
                              .sort((a, b): number => a.index - b.index)[0].id
                )
            );
        }
    }

    goToFirstUnfinishedForm() {
        if (this.order && this.order.forms) {
            const nextUnfinishedForm = Object.keys(this.order.forms)
                .map(itm => this.order.forms[itm])
                .sort((prev, next) => (prev.index > next.index ? 1 : -1))
                .filter(form => form.status !== formStatus.completed)[0];
            const formId = nextUnfinishedForm.id;
            this.router.navigate(this.storeFacade.routes.form(this.orderId, formId));
        }
    }

    completeForms() {
        this.storeFacade.actions.orderFormsComplete.dispatch({ orderId: this.orderId });
    }

    hasOrderFormComments(): boolean {
        return this.totalUnresolvedFormComments > 0;
    }

    isRevising(): boolean {
        if (!this.order || !this.order.forms) {
            return false;
        }

        let isRevising = true;
        Object.values(this.order.forms).forEach(form => {
            if (form.status !== formStatus.revising) {
                isRevising = false;
            }
        });
        return isRevising;
    }

    isOnlyNotOnboardedForms(): boolean {
        return (
            this.order &&
            !this.unfinishedForms &&
            this.totalNotOnboardedForms === Object.keys(this.order.forms).length
        );
    }

    getOrderStepDescription(): string {
        if (this.totalUnresolvedFormComments > 0) {
            return 'Your order requires attention. Please address the action items to continue.';
        } else if (this.isRevising() && this.unfinishedForms) {
            return 'You are editing this order. Choose a form below to continue.';
        } else if (this.isOnlyNotOnboardedForms()) {
            return `You will be prompted to complete ${
                this.totalNotOnboardedForms === 1 ? 'this form' : 'these forms'
            } in a later step, please continue.`;
        } else if (!this.unfinishedForms) {
            return 'All forms are complete. Please continue.';
        } else {
            return 'Please review and complete required forms.';
        }
    }
}
