import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OrderMapSignaturesService } from './service';
import { map, mergeMap } from 'rxjs/operators';
import { catchHttpError } from '@trade-platform/ui-shared';
import { of } from 'rxjs';
import {
    OrderMapSignatures,
    OrderMapSignaturesActionTypes,
    OrderMapSignaturesFailure,
    OrderMapSignaturesSuccess
} from './actions';

@Injectable()
export class OrderMapSignaturesEffects {
    private actions$ = inject(Actions);
    private orderMapSignaturesService = inject(OrderMapSignaturesService);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    orderDocumentRemove$ = createEffect(() =>
        this.actions$.pipe(
            ofType<OrderMapSignatures>(OrderMapSignaturesActionTypes.ORDER_MAP_SIGNATURES),
            mergeMap(action =>
                this.orderMapSignaturesService.mapSignatures(action.payload).pipe(
                    map(res => new OrderMapSignaturesSuccess(res)),
                    catchHttpError(error => of(new OrderMapSignaturesFailure({ error })))
                )
            )
        )
    );
}
