import { Component, input, output } from '@angular/core';
import { AixTooltipDirective } from '../../directives/tooltip/aix-tooltip';
import { DatePipe } from '@angular/common';
import { AixThumbnailComponent } from '../aix-thumbnail/aix-thumbnail.component';

export interface ProductMaterialInfo {
    id: string;
    name: string;
    isPdf: boolean;
    description?: string;
    date?: string;
    thumbnail: string;
    requiresStamping?: boolean;
}

export interface ProductMaterialListItem {
    material: ProductMaterialInfo;
    isSelected: boolean;
}

export interface ProductMaterialSendData {
    materialSelection: ProductMaterialListItem[] | undefined;
    form: any;
}

@Component({
    selector: 'aix-document-preview',
    templateUrl: './aix-document-preview.component.html',
    styleUrls: ['./aix-document-preview.component.scss'],
    standalone: true,
    imports: [AixThumbnailComponent, AixTooltipDirective, DatePipe]
})
export class AixDocumentPreviewComponent {
    data = input.required<ProductMaterialListItem>();
    tooltip = input<string>();
    selectable = input(false);

    actionClick = output<string>();
    checkItem = output<ProductMaterialListItem>();

    constructor() {}

    onCheck(evt: Event) {
        this.checkItem.emit(<ProductMaterialListItem>this.data());
        evt.stopPropagation();
    }

    onActionClicked(id: string) {
        this.actionClick.emit(id);
    }
}
