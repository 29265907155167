import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'aix-ui-document-tabs',
    templateUrl: './document-tabs.component.html',
    styleUrls: ['./document-tabs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass]
})
export class AixUIDocumentTabsComponent {
    activeTab = input<string>('Order');
    tabs = input<string[]>(['Order']);

    onClickTab = output<string>();

    constructor() {}
}
