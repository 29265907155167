import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    inject,
    OnDestroy,
    OnInit,
    viewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { AppState, Outage } from '@trade-platform/ui-shared';
import {
    AixSanitizePipe,
    ENVIRONMENT,
    getFromStorage,
    IEnvironment
} from '@trade-platform/ui-utils';
import { EventSourcePolyfill } from 'ng-event-source';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AixAuthService, getOutagesForTenant } from '@advisor-ui/app-services';

import { AixNotificationComponent } from '@trade-platform/ui-components';
import { Store } from '@ngrx/store';
import {
    ClearBannerOffsetAction,
    SetBannerOffsetAction
} from 'libs/ui-shared/src/lib/store/styles/actions';

@Component({
    selector: 'aix-system-notifications',
    templateUrl: 'system-notifications.html',
    styleUrls: ['system-notifications.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AixNotificationComponent, AixSanitizePipe]
})
export class SystemNotificationsComponent implements OnInit, OnDestroy {
    private ref = inject(ChangeDetectorRef);
    private router = inject(Router);
    private authService = inject(AixAuthService);
    readonly store = inject<Store<AppState>>(Store);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    // TODO: check elementRef
    // @ViewChild('messageBoxElement', { read: ElementRef, static: false })
    // messageBoxElement: ElementRef;

    messageBoxElement = viewChild<ElementRef>('messageBoxElement');

    show = false;
    status: string;
    message: string;
    logoutAction: any;
    systemNotifications: Outage[];
    routeName: string;
    source: EventSourcePolyfill | null;

    subscriptions: Subscription[] = [];

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    ngOnInit() {
        this.subscriptions.push(
            this.router.events.pipe(debounceTime(50)).subscribe(() => {
                const auth = getFromStorage('auth');
                const url = this.router.url;
                const urlParts = url.split('/');
                this.routeName = urlParts[urlParts.length - 1];

                if (!this.environment.ignoreOutages && !this.source && auth) {
                    this.createEventSource();
                }

                if (this.routeName === 'outage') {
                    this.message = '';

                    // Handle if a logout action was fired, but we're already on the outage page
                    this.removeInterval();

                    if (this.source) {
                        this.source.close();
                        this.source = null;
                    }

                    this.ref.detectChanges();
                }
            })
        );
    }

    createEventSource() {
        // Polyfill for IE11/Edge and custom headers
        // Set heartbeat timeout to 1 hr
        this.source = new EventSourcePolyfill(`${this.environment.outagesApi}outages`, {
            heartbeatTimeout: 3600000,
            connectionTimeout: 3600000
        });

        this.source.onmessage = e => {
            const outagesData = JSON.parse(e.data);
            this.systemNotifications = getOutagesForTenant(outagesData);
            if (this.systemNotifications?.length > 0) {
                if (
                    this.systemNotifications.find(notification => notification.status === 'error')
                ) {
                    this.systemNotifications.push({
                        status: 'error',
                        createdAt: '',
                        tenants: [],
                        messages: [
                            {
                                status: 'error',
                                createdAt: '',
                                message:
                                    'You will be logged out in 30 seconds so we can resolve this issue. Thank you for your patience.'
                            }
                        ]
                    });
                    this.logoutAction = setTimeout(() => {
                        this.authService.callLogout();
                        this.ref.detectChanges();
                    }, 30000);
                }
                // Never show notifications on outage screen
                if (this.routeName !== 'outage') {
                    setTimeout(() => {
                        this.ref.detectChanges();
                    }, 100);
                }
            }
            this.ref.detectChanges();
            setTimeout(() => {
                const bannersHeight = (this.messageBoxElement() as ElementRef).nativeElement
                    .clientHeight;
                this.store.dispatch(new SetBannerOffsetAction({ bannerOffsetPx: bannersHeight }));
            }, 101);
        };
        this.source.onerror = (e: Error) => {
            console.warn(`EventSource emitted an error: ${e.message}`);
        };
    }

    removeInterval() {
        if (this.logoutAction) {
            clearTimeout(this.logoutAction);
            this.logoutAction = null;
        }
    }

    onCloseNotification() {
        setTimeout(() => {
            const bannersHeight = (this.messageBoxElement() as ElementRef).nativeElement
                .clientHeight;
            this.store.dispatch(new SetBannerOffsetAction({ bannerOffsetPx: bannersHeight }));
        }, 101);
    }

    ngOnDestroy() {
        this.removeInterval();
        if (this.source) {
            this.source.close();
        }
        this.store.dispatch(new ClearBannerOffsetAction());
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
