import { extractStreamValue } from '@trade-platform/ui-utils';
import { Observable } from 'rxjs';

export interface CurrentPageStruct {
    page: number;
}

export class PaginationHelper {
    totalCount: number;
    currentPage: CurrentPageStruct;
    data$: Observable<any>;

    constructor(
        currentPage: CurrentPageStruct,
        data$: Observable<any>,
        private filterCallback = () => undefined as any
    ) {
        this.currentPage = currentPage;
        this.data$ = data$;
    }

    goToPage(n: any) {
        if (this.currentPage.page !== n) {
            this.currentPage.page = n;

            if (this.filterCallback) {
                this.filterCallback();
            }
        }
    }

    goToFirstPage() {
        this.goToPage(0);
    }

    goToPrevPage() {
        this.goToPage(Math.max(this.currentPage.page - 1, 0));
    }

    goToNextPage() {
        const totalPages = this.getTotalPages();
        this.goToPage(Math.min(this.currentPage.page + 1, totalPages - 1));
    }

    goToLastPage() {
        const totalPages = this.getTotalPages();
        this.goToPage(totalPages - 1);
    }

    private getTotalPages() {
        const data = extractStreamValue(this.data$);
        return data && data.totalPages ? data.totalPages : 1;
    }
}
