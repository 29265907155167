import { ChangeDetectionStrategy, Component, input, output, inject } from '@angular/core';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../../../base.orders.store.facade';

@Component({
    selector: 'aix-e-sign',
    templateUrl: './e-sign.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AixButtonComponent, AixDataTestingDirective]
})
export class AixOrderProcessESignComponent {
    storeFacade = inject<BaseOrdersStoreFacade>(ORDERS_STORE_FACADE);

    signaturesCompleted = input<boolean>(false);
    isReadOnly = input<boolean>(false);

    onClickContinue = output();

    continueButtonType = BUTTON_TYPE.primary;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}
}
