import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';

export interface DocuSignForm {
    orderId?: string;
    emailSubject: string;
    emailBody: string;
}

@Injectable()
export class OrderDocusignSendService {
    private http = inject(HttpClient);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    orderDocusignSend(form: DocuSignForm): Observable<any> {
        return this.http.post<any>(`${this.environment.api}orders/${form.orderId}/send`, {
            emailSubject: form.emailSubject,
            emailBody: form.emailBody
        });
    }
}
