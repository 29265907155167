import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { DocumentFile } from '@trade-platform/ui-shared';

@Injectable()
export class OrderDocumentRemoveService {
    private http = inject(HttpClient);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    orderDocumentRemove(payload: { orderId: string; fileToRemove: DocumentFile }): Observable<any> {
        return this.http.delete(
            `${this.environment.api}orders/${payload.orderId}/files/${payload.fileToRemove.id}`
        );
    }
}
