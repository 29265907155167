import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchHttpError } from '@trade-platform/ui-shared';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
    DocumentTypeActionTypes,
    LoadDocumentTypes,
    LoadDocumentTypesFailure,
    LoadDocumentTypesSuccess
} from './actions';
import { DocumentTypesService } from './service';

@Injectable()
export class DocumentTypesEffects {
    private actions$ = inject(Actions);
    private service = inject(DocumentTypesService);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    loadDocumentTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadDocumentTypes>(DocumentTypeActionTypes.LOAD_DOCUMENT_TYPES),
            switchMap(action => {
                return this.service.getDocumentTypes(action.payload).pipe(
                    map(fileTypes => {
                        return new LoadDocumentTypesSuccess(fileTypes);
                    }),
                    catchHttpError(error => of(new LoadDocumentTypesFailure({ error })))
                );
            })
        )
    );
}
