import { AfterViewInit, Component, output, TemplateRef, viewChildren } from '@angular/core';
import { AixTemplateDirective } from './aix-template.directive';
import { NgClass } from '@angular/common';
import { AixDataTestingDirective } from '@trade-platform/ui-components';

export interface AixTemplateConfig {
    title: string;
}

@Component({
    selector: 'aix-templates',
    templateUrl: './aix-templates.component.html',
    standalone: true,
    imports: [AixTemplateDirective, AixDataTestingDirective, NgClass]
})
export class AixTemplatesComponent implements AfterViewInit {
    readonly templateRefs = viewChildren(AixTemplateDirective);
    valueChanged = output<any>();
    onInit = output<boolean>();

    _initialized = false;

    get initialized(): boolean {
        return this._initialized;
    }

    constructor() {}

    ngAfterViewInit() {
        this._initialized = true;
        setTimeout(() => this.onInit.emit(this._initialized));
    }

    getTemplate(templateName: string): TemplateRef<any> {
        return (
            this.templateRefs().find(
                (t: AixTemplateDirective) => t.name().toLowerCase() === templateName.toLowerCase()
            ) as AixTemplateDirective
        ).template;
    }
}
