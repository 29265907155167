import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
    AppState,
    FlashMessage,
    FlashMessageType,
    RemoveFlashMessageAction
} from '@trade-platform/ui-shared';
import { Observable } from 'rxjs';
import { NgClass, AsyncPipe } from '@angular/common';

@Component({
    selector: 'aix-flash-messages',
    templateUrl: 'flash-messages.html',
    styleUrls: ['flash-messages.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass, AsyncPipe]
})
export class FlashMessagesComponent {
    store = inject<Store<AppState>>(Store);

    messages$: Observable<FlashMessage[]>;
    MsgType = FlashMessageType;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]); // enums cant be used in templates

    constructor() {
        this.messages$ = this.store.pipe(select(state => state.flashMessages.messages));
    }

    closeMessage(uid: string) {
        this.store.dispatch(new RemoveFlashMessageAction({ uid }));
    }
}
