import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { getFromStorage, setToStorage, withStorage } from '@trade-platform/ui-utils';
import { map, tap } from 'rxjs/operators';
import {
    AuthActionTypes,
    LoadAuthFromStorageAction,
    SetAuthAction,
    UnloadAuthAction
} from './actions';
import { Auth } from '../../models/auth/model';

@Injectable()
export class AuthEffects {
    private actions$ = inject(Actions);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    loadAuthFromStorage$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadAuthFromStorageAction>(AuthActionTypes.LOAD_AUTH_FROM_STORAGE),
            map(() => {
                const auth: Auth = getFromStorage('auth') as Auth;
                return new SetAuthAction(auth);
            })
        )
    );

    setAuth$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActionTypes.SET_AUTH),
                tap((action: SetAuthAction) => {
                    setToStorage('auth', {
                        accessToken: action.payload.accessToken,
                        expiresAt: action.payload.expiresAt.toString()
                    });
                })
            ),
        { dispatch: false }
    );

    unloadAuth$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActionTypes.UNLOAD_AUTH),
                tap((_: UnloadAuthAction) => {
                    withStorage(storage => storage.removeItem('auth'));
                })
            ),
        { dispatch: false }
    );
}
