import { Component, input, output } from '@angular/core';
import { NgClass, NgStyle } from '@angular/common';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';
import { curryRight } from 'lodash-es';

@Component({
    selector: 'aix-inline-dropdown',
    templateUrl: 'aix-inline-dropdown.component.html',
    styleUrls: ['aix-inline-dropdown.component.scss'],
    standalone: true,
    imports: [AixDataTestingDirective, NgStyle, NgClass]
})
export class AixInlineDropdownComponent {
    dropdownOptions = input<string[]>([]);
    label = input<string>();
    disabled = input(false);
    disabledOptions = input<string[]>([]);
    icon = input<string>('fa-regular fa-ellipsis-vertical');
    rightAlign = input<boolean>(false);
    buttonStyle = input<string>('');
    styles = input<any>({
        links: {
            color: null
        },
        mainButton: {}
    });

    optionSelected = output<string>();

    constructor() {}

    optionClicked(option: string) {
        this.optionSelected.emit(option);
    }

    protected readonly curryRight = curryRight;
}
