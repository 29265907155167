import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    OnDestroy,
    OnInit,
    Type,
    viewChild,
    inject
} from '@angular/core';
import { ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import {
    AixDataTestingDirective,
    AixDropdownComponent,
    selectByIdFunction,
    unwrapSelectByIdFunction
} from '@trade-platform/ui-components';
import { getHostPropertyClass } from '../../dynamic-form.constants';
import { DynamicFormHelper } from '../../dynamic-form.helper';
import { SelectFieldConfig } from '@trade-platform/form-fields';
import { Field, FieldEvent } from '../field.interface';
import { DropdownControlState, DynamicFormState } from '../../dynamic-form-store/model';
import { DynamicFormStore } from '../../dynamic-form-store';
import { getDataPathByFieldConfig } from '../../dynamic-form-store/utils';
import { filter, first, withLatestFrom } from 'rxjs/operators';
import {
    DynamicFormControlSetDirtyAction,
    DynamicFormSetDataAction
} from '../../dynamic-form-store/actions';
import { objectHasValue, stringIsNotEmpty } from '@trade-platform/ui-utils';
import { DropdownCellrendererMap } from './dropdown-cellrenderer-map';
import { handleServerValdationErrors } from '../../dynamic-form.utils';
import {
    FieldDecoration,
    FieldDecorationDirective
} from '../field-decoration/field-decoration.directive';
import { FieldDecorationMap } from '../field-decoration/field-decoration-map';
import { IsInProgressPipe } from 'ngx-remotedata';
import { NgClass } from '@angular/common';
import { AixDynamicNextPendingFieldDirective } from '../../directives/dynamic-next-pending-field';
import { AixFormErrorsPipe } from '../../../pipes/form-errors';

@Component({
    selector: 'aix-dynamic-dropdown',
    styleUrls: ['./dropdown.component.scss'],
    templateUrl: './dropdown.component.html',
    standalone: true,
    imports: [
        AixDropdownComponent,
        AixDynamicNextPendingFieldDirective,
        NgClass,
        AixDataTestingDirective,
        FieldDecorationDirective,
        AixFormErrorsPipe
    ]
})
export class AixDynamicDropdownComponent implements Field, OnInit, OnDestroy {
    helper = inject(DynamicFormHelper);
    private store = inject<Store<Record<string, DynamicFormState>>>(Store);
    private formStore = inject(DynamicFormStore);
    private elemRef = inject(ElementRef);
    private cd = inject(ChangeDetectorRef);

    // Static
    static HOST_CLASS = 'aix-flex-grid__col';

    // Decorators
    @HostBinding('class')
    classNames = AixDynamicDropdownComponent.HOST_CLASS;

    dropdownRef = viewChild.required<AixDropdownComponent>('dropdownRef');

    @HostBinding('attr.aix-control')
    aixControl: string;

    // Store Data
    templateData = {
        valueToRender: '',
        options: [] as DropdownControlState['extra']['options'],
        selectedOption: undefined as Readonly<Record<string, string>> | undefined,
        fieldIsDisabled: false,
        fieldIsRequired: false,
        fieldIsDirty: false,
        ctrlErrors: null as ValidationErrors | null,
        ctrlHasRequiredError: false,
        ctrlIsInvalid: false,
        canDisplayErrors: false,
        ctrlIsFocused: false,
        ctrlIsExternalFiltering: false
    };
    userInputEmitter$ = new Subject<string>();

    // Other
    config: SelectFieldConfig;
    private subscriptions: Subscription[] = [];
    selectByIdFunction = selectByIdFunction;
    unwrapSelectByIdFunction = unwrapSelectByIdFunction;
    fieldDecoration: Type<FieldDecoration>;
    fieldDecorationParams: FieldDecoration['params'];
    fieldDecorationHostObservables: FieldDecoration['hostObservables'];

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        this.cd.detach();
    }

    ngOnInit() {
        if (this.dropdownRef()) {
            this.dropdownRef().registerOnChange((value: any) => this.userInputEmitter$.next(value));
            this.dropdownRef().registerOnTouched(() => ({}));
        }

        const formUID = this.formStore.formUID;
        const refId = this.config.refId as string;
        this.aixControl = refId;
        this.classNames = this.calculateClassNames();
        this.initializeFieldDecorations();
        this.initializeExternalFilterListener();

        // Control initialization
        this.formStore.addDropdown(this.config);

        const ctrlStore$ = this.formStore.getControlStoreByRefId<DropdownControlState>(refId);
        const valueToRender$ = this.formStore.getDataStore<string>(
            getDataPathByFieldConfig(this.config)
        );

        this.subscriptions.push(
            ctrlStore$.pipe(withLatestFrom(valueToRender$)).subscribe(([ctrl, valueToRender]) => {
                const options = ctrl.extra.options;

                // valueToRender
                this.templateData.valueToRender = valueToRender;

                if (ctrl.configUpdateByRelation) {
                    this.config = {
                        ...(ctrl.fieldConfig as SelectFieldConfig)
                    };
                }

                // options
                this.templateData.options = options;

                this.templateData.selectedOption = options.find(
                    opt => opt[this.config.valueField as string] === valueToRender
                );

                // Selected option has been filtered, so we have to clear the data
                if (valueToRender && !this.templateData.selectedOption) {
                    this.dropdownRef().clearSelection();
                    this.store.dispatch(
                        new DynamicFormSetDataAction(
                            formUID,
                            getDataPathByFieldConfig(this.config),
                            null
                        )
                    );
                }

                // fieldIsDisabled
                this.templateData.fieldIsDisabled =
                    ctrl.disabledByRelation || ctrl.fieldConfig?.disabled === true;

                // fieldIsRequired
                const hasRequiredValidator = (ctrl?.fieldConfig?.validation as ValidatorFn[]).some(
                    val => val === Validators.required
                );
                this.templateData.fieldIsRequired = ctrl.requiredByRelation || hasRequiredValidator;

                // fieldIsDirty
                this.templateData.fieldIsDirty = ctrl.isDirty;

                // ctrlErrors
                this.templateData.ctrlErrors = ctrl?.validation;

                // ctrlHasRequiredError
                this.templateData.ctrlHasRequiredError =
                    ctrl?.validation && ctrl?.validation['required'];

                // ctrlIsInvalid
                this.templateData.ctrlIsInvalid = ctrl?.validation !== null;

                // canDisplayErrors
                const hasNonRequiredErrors =
                    !!ctrl.validation &&
                    Object.keys(ctrl.validation).some(key => key !== 'required');
                this.templateData.canDisplayErrors =
                    (ctrl.validation !== null && ctrl.isDirty) || hasNonRequiredErrors;

                // Server Validation errors
                handleServerValdationErrors(ctrl, evt =>
                    (this.elemRef.nativeElement as HTMLElement).dispatchEvent(evt)
                );

                this.cd.detectChanges();
            })
        );

        // User Input
        this.subscriptions.push(
            this.userInputEmitter$.subscribe(value => {
                this.store.dispatch(
                    new DynamicFormSetDataAction(
                        formUID,
                        getDataPathByFieldConfig(this.config),
                        this.normalizeDropdownValue(value)
                    )
                );
                this.store.dispatch(new DynamicFormControlSetDirtyAction(formUID, refId));
                this.helper.dispatchStoreActions(this.store, this.config, value);
            })
        );

        // Notifications
        this.subscriptions.push(
            combineLatest([valueToRender$.pipe(first()), this.userInputEmitter$])
                .pipe(
                    filter(
                        ([defaultValue, userInput]) =>
                            stringIsNotEmpty(defaultValue) && defaultValue !== userInput // isNonEmptyString does not work here
                    )
                )
                .subscribe(_ => {
                    const aixEvt = new CustomEvent(FieldEvent.CHANGE, {
                        detail: this.config,
                        bubbles: true,
                        cancelable: true
                    });
                    this.elemRef.nativeElement.dispatchEvent(aixEvt);
                })
        );

        // On Load Store Actions
        this.subscriptions.push(
            valueToRender$
                .pipe(first())
                .subscribe(value =>
                    this.helper.dispatchOnLoadStoreActions(this.store, this.config, value)
                )
        );
        this.cd.detectChanges();
    }

    onFocus() {
        this.templateData.ctrlIsFocused = true;
        if (this.fieldDecorationHostObservables) {
            this.fieldDecorationHostObservables.hasFocus.next(true);
        }
    }

    onBlur() {
        this.templateData.ctrlIsFocused = false;
        if (this.fieldDecorationHostObservables) {
            this.fieldDecorationHostObservables.hasFocus.next(false);
        }
    }

    onFilter(filter: string) {
        // Only externally filter if we have a filter function set
        // AND we either don't have a selection or we have a selection and the filter is not empty
        if (
            !!this.config.storeActionOnFilter &&
            (!this.templateData.valueToRender || (this.templateData.valueToRender && !!filter))
        ) {
            this.helper.dispatchOnFilterStoreActions(this.store, this.config, filter);
        }
    }

    initializeExternalFilterListener() {
        if (this.config.externalFilterStoreName && this.config.externalFilterStoreName !== '') {
            const storeName = this.config.externalFilterStoreName.split('.') as [string];
            this.subscriptions.push(
                (this.store as Store<any>).pipe(select(...storeName)).subscribe(data => {
                    this.templateData.ctrlIsExternalFiltering =
                        this.templateData.ctrlIsFocused && new IsInProgressPipe().transform(data);
                    this.cd.detectChanges();
                })
            );
        }
    }

    initializeFieldDecorations() {
        if (objectHasValue(this.config.decoration)) {
            this.fieldDecorationParams = this.config.decoration.params;
            this.fieldDecorationHostObservables = {
                hasFocus: new BehaviorSubject<boolean>(false)
            };
            this.fieldDecoration = FieldDecorationMap.get(this.config.decoration.ref);
        }
    }

    private normalizeDropdownValue(value: any) {
        return (Array.isArray(value) && value.length === 0) || !stringIsNotEmpty(value) // isNonEmptyString does not work here
            ? null
            : value;
    }

    private calculateClassNames() {
        let classNames = this.config.classNames
            ? [
                  AixDynamicDropdownComponent.HOST_CLASS,
                  ...this.helper.parseHostProperties(this.config.classNames.host)
              ].join(' ')
            : AixDynamicDropdownComponent.HOST_CLASS;
        classNames = this.config.hidden
            ? classNames.concat(` ${getHostPropertyClass().hidden}`)
            : classNames;
        return classNames;
    }

    getCellrendererByKey(key: string) {
        if (key === undefined) {
            return undefined;
        }
        return DropdownCellrendererMap.get(key);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.formStore.removeControl(this.config);
    }
}
