import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    HostBinding,
    OnDestroy,
    OnInit,
    Renderer2,
    inject
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { DynamicFormStore } from '../../dynamic-form-store';
import { DynamicFormState } from '../../dynamic-form-store/model';
import { DynamicFormRelations } from '../../dynamic-form-store/relations';
import { showRelationsHeuristic } from '../../dynamic-form.utils';
import { FieldConfig, FieldGroupLightConfig } from '@trade-platform/form-fields';
import { Field, FieldEvent } from '../field.interface';
import { GroupNotification } from '../group/group.component';
import { getFormControlStateByRefId } from '../../dynamic-form-store/utils';

import { AixDataTestingDirective, AixNotificationComponent } from '@trade-platform/ui-components';
import { AixSanitizePipe } from '@trade-platform/ui-utils';
import { DynamicFieldDirective } from '../dynamic-field.directive';

@Component({
    selector: 'aix-dynamic-group-light',
    templateUrl: './group-light.component.html',
    standalone: true,
    imports: [
        AixNotificationComponent,
        AixDataTestingDirective,
        AixSanitizePipe,
        forwardRef(() => DynamicFieldDirective),
        DynamicFieldDirective
    ]
})
export class AixDynamicGroupLightComponent implements Field, OnInit, OnDestroy {
    private cd = inject(ChangeDetectorRef);
    private elemRef = inject(ElementRef);
    private renderer = inject(Renderer2);
    private formStore = inject(DynamicFormStore);
    private store = inject<Store<Record<string, DynamicFormState>>>(Store);
    relationsManager = inject(DynamicFormRelations);

    // Static
    static HOST_CLASS = 'aix-flex-grid aix-form__container';

    // Decorators
    @HostBinding('class')
    classNames = AixDynamicGroupLightComponent.HOST_CLASS;

    // Other
    config: FieldGroupLightConfig<FieldConfig[]>;
    subscriptions: Subscription[] = [];
    notifications: GroupNotification[] = [];
    private removeServerValidationErrorListener: ReturnType<Renderer2['listen']>;
    private notificationsShown: { [key: string]: boolean } = {};

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        this.cd.detach();
    }

    ngOnInit() {
        // Control initialization
        this.formStore.addNonControl(this.config);

        this.subscriptions.push(
            showRelationsHeuristic(this.store, this.formStore.formUID, this.cd)
        );

        // Server Validation Notifications
        this.removeServerValidationErrorListener = this.renderer.listen(
            this.elemRef.nativeElement,
            FieldEvent.SERVER_VALIDATION_ERROR,
            event => this.onServerValidationError(event)
        );

        this.cd.detectChanges();
    }

    private onServerValidationError(event: CustomEvent<{ refId: string; message: string }>) {
        const controlRefId = event.detail.refId + '_serverValidationError';

        const isHidden =
            getFormControlStateByRefId(this.store, this.formStore.formUID, event.detail.refId)
                .fieldConfig.hidden ?? false;

        if (!this.notificationsShown[controlRefId] && !isHidden) {
            this.notificationsShown[controlRefId] = true;
            this.notifications.push({
                refId: controlRefId,
                type: 'fieldChangeNotification',
                text: event.detail.message,
                status: 'warning'
            });
        }
        this.cd.detectChanges();

        event.stopImmediatePropagation();
    }

    onNotificationClosed(index: number) {
        const refId = this.notifications[index].refId as string;
        delete this.notificationsShown[refId];
        this.notifications.splice(index, 1);
        this.cd.detectChanges();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.formStore.removeControl(this.config);
        this.removeServerValidationErrorListener();
    }
}
