import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import {
    holdingOptionDecoder,
    holdingOptionListDecoder
} from '../../decoders/holding-option/decoder';
import { HoldingOption } from '../../models/holding-option/model';

@Injectable()
export class HoldingOptionService {
    private http = inject(HttpClient);
    private environment = inject<IEnvironment>(ENVIRONMENT);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    getHoldingOption(id: string) {
        return this.http
            .get<HoldingOption>(`${this.environment.api}holding_options/${id}`)
            .pipe(concatMap(p => fromPromise(holdingOptionDecoder.decodeToPromise(p))));
    }

    getHoldingOptions(): Observable<HoldingOption[]> {
        return this.http
            .get<HoldingOption[]>(`${this.environment.api}holding_options`)
            .pipe(concatMap(p => fromPromise(holdingOptionListDecoder.decodeToPromise(p))));
    }
}
