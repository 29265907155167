import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    input,
    Input,
    OnDestroy,
    OnInit,
    output,
    viewChild,
    ViewChild,
    inject
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { orderStatus, recipientStatus, recipientType } from '@trade-platform/lib-enums';
import {
    AixButtonComponent,
    AixCheckboxComponent,
    AixDataTestingDirective,
    AixHeaderSectionComponent,
    AixLoadingComponent,
    AixModalComponent,
    AixNotificationComponent,
    AixTooltipDirective,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import {
    AixErrorBoxComponent,
    AixExtendedNotification,
    AixNotificationExtra,
    AppState,
    BaseOrder,
    eSignEnvelopeRecipient
} from '@trade-platform/ui-shared';
import { isInProgress, RemoteData } from 'ngx-remotedata';
import { combineLatest, interval, Observable, Subscription } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ProfileStoreFacade } from '@advisor-ui/app-services';
import { isDocusignEnvelopeDead } from '../../utils/order-utils';
import { emailValidator, ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../../../../base.orders.store.facade';
import { OrderTriggerMakeChangesAction } from '../../../../store/order-make-changes/actions';
import { AixSignerFilterPipe } from '../pipes/signers.pipe';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { AixSignerComponent } from '../signer/signer.component';

@Component({
    selector: 'aix-signatures',
    templateUrl: './signatures.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AixLoadingComponent,
        AixSignerComponent,
        AixHeaderSectionComponent,
        AixButtonComponent,
        AixDataTestingDirective,
        AixTooltipDirective,
        AixNotificationComponent,
        AixErrorBoxComponent,
        NgTemplateOutlet,
        AixModalComponent,
        AixCheckboxComponent,
        AsyncPipe,
        AixSignerFilterPipe
    ]
})
export class AixOrderProcessDocuSignSignaturesComponent implements OnInit, OnDestroy {
    private environment = inject<IEnvironment>(ENVIRONMENT);
    private ref = inject(ChangeDetectorRef);
    ordersStore = inject<BaseOrdersStoreFacade>(ORDERS_STORE_FACADE);
    profileStore = inject(ProfileStoreFacade);
    store = inject<Store<AppState>>(Store);
    private _fb = inject(UntypedFormBuilder);

    cancelSignaturesConfirmModal = viewChild<AixModalComponent>(AixModalComponent);
    isReadOnly = input<boolean>(false);

    onMakeChanges = output();
    onSignaturesCompleted = output();

    readonly reducerSuffix = this.ordersStore.type;

    subscriptions: Subscription[] = [];
    pollSignaturesSubscription: Subscription | null;

    signers: eSignEnvelopeRecipient[] = [];
    signaturesCompleted = false;
    showResendNotification = false;
    showSigningNotification = false;
    showResend = false;
    docusignEmail: string;
    notification: AixExtendedNotification = {
        text: '',
        status: 'warning'
    };

    recipientType = recipientType;

    newEmailAddressForm: UntypedFormGroup;

    states: Observable<RemoteData<any, any>>[];
    isLoading$: Observable<boolean>;

    cancelEsignButtonType = BUTTON_TYPE.link;
    resendEsignButtonType = BUTTON_TYPE.link;

    acceptCancelESign = false;
    acceptCancelESignConfirmed = (button: string) => !this.acceptCancelESign && button !== 'Cancel';

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        const environment = this.environment;

        this.docusignEmail = environment.contactDetails.docusignEmail;
        this.newEmailAddressForm = this._fb.group({
            email: this._fb.control('', [Validators.required, emailValidator()])
        });
    }

    ngOnInit() {
        this.states = [
            this.ordersStore.orderRemoteData$,
            this.ordersStore.cancelEnvelopeRemoteData$,
            this.profileStore.profileRemoteData$,
            this.ordersStore.orderResendRemoteData$
        ];

        this.isLoading$ = combineLatest([
            this.ordersStore.orderRemoteData$,
            this.ordersStore.cancelEnvelopeRemoteData$,
            this.profileStore.profileRemoteData$,
            this.ordersStore.orderResendRemoteData$
        ]).pipe(map(states => states.some(isInProgress)));

        const ord = this.ordersStore.order;

        // Polling to know when documents are signed
        if (ord) {
            this.initRecipients(ord);
            this.startPolling(ord);
        } else {
            this.subscriptions.push(
                this.ordersStore.orderSuccess$.subscribe(order => {
                    this.initRecipients(order);
                    this.startPolling(order);
                })
            );
        }

        this.subscriptions.push(
            this.ordersStore.actions.resend.success$.subscribe(action => {
                this.showSigningNotification = false;
                this.showResendNotification = true;
                this.initRecipients(action.payload);
            }),
            this.ordersStore.actions.cancelEnvelope.success$.subscribe(() => {
                this.showResendNotification = false;
                this.ordersStore.actions.getOrder.dispatch({
                    orderId: this.ordersStore.order.id,
                    reducerSuffix: this.reducerSuffix
                });
            }),
            this.ordersStore.actions.orderTriggerMakeChanges.success$.subscribe(() => {
                this.onMakeChanges.emit();
            })
        );
    }

    checkResend() {
        const recipient = this.signers.filter(
            (s: eSignEnvelopeRecipient) =>
                s && (s.status === recipientStatus.sent || s.status === recipientStatus.delivered)
        )[0];
        this.showResend = !!(recipient && recipient.email);
    }

    checkSignerStatuses() {
        this.signers.every((s: eSignEnvelopeRecipient) => {
            switch (s.status) {
                case recipientStatus.declined:
                    this.notification = {
                        text: `${s.fullName} declined to sign. Please `,
                        status: 'warning',
                        extras: [
                            {
                                type: 'link',
                                text: 'edit order',
                                action: new OrderTriggerMakeChangesAction()
                            },
                            {
                                type: 'text',
                                text: '.'
                            }
                        ] as AixNotificationExtra[]
                    } as AixExtendedNotification;
                    this.showResendNotification = false;
                    this.showSigningNotification = true;
                    return false;
                case recipientStatus.autoResponded:
                    this.notification = {
                        text: `Cannot send email to ${s.fullName}. This may be due to an incorrect or misspelled email address. To resolve the issue, please `,
                        status: 'warning',
                        extras: [
                            {
                                type: 'link',
                                text: 'edit order',
                                action: new OrderTriggerMakeChangesAction()
                            },
                            {
                                type: 'text',
                                text: '.'
                            }
                        ] as AixNotificationExtra[]
                    } as AixExtendedNotification;
                    this.showResendNotification = false;
                    this.showSigningNotification = true;
                    return false;
                default:
                    this.showSigningNotification = false;
                    return true;
            }
        });
    }

    initRecipients(ord: Partial<BaseOrder>) {
        // Get signers;
        if (ord.eSign && ord.eSign.envelope && ord.eSign.envelope.recipients) {
            const signers = ord.eSign.envelope.recipients;
            this.showResendNotification =
                this.showResendNotification && this.areStatusChanges(signers)
                    ? false
                    : this.showResendNotification;
            this.signers = ord.eSign.envelope.recipients;
        }

        // Check signers;
        if (this.signers.length > 0) {
            this.checkSignerStatuses();
            this.checkResend();
            this.signaturesCompleted = this.areSignaturesCompleted();
            this.ref.detectChanges();
        }
    }

    startPolling(ord: BaseOrder) {
        // Stop polling if the order status or current step changes (via make changes);
        if (this.pollSignaturesSubscription && this.signaturesCompleted) {
            this.stopPolling();
        } else if (
            !this.pollSignaturesSubscription &&
            ord.status === orderStatus.pendingSignatures &&
            !this.signaturesCompleted
        ) {
            // We need to poll the order and the polling is not running
            this.pollSignatures(ord);
        }
    }

    pollSignatures(ord: BaseOrder) {
        this.pollSignaturesSubscription = interval(20000)
            .pipe(mergeMap(() => this.ordersStore.getOrder(ord.id) as Observable<BaseOrder>))
            .subscribe(order => {
                this.initRecipients(order);

                if (isDocusignEnvelopeDead(order) || this.signaturesCompleted) {
                    this.stopPolling();
                }

                this.ref.detectChanges();
            });
    }

    stopPolling() {
        if (this.pollSignaturesSubscription) {
            this.pollSignaturesSubscription.unsubscribe();
            this.pollSignaturesSubscription = null;
        }
    }

    /**
     * Checks all recipients (investors, advisors, additionals) for signature completion;
     * @returns {boolean} - true if all recipients completed signing, false otherwise;
     */
    areSignaturesCompleted(): boolean {
        // Parse recipients
        for (let i = 0; i < this.signers.length; i++) {
            const recipient: eSignEnvelopeRecipient = this.signers[i];
            if (!recipient.status || recipient.status !== recipientStatus.completed) {
                return false;
            }
        }
        // All recipients completed signing
        this.onSignaturesCompleted.emit();
        return true;
    }

    areStatusChanges(newRecipients: eSignEnvelopeRecipient[]): boolean {
        const newRecipientsStatus = newRecipients.map(e => e.status);
        const oldRecipientsStatus = this.signers.map(e => e.status);
        return JSON.stringify(newRecipientsStatus) !== JSON.stringify(oldRecipientsStatus);
    }

    cancelSignatures() {
        this.stopPolling();
        this.cancelSignaturesConfirmModal()?.openModal();
    }

    cancelSignaturesConfirmModalSelected(e: string) {
        switch (e) {
            case 'Yes, continue':
                this.ordersStore.actions.cancelEnvelope.dispatch({
                    orderId: this.ordersStore.order.id,
                    voidedReason: 'User cancelled'
                });
                break;
            default:
                break;
        }
    }

    onClickResend() {
        const recipient = this.signers.filter(
            (s: eSignEnvelopeRecipient) =>
                s.status === recipientStatus.sent ||
                s.status === recipientStatus.declined ||
                s.status === recipientStatus.delivered
        )[0];
        if (recipient && recipient.id) {
            const ord: BaseOrder = this.ordersStore.order;
            this.ordersStore.actions.resend.dispatch({
                orderId: ord.id,
                recipientId: recipient.id
            });
        }
    }

    cancelESing(e: boolean) {
        this.acceptCancelESign = e;
    }

    onCloseCancelESignModal() {
        this.acceptCancelESign = false;
    }

    ngOnDestroy() {
        this.stopPolling();
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
