import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { getFromStorage, setToStorage, withStorage } from '@trade-platform/ui-utils';
import { of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { catchHttpError } from '../utils/catch-http-error';
import {
    AcceptTermsAction,
    AcceptTermsActionTypes,
    AcceptTermsFailureAction,
    AcceptTermsSuccessAction,
    ClearTermsAction,
    LoadTermsAction,
    LoadTermsFailureAction,
    LoadTermsFromStorageAction,
    LoadTermsSuccessAction,
    SetTermsAction,
    TermsActionTypes
} from './actions';
import { TermsAndConditions } from '../../models/terms/model';
import { TermsService } from './service';

@Injectable()
export class TermsEffects {
    private actions$ = inject(Actions);
    private service = inject(TermsService);

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {}

    loadTerms$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadTermsAction>(TermsActionTypes.LOAD_TERMS),
            switchMap(action =>
                this.service.getTerms().pipe(
                    map(terms => {
                        setToStorage('terms', terms);
                        return new LoadTermsSuccessAction(terms);
                    }),
                    catchHttpError(error => {
                        return of(new LoadTermsFailureAction({ error }));
                    })
                )
            )
        )
    );

    loadTermsFromStorage$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadTermsFromStorageAction>(TermsActionTypes.LOAD_TERMS_FROM_LOCAL_STORAGE),
            map(() => {
                const terms = getFromStorage('terms') as TermsAndConditions;
                return new SetTermsAction(terms);
            })
        )
    );

    setTerms$ = createEffect(() =>
        this.actions$.pipe(
            ofType<SetTermsAction>(TermsActionTypes.SET_TERMS),
            tap((action: SetTermsAction) => {
                setToStorage('terms', action.payload);
            })
        )
    );

    clearTerms$ = createEffect(() =>
        this.actions$.pipe(
            ofType<ClearTermsAction>(TermsActionTypes.CLEAR_TERMS),
            tap((action: ClearTermsAction) => {
                withStorage(storage => storage.removeItem('terms'));
            })
        )
    );

    acceptTerms$ = createEffect(() =>
        this.actions$.pipe(
            ofType<AcceptTermsAction>(AcceptTermsActionTypes.ACCEPT_TERMS),
            switchMap(action =>
                this.service.acceptTerms().pipe(
                    map(response => new AcceptTermsSuccessAction(response)),
                    catchHttpError(error => of(new AcceptTermsFailureAction({ error })))
                )
            )
        )
    );
}
