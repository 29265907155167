import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    inject
} from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
    AppState,
    ErrorWrapper,
    AixEffectActions,
    getDataFromState,
    LoadPrivacyPolicyAction,
    PrivacyPolicy,
    AixErrorBoxComponent
} from '@trade-platform/ui-shared';
import { RemoteData, RemoteDataModule } from 'ngx-remotedata';
import { Observable, Subscription } from 'rxjs';
import { UnescapeQuotesPipe } from '../pipes/unescape-quotes';
import { SafeHtmlPipe } from '../pipes/secure-html-pipe';
import { AsyncPipe } from '@angular/common';
import { AixLoadingComponent, AixPrintButtonComponent } from '@trade-platform/ui-components';

@Component({
    selector: 'aix-privacy-policy',
    templateUrl: './privacy-policy.html',
    styleUrls: ['./privacy-policy.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AixLoadingComponent,
        AixErrorBoxComponent,
        AixPrintButtonComponent,
        AsyncPipe,
        RemoteDataModule,
        SafeHtmlPipe,
        UnescapeQuotesPipe
    ]
})
export class AixPrivacyPolicyComponent implements OnInit, OnDestroy {
    store = inject<Store<AppState>>(Store);
    router = inject(Router);
    actions$ = inject(AixEffectActions);
    private ref = inject(ChangeDetectorRef);

    privacyPolicyState$: Observable<RemoteData<PrivacyPolicy, ErrorWrapper>>;
    privacyPolicy$: Observable<PrivacyPolicy>;

    subscriptions: Subscription[] = [];
    states: Observable<RemoteData<any, any>>[];

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        this.privacyPolicyState$ = this.store.pipe(select(state => state.privacyPolicy));
        this.privacyPolicy$ = getDataFromState(this.privacyPolicyState$);
        this.states = [this.privacyPolicyState$];
    }

    ngOnInit() {
        this.store.dispatch(new LoadPrivacyPolicyAction());
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
