import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    effect,
    input,
    OnInit,
    Signal,
    inject
} from '@angular/core';
import { NgClass, NgStyle } from '@angular/common';
import { AixDataTestingDirective } from '@trade-platform/ui-components';

@Component({
    selector: 'aix-step-progress',
    templateUrl: './step-progress.html',
    styleUrls: ['./step-progress.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgStyle, NgClass, AixDataTestingDirective]
})
export class AixStepProgressComponent implements OnInit {
    private cd = inject(ChangeDetectorRef);

    maxN: Signal<number> = computed(() => this.maxSteps() || 0);
    progressN: Signal<number> = computed(() => {
        if (this.progress() !== undefined) {
            return Math.floor(this.progress() as number);
        }
        return 0;
    });
    percentage = 0;
    wordWrapText: Signal<string[]> = computed(() => {
        return this.label() ? (this.label() as string).split(' ') : ['Complete'];
    });

    showTotal = input(true);
    progress = input<number>();
    maxSteps = input<number>();
    label = input<string>();

    type = input<'linear' | 'circular'>('linear');
    sqSize = input(120);
    strokeLinecap = input<'round' | 'butt'>('round');
    strokeWidth = input(8);
    strokeColor = input<string | null>(null);
    fontSize = input(24);
    subtext = input(true);

    innerStrokeWidth: number;
    radius: number;
    viewBox: string;
    dashArray: number;
    dashOffset: number;
    stepX: number;
    stepY: number;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        effect(() => {
            this.updateProgress();
            this.cd.detectChanges();
        });
    }

    ngOnInit() {
        this.updateProgress();
    }

    updateProgress() {
        this.percentage = (this.progressN() * 100) / this.maxN();

        this.innerStrokeWidth = this.strokeWidth();
        this.radius = (this.sqSize() - this.strokeWidth()) / 2;
        this.viewBox = `0 0 ${this.sqSize()} ${this.sqSize()}`;
        this.dashArray = this.radius * Math.PI * 2;
        this.dashOffset = this.dashArray - (this.dashArray * this.percentage) / 100;
        this.stepY = this.subtext()
            ? Math.ceil(this.sqSize() / 2 + this.fontSize() / 4.1667)
            : Math.ceil(this.sqSize() / 2 + this.fontSize() / 4.1667) + this.fontSize();
        this.stepX = this.stepY - 2;
    }
}
