import { ChangeDetectorRef, Directive, HostBinding, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@trade-platform/ui-shared';
import { Observable } from 'rxjs';

@Directive({
    standalone: true,
    selector: '[bannerStyles]'
})
export class BannerStylesDirective {
    private store = inject<Store<AppState>>(Store);
    ref = inject(ChangeDetectorRef);

    @HostBinding('style.padding-top') paddingTop: string;
    @HostBinding('style.transition') transition = 'padding-top 0.2s ease-in-out';

    bannerOffsetPx$: Observable<number>;
    baseOffsetPx = 0;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
        const ref = this.ref;

        this.bannerOffsetPx$ = this.store.select(state => state.styles.bannerOffsetPx);

        this.bannerOffsetPx$.subscribe((px: number) => {
            this.paddingTop = `${this.baseOffsetPx + px}px`;
            ref.markForCheck();
        });
    }
}
