import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';
import { NgClass } from '@angular/common';

@Component({
    selector: 'aix-page-section',
    templateUrl: './aix-page-section.html',
    styleUrls: ['./aix-page-section.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass, AixDataTestingDirective]
})
export class AixPageSectionComponent {
    title = input<string>();
    pageClasses = input<any>();
    dataTestingName = input('');

    constructor() {}
}
